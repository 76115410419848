<template>
  <div class="iw-popInfo" :style="{width,height,opacity}" style='background: rgba(11,20,19,0.8); ;border: 1px solid  #00FFF7 ;transform: translate(30px,35%);'>
      <div class="box" style='width: 100%;height: 100%;'>
        <div class='tools' >
          <div class='title' >
            <span class='text'>停车场</span>
            <img style="width:30px;height:30px;" src="/images/components/std/del-n.png" alt="" onclick="closeInfoWindow()"/>
          </div>
        </div>
        <div class='content' style='display: flex;width: 100%;flex-direction: column;font-size: 18px;height:450px;'>
          <div class='item' style='margin-left: 30px;height: 40px;margin-top:10px;'>{{data.name}}</div>
          <div class='item' style='margin-left: 30px;height: 40px;display: flex;align-items: center;'>
            <img style="width:17px;height:17px;margin-right:10px;"  src="/images/components/std/pos.png" alt="" />
           {{data.deviceLocation}}</div>
          <div class='item' style='height:70px;margin-left: 15px;margin-bottom:10px;flex-direction: row;' >
            <div style='margin-left: 30px;flex-direction: column;position: absolute;left: 0px;'>
              <div style='margin-bottom: 7px;'> 剩余车位数量 </div>
              <div><span style='color:#00FCFF;margin: 0 5px;font-size: 20px;'>{{ data.parkingPlaceNumTotal - data.parkingPlaceNumUsed }} / {{data.parkingPlaceNumTotal}}</span>  个</div>
            </div>
            <div style='flex:1;margin-left: 30px;flex-direction: column;position: absolute;right: 50px;'>
              <div style='margin-bottom: 7px;'> 饱和状态 </div>
              <div v-if="data.viewType === '4' " style='width: 84px;height: 30px;background: #5B0610;border: 1px solid #E71313;border-radius: 15px;align-items: center;text-align: center;'>紧张</div>
              <div v-else-if="data.viewType === '3' " style='width: 84px;height: 30px;background: #624912;border: 1px solid #C89126;border-radius: 15px;align-items: center;text-align: center;'>适中</div>
              <div v-else  style='width: 84px;height: 30px;background: #0D4841;border: 1px solid #06E082;border-radius: 15px;align-items: center;text-align: center;'>空闲</div>


            </div>
          </div>

            <div class='item' style='margin-left:0 ;width: 92%;margin: 0 auto;height: 420px;'>
              <!--            <img src='/images/components/iw/l1.png' width='300px' height='150px' alt=''>-->

              <div  class='videoContainer' style='height: 100%;width: 100%;border:none' >
                <div  class="iframe-box" style='display: flex;flex-direction: column; '>

                  <!-- <div style=" border: 2px solid #074044;height:270px;">
                        <img :src="picUrlcal(data.picUrl)" width='100%' height='100%' alt=''>
                  </div> -->
              
  <el-carousel v-if="picUrlcal(data.picUrl)">
    <el-carousel-item v-for="(item,index) in picUrlcal(data.picUrl)" :key="index">
      <img :src="item" width='100%' height='100%' alt=''>
    </el-carousel-item>
  </el-carousel>
<ek-empty v-else></ek-empty>

                  <div class='flexItem' style="margin-top:15px;">收费标准：<span style='color: #00FCFF;font-size: 20px;font-weight: bold;'>4</span>元/小时 <span style='color: #BB8927;font-size: 15px; font-weight: bold;'>免费时长120分钟</span>  </div>
                  <div class='flexItem'>营业时间：00:00-24:00</div>
                  <!-- <div class='flexItem' >车位预测（空闲泊位数）{{data.parkingPlaceNumTotal - data.parkingPlaceNumUsed}}</div> -->
                  <div class='flexItem' >车位预测（空闲泊位数）/ 个</div>
                </div>
            </div>
          </div>
          <!-- <div class='item' style='flex:1;margin-left: 15px;'>车位预测(空闲泊位数)</div> -->
        </div>
        <div class="chart-box1" style='width:90%;padding: 10px;margin: 0 auto;height:200px;'>
          <!-- <ek-chart
         
            style="width: 100%;height: 100%"
            :options="data.parkForecastModelList? calOptions(data.parkForecastModelList):options "
            :animation="true"
           :animationData="calOptions(data.parkForecastModelList).xAxis.data"
          /> -->
           <ek-chart
            v-if="options.series[0].data"
            style="width: 100%;height: 100%"
            :options="options"
            :animation="true"
           :animationData="options.xAxis.data"
          />
            <ek-empty v-else></ek-empty>
        </div>
      </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'

export default {
  data() {
    return {
      data:{
        name:"市北区万寿路-道路停车泊位"
      },
      opacity:0.1,
      picUrl:'',
      options: {
        grid: {
          top: '5%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff',
            fontSize: 16,
            formatter: function(val) {
              return val
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC'
            }
          },
          // 不显示刻度线
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#2973AC',
              type: [3, 5]
            }
          },
          data:[1,2,3,4,5,6]
        },
        // xAxis: {
        //   type: 'category',
        //   axisLabel: {
        //     show: false
        //   },
        //   axisLine: {
        //     show: false
        //   },
        //   // 不显示刻度线
        //   axisTick: {
        //     show: false
        //   },
        //   splitLine: {
        //     // 网格线为虚线
        //     show: false
        //   },
        //
        // },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC',
              type: [3, 5]
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 16,
            formatter: function(val) {
              return val
            }
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: '50%',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#EF9C00'
                },
                {
                  offset: 1,
                  color: '#142D3F'
                }
              ])
            },
            data: [50, 55, 65, 70, 90, 100]
          }
        ]
      }

    }
  },
  props:{
    width: {
      type: String,
      default: '560px'
    },
    height: {
      type: String,
      default: '850px'
    }
  },
  components: {
  },
  computed:{
    picUrlcal:function(){
      return function(val){
        if(val && val.indexOf(',')!==-1){
          let a = val.split(',')
          // return a[0]
          return a
        }else{
          // return "http://parking.qdznjt.com/mopai/image/20230522/c96d8713-36dd-4605-89cd-9ecaa1930588.jpg"
           return ["http://parking.qdznjt.com/mopai/image/20230522/c96d8713-36dd-4605-89cd-9ecaa1930588.jpg"]
        }

      }
    },
    calOptions:function(){
      let that = this
      return function(val){
        let data=[]
        let date=[]
          // console.log(this.data.parkForecastModelList,this.data.picUrl,'ppppppppppppppppppp')
         val.forEach(item=>{
            data.push(item.empty)
            date.push(item.time)
          })
          that.options.xAxis.data = date
          that.options.series[0].data =data
        return that.options

      }
    },
  },
  mounted() {


  },
  methods: {

    closeInfoWindow(){
      window.closeInfoWindow()
    },
    videoFull() {
      // console.log(e)
      // this.$eventBus.$emit(
      //   'dev-afjk1',
      //   // e.url?e.url:''
      //   ''
      // )
    }

  }
}
</script>

<style scoped lang="scss">

.box {

  @include flex(column);
  .tools {
    @include flex(column);
    .title{
      height: 50px;
      //width: 100%;
      background: rgba(7,64,68,0.5);
      border-bottom: 1px solid #00FFF7;
      line-height: 50px;
      align-items: center;
      width: 100%;
      .text{
        padding-left: 20px;
        font-size: 26px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #00FFFF;
      }
      img{
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
    }
  }
  .content{
    flex: 1;
    .item{
      display: flex;
      align-items: center;
    }
  }
  .chart-box1 {

    //flex: 1;
    //position: relative;
    //@include flex();
    //justify-content: center;

  }
}
.item {
  margin-left: 30px;
}
.flexItem{
  height: 40px;
}
</style>
<style >
.el-carousel--horizontal{
  overflow: hidden;
}
</style>