<template>
  <div class="modal-img" v-if="visible">
    <div class="mask"></div>
    <div class="box" @click="close">
      <img :src="imgUrl" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      imgUrl: ''
    }
  },
  mounted() {},
  methods: {
    open(type) {
      this.visible = true
      if (type == 'store') {
        this.imgUrl = 'http://localhost:3020/img/store.png'
      } else if (type == 'tourist') {
        this.imgUrl = 'http://localhost:3020/img/tourist.png'
      }
    },
    close() {
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
.modal-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;

  @include flex();
  align-items: center;
  justify-content: center;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba($color: #000000, $alpha: 0.8);
  }
  .box {
    width: 100%;
    height: 100%;
    z-index: 11;
    @include flex();
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
