<template>
  <div class="header">
    <img class="header-bg" src="/images/header/header-bg.png" alt="" />
    <div class="header-menu">
      <div
        class="menu"
        :class="headerType == item.type ? 'active' : ''"
        v-for="(item, index) in menus.left"
        :key="index"
        @click="handleHeader(item)"
      >
        <img
          v-if="headerType == item.type"
          src="/images/header/menu-icon.png"
          alt=""
        />
        <span>{{ item.label }}</span>
        <img
          v-if="headerType == item.type"
          src="/images/header/menu-icon.png"
          alt=""
        />
      </div>
    </div>
    <div class="header-title">
      <div class="modalImg-btn" @click="handleModalBtn('store')"></div>
      <img src="/images/header/header-title.png" alt="" />
      <div class="modalImg-btn" @click="handleModalBtn('tourist')"></div>
    </div>

    <div class="header-menu">
      <div
        class="menu"
        :class="headerType == item.type ? 'active' : ''"
        v-for="(item, index) in menus.right"
        :key="index"
        @click="handleHeader(item)"
        @mouseover="handleMouseHeader"
      >
        <img
          v-if="headerType == item.type"
          src="/images/header/menu-icon.png"
          alt=""
        />
        <span>{{ item.label }}</span>
        <img
          v-if="headerType == item.type"
          src="/images/header/menu-icon.png"
          alt=""
        />
      </div>
      <div
        class="right_box"
        v-if="headerType == 'bdg' && bdgSelectFlag"
        @mouseover="handleMouseHeader"
        @mouseleave="handleHideMenu"
      >
        <div
          v-for="(item, index) in selectMenuList"
          :class="menuType == item.type ? 'activeC' : ''"
          :key="index"
          @click="handleClickHeaderChildren(item)"
          @onmouseover="handleMouseHeader"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      menuTime: '',
      menus: {
        left: [
          { label: '台东概况', type: 'ov' },
          { label: '智慧管理', type: 'iw' },
          { label: '综合态势', type: 'cs' }
        ],
        right: [
          { label: '数字运营', type: 'do' },
          { label: '安全台东', type: 'std' },
          {
            label: '商圈治理',
            type: 'bdg'
          }
        ]
      },
      bdgSelectFlag: false,
      selectMenuList: [
        { label: '街区党建', type: 'jqdj' },
        { label: '街区治理', type: 'jqzl' },
        { label: '街区服务', type: 'jqfw' }
      ]
    }
  },
  computed: {
    ...mapGetters(['headerType', 'menuType'])
  },
  methods: {
    handleHeader({ label, type }) {
      if (type != 'bdg') this.$store.dispatch('setMenuType', '')
      if (type === this.headerType) return
      console.log('一级菜单:', label, type)
      this.$store.dispatch('setHeaderType', type)
      if (type == 'ov') {
        this.$store.dispatch('setMenuType', 'br')
      } else if (type == 'bdg') {
        this.$store.dispatch('setMenuType', 'jqdj')
        this.bdgSelectFlag = true
        setTimeout(() => {
          this.bdgSelectFlag = false
        }, 5000)
      }
    },
    handleClickHeaderChildren({ label, type }) {
      console.log('二级菜单:header', label, type)
      if (type === this.menuType) return
      this.$store.dispatch('setMenuType', type)

      console.log(type, this.menuType)
    },
    handleMouseHeader() {
      clearTimeout(this.time)
      if (this.headerType == 'bdg') {
        this.bdgSelectFlag = true
      }
    },
    handleHideMenu() {
      this.menuTime = setTimeout(() => {
        this.bdgSelectFlag = false
      }, 5000)
    },

    handleModalBtn(type) {
      this.$eventBus.$emit('wechat-demo', type)
    }
  }
}
</script>
<style scoped lang="scss">
.header {
  height: $headerHeight;
  z-index: 4;
  padding: 0 50px 0;
  @include flex();
  position: relative;
  justify-content: space-between;

  &-bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }

  &-menu {
    width: 700px;
    height: 54px;
    @include flex();
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    z-index: 2;

    div {
      width: 198px;
      height: 100%;
      @include flex();
      align-items: center;
      justify-content: center;
      font-size: 24px;
      cursor: pointer;

      img {
        width: 36px;
        height: 22px;
      }
    }

    .active {
      position: relative;

      span {
        padding: 0 15px;
        background: linear-gradient(to top, #ef9c00, #fff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .active::after {
      content: '';
      position: absolute;
      bottom: 2px;
      height: 4px;
      width: 50px;
      background: #ef9c00;
      box-shadow: 0 0 10px #ef9c00;
    }
  }

  &-title {
    @include flex();
    align-items: center;
    justify-content: center;
    z-index: 1;
    img {
      width: 484px;
      margin: 0 10px;
    }
    .modalImg-btn {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
  .right_box {
    @include flex(column);
    width: 160px;
    height: 168px;
    position: absolute;
    background: url('../../../public/images/components/bdg/select_bg.png')
      no-repeat;
    background-size: 100% 100%;
    right: 83px;
    top: 82px;
    justify-content: space-around;
    .activeC {
      padding: 0 15px;
      background: linear-gradient(to top, #ef9c00, #fff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
</style>
