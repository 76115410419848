<template>
  <div class='iw-popInfo' :style='{width,height,opacity}' style='background: rgba(11,20,19,0.8); ;border: 1px solid  #00FFF7 ;transform: translate(30px,25%);'>
    <div class='box' style='width: 100%;height: 100%;'>
      <div class='tools' style='height: 50px; background: rgba(7,64,68,0.5);
      border-bottom: 1px solid #00FFF7;line-height: 50px;align-items: center;
      width: 100%;'>
        <div class='title' style='height: 50px; background: rgba(7,64,68,0.5);border-bottom: 1px solid #00FFF7;line-height: 50px;align-items: center;
      width: 100%;'>
            <span class='text' style=' padding-left: 20px;font-size: 26px;
        font-family: SourceHanSansCN;font-weight: bold;color: #00FFFF;'>{{ title }}</span>
          <img style='width:30px;height:30px; position: absolute;right: 10px;top: 10px;cursor: pointer;'
               src='/images/components/std/del-n.png' alt='' @click='closeInfoWindow()' />
        </div>
      </div>
      <div class='content' style='display: flex;width: 100%;flex-direction: column;font-size: 18px;flex:2;'>
        <div class='item' style='flex:1;margin-left: 15px;'>{{ data.name }}</div>
        <div class='item' style='flex:1;margin-left: 15px;display: flex;align-items: center;'>
          <img style='width:17px;height:17px;margin-right:10px;' src='/images/components/std/pos.png' alt='' />
          {{ data.deviceLocation }}
        </div>
        <div class='item' style='flex:2;margin-left: 15px;'>
          <div v-if="title.indexOf('报警柱')!==-1 ">
            <div style='flex:1;margin-right: 15px;'>报警次数（今年）</div>
            <div style='flex:1;margin-right: 15px;'><span style=' font-size: 26px;
        font-family: SourceHanSansCN;font-weight: bold;color: #00FFFF;'>{{ alarmInfo.num ||0 }} </span> 次
            </div>
          </div>
          <div v-if="title.indexOf('井盖')!==-1 ">
            <div style='flex:1;margin-right: 15px;'>倾斜次数（今年）</div>
            <div style='flex:1;margin-right: 15px;'><span style=' font-size: 26px;
        font-family: SourceHanSansCN;font-weight: bold;color: #00FFFF;'>{{ alarmInfo.moveNum || 0 }} </span> 次
            </div>
          </div>
          <div v-if="title.indexOf('井盖')!==-1 ">
            <div style='flex:1;margin-left: 15px;'>冒溢次数（今年）</div>
            <div style='flex:1;margin-left: 15px;'><span style=' font-size: 26px;
        font-family: SourceHanSansCN;font-weight: bold;color: #00FFFF;'>{{ alarmInfo.overflowNum || 0 }} </span> 次
            </div>
          </div>


        </div>

      </div>
      <div class='chart-box1' style='width:100%;margin: 5px auto;background: rgba(6,23,25,0.7);border-top:1px solid  rgba(7,64,68,1);
border-radius: 10px;flex:3;'>
        <div v-if="list.length" class='table-box'>
          <div class='table-list'>
            <div class='table-item' style=' color: #EF9C00;background: rgba(8, 74, 80, 0.0)'>
              <p class='f1'>序号</p>
              <p class='f4'>报警内容</p>
              <p class='f3'>报警时间</p>
            </div>
            <div class='table-body'>
              <div v-for='(item,index) in list' :key='index' class='table-item'>
                <p class='f1'>{{ index +1 }}</p>
                <p class='f4'>{{ item.content }}</p>
                <p class='f3'>{{ item.time }}</p>
              </div>
            </div>

          </div>
        </div>
           <ek-empty v-if="!list.length"></ek-empty>
        <el-pagination
          layout='total,prev, pager, next'
          :background='true'
          @current-change='handlePageChange'
          :total='dataTotal'
          :current-page='curPage'
          :page-size='pageSize'
          v-if="list.length"
        />
     
      </div>


    </div>
  </div>


</template>

<script>

import { alarmList } from '@/api/other'

export default {
  data() {
    return {
      title: '',
      data: {},
      opacity:0.1,
      alarmInfo:[],
      list: [
        {
          type: '设备',
          level: '1',
          dev: '报警柱',
          content: '紧急报警',
          postion: '台东三路88号',
          time: '04/03'
        },
        {
          type: '舆情',
          level: '2',
          dev: '新浪微博',
          content: '环境差',
          postion: '满口香饭店',
          time: '04/03'
        },
        {
          type: '设备',
          level: '3',
          dev: '智能垃圾桶',
          content: '垃圾桶满溢',
          postion: '台东三路88号',
          time: '04/03'
        }
      ],
      dataTotal: 0,
      curPage: 1,
      pageSize: 3
    }
  },
  props: {
    width: {
      type: String,
      default: '480px'
    },
    height: {
      type: String,
      default: '560px'
    }
  },

  mounted() {
    // this.fectchData()
  },
  methods: {
    handlePageChange(page) {
      console.log(page)
      this.curPage = page
      this.fetchData()
    },
    async fetchData(data) {
      console.log(data)
      const { resultCode, resultData } = await alarmList({
        // needPage: true,
        // page: this.curPage - 1,
        "pageNo": this.curPage -1,
        "pageSize": 3,
        "deviceId": this.data.deviceId,
      })
      let list = []
      if (resultCode === '200' && resultData) {
        this.dataTotal = resultData.totalElements
        resultData.content.forEach(item => {
          list.push({
            content: item.alarmContent,
            postion: item.alarmLocation,
            time: item.alarmTime.split(' ')[0]
          })
        })
        this.list = list
      }
    },
    closeInfoWindow() {
      window.closeInfoWindow()
    }

  }
}
</script>

<style scoped lang='scss'>

.box {

  @include flex(column);

  .tools {
    @include flex(column);

    .title {
      height: 50px;
      //width: 100%;
      background: rgba(7, 64, 68, 0.5);
      border-bottom: 1px solid #00FFF7;
      line-height: 50px;
      align-items: center;
      width: 100%;

      .text {
        padding-left: 20px;
        font-size: 26px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #00FFFF;
      }

      img {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
    }
  }

  .content {
    flex: 1;

    .item {
      display: flex;
      align-items: center;
    }
  }

  .chart-box1 {

    //flex: 1;
    //position: relative;
    //@include flex();
    //justify-content: center;

  }
}

.item {
  margin-left: 30px;

}

.table-box {
  height: 250px;
  padding: 5px 15px;

  .table-list {
    height: 100%;

    .table-item {

      display: flex;
      //justify-content: space-between;
      //padding: 0 10px;
      &:nth-child(odd) {
        background: rgba(8, 74, 80, 0.5);
      }

      &:nth-child(even) {
        background: rgba(8, 74, 80, 0.0);
      }

      p {
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }

      .f1 {
        flex: 1
      }

      .f2 {
        flex: 2
      }

      .f3 {
        flex: 3
      }

      .f4 {
        flex: 4
      }

      .f5 {
        flex: 5
      }

      .f6 {
        flex: 6
      }
    }
  }

  .table-body {
    height: 230px;

    .swiper-container {
      height: 100%;
      width: 100%;
    }
  }
}
</style>

<style lang='scss'>
.chart-box1{
  .el-pagination {
    padding: 15px 20px;
    @include flex();
    justify-content: center;
  }

  .el-pagination__total {
    color: #fff;
  }

  .is-background .el-pager li:not(.disabled).active {
    background-color: #084a50 !important;
    color: #fff !important;
  }
  .el-pager li:not(.disabled):hover {
    color: #084a50 !important;
  }
}

</style>
