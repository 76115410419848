<template>
  <div class="scene3D">
    <div class="box">
      <div class="i-box">
        <div class="t-close" @click="close">
          <img src="/images/map/ov/br/desc-close.png" alt="" />
        </div>
        <div id="videoBoxCon" class="video-box">
          <div v-for="(item, index) in stdList2" :key="index" class="box1">
            <div
              class="t-content"
              :class="currIndex == index ? 'active' : ''"
              @click="locateCamera(item, index)"
            >
              <div class="t-item">{{ index + 1 }} .</div>
              <div class="t-item">{{ item.deviceName }}</div>
              <img
                class="t-itemBF"
                width="40px"
                height="40px"
                src="/images/components/std/bf-icon.png"
                alt=""
                @click="videoFull(item)"
              />
            </div>
          </div>
        </div>
        <div id="tianqiaoCon" class="scene3D-box"></div>

        <div class="reset-location" @click="setDefultCamera"></div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      stdList: [],
      videoList: [],
      currIndex: -1,
      stdList2: []
    }
  },
  mounted() {
    let that = this
    this.isModel = []
    this.modelsInfo = []
    this.link = 
[
  'G10-A'	,'台东三路恒泰广场西北角-客流球机-A',
  'G10-B',	'台东三路恒泰广场西北角-客流球机-B',
'G11-A','台东三路长兴路-客流球机-A',
'G11-B','台东三路长兴路-客流球机-B',
'天桥东侧下沉广场北','天桥东侧下沉广场北-枪机',
'天桥东侧下沉广场南','天桥东侧下沉广场南-枪机',
'天桥东扶梯口',	'天桥东扶梯口-枪机',
'天桥东楼梯口',	'天桥东楼梯口-枪机',
'天桥东上下扶梯处',	'天桥东上下扶梯处-枪机',
'天桥东上下楼梯处',	'天桥东上下楼梯处-枪机',
'天桥西侧下沉广场北',	'天桥西侧下沉广场北-枪机',
'天桥西侧下沉广场南',	'天桥西侧下沉广场南-枪机',
'天桥西扶梯口',	'天桥西扶梯口-枪机',
'天桥西楼梯口',	'天桥西楼梯口-枪机',
'天桥西上下扶梯处',	'天桥西上下扶梯处-枪机',
'天桥西上下楼梯处',	'天桥西上下楼梯处-枪机',
'天桥下东北角立柱球机-A',	'天桥下东北角立柱-人脸球机-A',
'天桥下东南角立柱球机-A',	'天桥下东南角立柱-客流球机-A',
'天桥下西北角立柱球机-A',	'天桥下西北角立柱-客流球机-A',
'天桥下西南角立柱球机-A',	'天桥下西南角立柱-人脸球机-A',
'shexiangtou020','台东三路历城路-客流球机1-A',
'shexiangtou021','台东三路历城路-客流球机1-B',
'shexiangtou022','台东三路历城路-客流球机2-A',
'shexiangtou023','台东三路历城路-客流球机2-B',
]
    this.linkList=[]
     

    for(let i=0;i< that.link.length / 2 ; i ++ ){
      that.linkList.push({
        modelName:that.link[i],
        deviceName:that.link[i+1]
      })
    }
    console.log(this.linkList)
  
    this.initGlobe()
    $.getJSON(
      window.baseTileSetUrl + '/bridge/3DTiles/scenetree.json',
      data => {
        console.log(data, 'data')

        data.scenes[0].children.forEach(item => {
          this.isModel.push(item.name)
          let nameindex = that.link.indexOf(item.name)
          if(nameindex ==-1) return
          this.modelsInfo.push({
            name: item.name,
            sphere: item.sphere,
            view: item.view,
            deviceName:that.link[nameindex +1]
          })
        })
      }
    )
  },
  methods: {
    close() {
      this.$eventBus.$emit('close-bridge3D')
    },

    initGlobe() {
      const that = this
      // let camera = {
      //   tx: -0.7136319814816795,
      //   ty: -0.047839927192195875,
      //   tz: -0.04138341828969927,
      //   x: 0,
      //   y: 35.83969173914875,
      //   z: 37.38192392889593,
      //   near: 0.1,
      //   far: 20000
      // }

      /* eslint-disable */
      that.globe = new cg.Globe('tianqiaoCon')
       // 不显示底图
      that.globe._viewer.imageryLayers.get(0).show = false;
      // that.globe._viewer.scene.globe.show = false;
      // that.globe._viewer.scene.skyBox.show = false;
 
      // that.globe = new tg.Globe('tianqiaoCon',{camera})
      // that.globe.on(
      //   'click',
      //   e => {
      //     that.showInfoWindowByEntity(e)
      //   }
      // )
      // that.initObj()
      that.init3DTiles()
      that.setDefultCamera()
      that.viewer = this.globe._viewer
      that.fetchStdAll()

      var handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas)
      handler.setInputAction(function(event) {
        // 获取鼠标点击的位置
        if (that.pickedOBJ) {
          that.pickedOBJ.color = Cesium.Color.WHITE
          that.pickedOBJ = undefined
          that.currIndex = -1
        }

        let feature = that.viewer.scene.pick(event.position)
        if (Cesium.defined(feature)) {
          that.pickedOBJ = feature

          let name = feature.getProperty('name')
          console.log(name)
          let nameindex = that.link.indexOf(name)
          if(nameindex == -1 ) return
          let deviceName = that.link[nameindex+1]
          console.log(name,deviceName)
          that.stdList2.map(item => {
            if (
              // item.deviceLocation.indexOf('天桥') !== -1 &&
               deviceName == item.deviceName
              ) {
              if (!item.cameraUrl) {
                this.$message('暂无数据')
                return
              }
              that.pickedOBJ.color = Cesium.Color.RED
          
              that.$eventBus.$emit('dev-afjk', {
                url: item.cameraUrl,
                isFull: false
              })
            }
          })
        }
         // var earthPosition = that.viewer.camera.pickEllipsoid(
        //   event.position,
        //   that.viewer.scene.globe.ellipsoid
        // )
        // let postion = that.carToGraphic(
        //   earthPosition.x,
        //   earthPosition.y,
        //   earthPosition.z
        // )
        // console.log('点击的位置--->', postion)

        // // 获取当前摄像机中心点
        let cartesian = that.viewer.camera.position
        let center = that.carToGraphic(cartesian.x, cartesian.y, cartesian.z)
        console.log('摄像机中心点坐标--->', center, that.viewer.camera)
        // // that.setDefultCamera({ lng: '', lat: '' })
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
    },

    initObj: function() {
      const that = this
      that.globe.loadObj(
        window.baseObjUrl + '/bridge/obj/tianqiao.mtl',
        window.baseObjUrl + '/bridge/obj/tianqiao.obj',
        { isLight: false },
        e => {
          console.log(e, '加载进度')
          if (e == 100) {
            console.log(e)
          }
        }
      )
    },
    videoFull(e) {
      console.log(e)
      if (!e.cameraUrl) {
        this.$message('暂无数据')
        return
      }
      let isfull = false
     
      this.$eventBus.$emit('dev-afjk', {
        url: e.cameraUrl,
        isFull: isfull
      })
    },
    init3DTiles: function() {
      const that = this
    
      this.tileset = this.globe._viewer.scene.primitives.add(
        new Cesium.Cesium3DTileset({
          url: window.baseTileSetUrl + '/bridge/3DTiles/tileset.json'
        })
      )
      this.tileset.tileLoad.addEventListener(tile => {
        
        let content = tile.content._contents[0]
        let featuresLength = content.featuresLength

        that.features = []

        for (let i = 0; i < featuresLength; i++) {
          let feature = content.getFeature(i)
          let name = feature.getProperty('name')
          console.log(name,'sss')
         let nameindex = that.link.indexOf(name)
          let deviceName = that.link[nameindex+1]
          that.features.push({
            fea: content.getFeature(i),
            name: feature.getProperty('name'),
            deviceName:deviceName
          })
        }
       
      })
     
    },

    async fetchStdAll() {
      const l1 = await this.fetchStdData('10')
      const l2 = await this.fetchStdData('20')
      const l3 = await this.fetchStdData('30')
      const l4 = await this.fetchStdData('40')
      const l5 = await this.fetchStdData('50')
      this.stdList = [...l1, ...l2, ...l3, ...l4, ...l5]
      let that = this
      that.stdList2 = []

      setTimeout(() => {
        that.stdList.forEach(item => {
          // if (item.deviceName && that.isModel.indexOf(item.deviceName) !== -1) {
          // if (item.is3d && that.isModel.indexOf(item.is3d) !== -1) {
          if (item.is3d == '1') {
            that.stdList2.push(item)
          }
        })
      })

    },
    async fetchStdData(curServerArea) {
      const { resultCode, resultData } = await this.$axios.post(
        '/stat/deviceInfo/deviceListBySource/',
        {
          source: '2',
          businessScenario: curServerArea
        }
      )
      if (resultCode === '200' && resultData) {
        return resultData
      }
    },
    setDefultCamera() {
      let that = this
      this.currIndex = -1
      let camOPT = {
       lng: 116.39070302758563,
lat: 39.90682461070684,
height: 2.8731663189808363,
        heading: 1.1396670347048872,
        pitch: 0.11601378770283777,
        roll: 0.003158811133763706
      }
    
      that.globe._viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(
          camOPT.lng,
          camOPT.lat,
          camOPT.height
        ),
        orientation: {
        
          heading: camOPT.heading,
          pitch: camOPT.pitch,
          roll: camOPT.roll
        }
      })
    },
    showInfoWindowByEntity(e) {
      console.log(e)
    },
    locateCamera(obj, index) {
      let that = this
      this.currIndex = index

      if (that.pickedOBJ) {
        that.pickedOBJ.color = Cesium.Color.WHITE
        that.pickedOBJ = undefined
      }
      if (that.features.length) {
        that.features.forEach(fea => {
          if (fea.deviceName === obj.deviceName) {
            that.pickedOBJ = fea.fea
            that.pickedOBJ.color = Cesium.Color.RED
          }
        })
        that.modelsInfo.forEach(item => {
          if (item.deviceName === obj.deviceName) {
            const sphere = item.sphere
            let center = new Cesium.Cartesian3(sphere[0], sphere[1], sphere[2])
            const spheres = new Cesium.BoundingSphere(center, sphere[3])

            let hpt = new Cesium.HeadingPitchRange(1.90893, 0.061096, 4)
            if (item.view =='2') {
 
              hpt = new Cesium.HeadingPitchRange(
               4.5166698773866845,
0.06109441909350033,
                4.5
              )
            }else if(item.view =='3'){
              hpt = new Cesium.HeadingPitchRange(               
3.15314645144515,
0.04962857717399283,
                4.5
              )
            }

            that.globe._viewer.camera.viewBoundingSphere(spheres, hpt)
            that.videoFull(obj)
          }
        })
      }
    },
    carToGraphic(x, y, z) {
      let ellipsoid = this.viewer.scene.globe.ellipsoid
      let cartesian3 = new Cesium.Cartesian3(x, y, z) //传入世界坐标系
      let cartographic = ellipsoid.cartesianToCartographic(cartesian3)
      let lat = Cesium.Math.toDegrees(cartographic.latitude) //纬度
      let lng = Cesium.Math.toDegrees(cartographic.longitude) //经度
      let h = cartographic.height
      let coordinate = {
        lng: lng,
        lat: lat,
        h: h
      }
      return coordinate
    },
    beforeDestroy() {
      this.globe.removeAll()
    }
  }
}
</script>

<style scoped lang="scss">
.scene3D {
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;
  @include flex();
  align-items: center;
  justify-content: center;
  .box {
    width: 1800px;
    height: 90%;
    z-index: 11;
    @include flex();
    background: rgba(6, 23, 25, 0.7);
    border: 1px solid #ef9c00;
    border-radius: 10px;
    position: relative;
    .i-box {
      @include flex(row);
      width: 100%;
      font-size: 24px;
      padding: 30px;
      position: relative;
      &::-webkit-scrollbar {
        display: none;
      }
      .t-close {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 25px;
        height: 25px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .scene3D-box {
        width: 70%;
        height: 100%;
      }
      .video-box {
        width: 30%;
        height: 100%;
        overflow-y: scroll;
        @include scrollBar();
        .box1 {
          
          .t-content {
            position: relative;
            display: flex;
            flex-direction: row;
            //width: 495px;
            height: 70px;
            position: relative;
            align-items: center;
            padding-left: 10px;
            cursor: pointer;
          }

          &:nth-child(odd) .t-content {
            background: rgba(8, 74, 80, 0.7);
            //background: rgba(6, 23, 25, 1);
          }
          &:nth-child(even) .t-content {
            background: rgba(4, 48, 52, 0.7);
            //background: rgba(6, 23, 25, 0.5);
          }
          .t-content.active {
            background: rgba(8, 0, 0, 1);
            color: #c32828;
            font-weight: bolder;
          }
        }
        .t-itemBF {
          position: absolute;
          right: 10px;
        }
      }
      .reset-location {
        position: absolute;
        width: 60px;
        height: 60px;
        right: 40px;
        bottom: 40px;
        background: url('/images/com/reset-location.png') no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
  }
}
</style>
