/**
 * 高频事件触发，但在n秒内只会执行一次，所以节流会稀释函数的执行频率
 */
export function throttle(func, wait = 300) {
  let isOK = true // 标志位
  return function() {
    if (!isOK) return
    isOK = false
    setTimeout(() => {
      func.apply(this, arguments)
      isOK = true
    }, wait)
  }
}

/**
 * 触发高频事件后n秒内函数只会执行一次，如果n秒内高频事件再次被触发，则重新计算时间
 */
export function debounce(func, wait = 500, immediate = false) {
  let timer, result
  return function() {
    timer && clearTimeout(timer)
    if (immediate) {
      !timer && (result = func.apply(this, arguments))
    } else {
      // this 指向 和 event 指向； => 和 apply
      timer = setTimeout(() => {
        result = func.apply(this, arguments)
      }, wait)
    }
    return result
  }
}

/**
 * 根据元素大小排序
 * key:元素
 * desc:true 升序 false 降序
 */
export function compare(key, desc) {
  return function(a, b) {
    let value1 = a[key]
    let value2 = b[key]
    if (desc == true) {
      // 升序排列
      return value1 - value2
    } else {
      // 降序排列
      return value2 - value1
    }
  }
}
/**
 * 四舍五入 自定义小数位数，空缺补0
 */
export function round(num, n) {
  let dd = 1
  let tempnum
  for (let i = 0; i < n; i++) {
    dd *= 10
  }
  tempnum = num * dd
  tempnum = Math.round(tempnum)
  return formatnumber(tempnum / dd, n)
}

export function formatnumber(value, num) {
  var a, b, c, i
  a = value.toString()
  b = a.indexOf('.')
  c = a.length
  if (num == 0) {
    if (b != -1) {
      a = a.substring(0, b)
    }
  } else {
    if (b == -1) {
      a = a + '.'
      for (i = 1; i <= num; i++) {
        a = a + '0'
      }
    } else {
      a = a.substring(0, b + num + 1)
      for (i = c; i <= b + num; i++) {
        a = a + '0'
      }
    }
  }
  return a
}

var x_PI = (3.14159265358979324 * 3000.0) / 180.0
var PI = 3.1415926535897932384626
var a = 6378245.0
var ee = 0.00669342162296594323

export function gcj02tobd09(lng, lat) {
  var z = Math.sqrt(lng * lng + lat * lat) + 0.00002 * Math.sin(lat * x_PI)
  var theta = Math.atan2(lat, lng) + 0.000003 * Math.cos(lng * x_PI)
  var bd_lng = z * Math.cos(theta) + 0.0065
  var bd_lat = z * Math.sin(theta) + 0.0064
  return [bd_lng, bd_lat]
}

export function wgs84togcj02(lng, lat) {
  let dlat = transformlat(lng - 105.0, lat - 35.0)
  let dlng = transformlng(lng - 105.0, lat - 35.0)
  let radlat = (lat / 180.0) * PI
  let magic = Math.sin(radlat)
  magic = 1 - ee * magic * magic
  let sqrtmagic = Math.sqrt(magic)
  dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI)
  dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI)
  let mglat = lat + dlat
  let mglng = lng + dlng
  // console.log(mglng, mglat)
  return [mglng, mglat]
}

export function gcj02_to_wgs84(lng, lat) {
  let dlat = transformlat(lng - 105.0, lat - 35.0)
  let dlng = transformlng(lng - 105.0, lat - 35.0)
  let radlat = (lat / 180.0) * PI
  let magic = Math.sin(radlat)
  magic = 1 - ee * magic * magic
  let sqrtmagic = Math.sqrt(magic)
  dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI)
  dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI)
  let mglat = lat + dlat
  let mglng = lng + dlng
  return [lng * 2 - mglng, lat * 2 - mglat]
}

function transformlat(lng, lat) {
  var ret =
    -100.0 +
    2.0 * lng +
    3.0 * lat +
    0.2 * lat * lat +
    0.1 * lng * lat +
    0.2 * Math.sqrt(Math.abs(lng))
  ret +=
    ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) *
      2.0) /
    3.0
  ret +=
    ((20.0 * Math.sin(lat * PI) + 40.0 * Math.sin((lat / 3.0) * PI)) * 2.0) /
    3.0
  ret +=
    ((160.0 * Math.sin((lat / 12.0) * PI) + 320 * Math.sin((lat * PI) / 30.0)) *
      2.0) /
    3.0
  return ret
}

function transformlng(lng, lat) {
  var ret =
    300.0 +
    lng +
    2.0 * lat +
    0.1 * lng * lng +
    0.1 * lng * lat +
    0.1 * Math.sqrt(Math.abs(lng))
  ret +=
    ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) *
      2.0) /
    3.0
  ret +=
    ((20.0 * Math.sin(lng * PI) + 40.0 * Math.sin((lng / 3.0) * PI)) * 2.0) /
    3.0
  ret +=
    ((150.0 * Math.sin((lng / 12.0) * PI) +
      300.0 * Math.sin((lng / 30.0) * PI)) *
      2.0) /
    3.0
  return ret
}

export function arrayToString(arr = []) {
  if (arr.length <= 0) return ''
  return arr.join(',')
}
export function stringToArray(str) {
  if (str.length <= 0) return []
  return str.split(',')
}

// 校验是否为空
function isEmpty(test) {
  if (test === null || test === '') {
    return true
  } else {
    return false
  }
}
// 是否是有效手机号码
function isPhone(phone) {
  if (isEmpty(phone)) return false
  let error = /^1[3456789]\d{9}$/.test(phone)
  return error
}
// 身份证是否有效
// function isCardId(card) {
//   if (isEmpty(card)) return false
//   return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(card)
// }
// 是否是有效车牌
function isVehicleId(num) {
  if (isEmpty(num)) return false
  return (
    /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/.test(
      num
    ) ||
    /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][a-z](([0-9]{5}[df])|([df]([a-hj-np-z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][a-z][a-hj-np-z0-9]{4}[a-hj-np-z0-9挂学警港澳使领]))$/.test(
      num
    )
  )
}

function isNumber(num) {
  if (isEmpty(num)) return false
  let error = /^[0-9]+.{0,1}[0-9]{0,2}$/.test(num)
  return error
}

function isCardId(num) {
  let idcard = num
  if (idcard == '') {
    return false
  }
  let regex1 = /^[1-9][0-7]\d{4}((([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})(((0[13578]|1[02])(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)(0[1-9]|[12][0-9]|30))|(02(0[1-9]|[1][0-9]|2[0-8]))))|((([0-9]{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))0229))\d{3}(\d|X|x)?$/
  // 身份号码位数及格式检验
  let regex2 = ''
  switch (idcard.length) {
    case 15:
      if (
        (parseInt(idcard.substr(6, 2)) + 1900) % 4 == 0 ||
        ((parseInt(idcard.substr(6, 2)) + 1900) % 100 == 0 &&
          (parseInt(idcard.substr(6, 2)) + 1900) % 4 == 0)
      ) {
        regex2 = /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}$/ // 测试出生日期的合法性
      } else {
        regex2 = /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}$/ // 测试出生日期的合法性
      }

      if (regex2.test(idcard)) {
        return true
      } else {
        return false
      }
    case 18:
      if (regex1.test(idcard)) {
        let S =
          (parseInt(idcard[0]) + parseInt(idcard[10])) * 7 +
          (parseInt(idcard[1]) + parseInt(idcard[11])) * 9 +
          (parseInt(idcard[2]) + parseInt(idcard[12])) * 10 +
          (parseInt(idcard[3]) + parseInt(idcard[13])) * 5 +
          (parseInt(idcard[4]) + parseInt(idcard[14])) * 8 +
          (parseInt(idcard[5]) + parseInt(idcard[15])) * 4 +
          (parseInt(idcard[6]) + parseInt(idcard[16])) * 2 +
          parseInt(idcard[7]) * 1 +
          parseInt(idcard[8]) * 6 +
          parseInt(idcard[9]) * 3
        let Y = S % 11
        let M = 'F'
        let JYM = '10X98765432'
        M = JYM.substr(Y, 1)
        // 判断校验位
        if (M == idcard[17].toUpperCase()) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    default:
      return false
  }
}

export const RegexTool = {
  isEmpty,
  isPhone,
  isCardId,
  isVehicleId,
  isNumber
}
