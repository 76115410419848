<template>
  <div id="app">
    <div class="map-mask" v-if="showMask"></div>
    <div class="ekmap">
      <EkMap @map-complete="mapComplete" />
      <div
        class="reset-location"
        :style="{
          right: resetIconStyle.right,
          bottom: resetIconStyle.bottom
        }"
        @click="handleResetLocation"
      ></div>
    </div>
    <Header />
    <div class="content">
      <router-view />
    </div>

    <emo-list ref="csEmoList" />
    <emo-detail ref="csEmoDetail" />
    <video-detail ref="iwVideoDetail" />
    <activity-detail
      ref="DoActivityDetail"
      :width="htmlWidth"
      style="margin: 0 auto"
    />
    <modal-img ref="modalWechartDemo" />
    <ldpop-info
      ref="ldpopInfo"
      style="position: absolute;bottom: 17px;left: 26%;"
    />
    <stdcam-panel ref="stdcamPanel"></stdcam-panel>

    <bridge3D v-if="showBridge" />
  </div>
</template>

<script>
import Header from '@/components/Header'
import EkMap from '@/components/Map'
import { mapGetters } from 'vuex'

import EmoList from '@/views/cs/components/emoList.vue'
import EmoDetail from '@/views/cs/components/emoDetail.vue'
import videoDetail from '@/views/iw/components/videoDetail.vue'
import ActivityDetail from '@/views/do/components/R1/activityDetail.vue'
import ModalImg from '@/components/Header/modalImg.vue'
import ldpopInfo from '@/views/iw/components/ldPopInfo'
import stdcamPanel from '@/views/std/components/stdCamPanel'
import bridge3D from '@/views/iw/components/bridge3D'
export default {
  data() {
    return {
      showMask: false,
      htmlWidth: undefined,
      resetIconStyle: {
        right: '',
        bottom: ''
      },
      showBridge: false
    }
  },
  components: {
    Header,
    EkMap,
    bridge3D,
    EmoList,
    EmoDetail,
    videoDetail,
    ActivityDetail,
    ModalImg,
    ldpopInfo,
    stdcamPanel
  },
  computed: {
    ...mapGetters(['headerType', 'menuType'])
  },
  watch: {
    headerType: {
      handler(val) {
        if (val == 'ov') {
          this.resetIconStyle.right = '32.5%'
          this.resetIconStyle.bottom = '30px'
        } else if (val != 'ov' && val != 'bdg' && val != 'do') {
          this.resetIconStyle.right = '32.5%'
          this.resetIconStyle.bottom = '30px'
        } else if (val == 'do') {
          this.resetIconStyle.right = '27%'
          this.resetIconStyle.bottom = '18px'
        }
      },
      immediate: true
    },
    menuType: {
      handler(val) {
        if (val == 'br' || val == 'jqfw') {
          this.resetIconStyle.right = '32.5%'
          this.resetIconStyle.bottom = '30px'
        } else if (val == 'bfp' || val == 'dc' || val == 'dh') {
          this.resetIconStyle.right = '27%'
          this.resetIconStyle.bottom = '18px'
        } else if (val == 'jqdj') {
          this.resetIconStyle.right = '34%'
          this.resetIconStyle.bottom = '20px'
        } else if (val == 'jqzl') {
          this.resetIconStyle.right = '32%'
          this.resetIconStyle.bottom = '46%'
        }
      },
      immediate: false
    }
  },
  mounted() {
    this.$eventBus.$off('stdcam-panel')
    this.$eventBus.$on('stdcam-panel', item => {
      if (item) {
        this.$refs.stdcamPanel.open()
        this.$refs.stdcamPanel.data = item
      } else {
        this.$refs.stdcamPanel.close()
      }
    })

    this.$eventBus.$off('cs_emo_list')
    this.$eventBus.$on('cs_emo_list', name => {
      this.$refs.csEmoList.open(name)
    })
    this.$eventBus.$off('cs_emo_detail')
    this.$eventBus.$on('cs_emo_detail', url => {
      this.$refs.csEmoDetail.open(url, '1800px')
    })
    this.$eventBus.$off('dev-afjk')
    this.$eventBus.$on('dev-afjk', url => {
      this.$refs.iwVideoDetail.open(url)
    })
    this.$eventBus.$off('do_activity-detail')
    this.$eventBus.$on('do_activity-detail', html => {
      this.$refs.DoActivityDetail.open(html)
    })
    this.$eventBus.$off('ov-dh-popImg')
    this.$eventBus.$on('ov-dh-popImg', html => {
      this.$refs.DoActivityDetail.open(html)
    })
    this.$eventBus.$off('ov-bfp-r1map-qpgj')
    this.$eventBus.$on('ov-bfp-r1map-qpgj', html => {
      this.$refs.DoActivityDetail.open(html)
      this.htmlWidth = '500px'
      // console.log(this.$refs.DoActivityDetail.$el)
      // this.$refs.DoActivityDetail.$el.clientWidth ='400px !important'
      // this.$refs.DoActivityDetail.classList.add('ctivityDetail')
    })
    this.$eventBus.$off('wechat-demo')
    this.$eventBus.$on('wechat-demo', type => {
      this.$refs.modalWechartDemo.open(type)
    })
    this.$eventBus.$off('ldpop-demo')
    this.$eventBus.$on('ldpop-demo', item => {
      console.log(item)
      if (item) {
        this.$refs.ldpopInfo.open()
        this.$refs.ldpopInfo.data = item.data
      } else {
        this.$refs.ldpopInfo.close()
      }
    })
    this.$eventBus.$off('iw-bridge3D')
    this.$eventBus.$on('iw-bridge3D', () => {
      this.showBridge = true
    })
    this.$eventBus.$off('close-bridge3D')
    this.$eventBus.$on('close-bridge3D', () => {
      this.showBridge = false
    })
  },
  methods: {
    mapComplete() {
      this.showMask = true
    },
    handleResetLocation() {
      this.$eventBus.$emit('reset-mapCenter')
    }
  }
}
</script>

<style lang="scss">
.map-mask {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('/images/com/mask.png') no-repeat;
  background-size: 100% 100%;
  pointer-events: none;
  opacity: 0.8;
}
.ekmap {
  position: absolute;
  @include flex();
  // align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .reset-location {
    position: absolute;
    width: 60px;
    height: 60px;
    bottom: 18px;
    background: url('/images/com/reset-location.png') no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
}
.content {
  position: relative;
  z-index: 2;
  pointer-events: none;
}
.ctivityDetail {
  width: 400px;
}
</style>
