<template>
    <div class="container mapCom"
         id="mapContainer"
         ref="amap_map">
        <div class="mark"></div>
        <div class="mask-loading"
             v-if="showMask">
            <a class="el-icon-loading"></a>
        </div>
        <klinfo-pop ref="iwInfo"></klinfo-pop>
        <tcinfo-pop ref="tcInfo"></tcinfo-pop>
        <!--    井盖和报警柱-->
        <bjzinfo-pop ref="bjzInfo"></bjzinfo-pop>
        <div class="dcBox"
             v-if="isDcFlag">
            <div class="dcItem"
                 v-if="dcStage1Flag"
                 :class="curDCstage === '1' ? 'active' : ''"
                 @click="getDcStage01">
                1
            </div>
            <div class="dcItem"
                 v-if="dcStage2Flag"
                 :class="curDCstage === '2' ? 'active' : ''"
                 @click="getDcStage02">
                2
            </div>
            <div class="dcItem"
                 v-if="dcStage3Flag"
                 :class="curDCstage === '3' ? 'active' : ''"
                 @click="getDcStage03">
                3
            </div>
            <div class="dcItem"
                 v-if="dcStage4Flag"
                 :class="curDCstage === '4' ? 'active' : ''"
                 @click="getDcStage04">
                4
            </div>
        </div>
    </div>
</template>

<script>
import { fetchData, fetchFindByType } from '@/api'
import { cs_l1 } from '@/api/cs'
import {
    alarmList,
    deviceAlarmNum,
    map_dev_marker,
    parkAndPssengerFlowDetail
} from '@/api/other'
import msgclient from '@/utils/rabbitMQ/msgclient.js'
import { _axios } from '@/utils/request'
import { requestPostBi } from '@/utils/requestMiddleground'
import { compare, wgs84togcj02 } from '@/utils/tools'
import bjzinfoPop from '@/views/iw/components/bjzinfoPop'
import klinfoPop from '@/views/iw/components/klinfoPop'
import tcinfoPop from '@/views/iw/components/tccinfoPop'
import dcStage from '@/views/ov/dc/dcStage'
import AMapLoader from '@amap/amap-jsapi-loader'
import * as echarts from 'echarts'
import { mapGetters } from 'vuex'
import { mapConfig } from './config'
var map = null
var AMap = null
var loca = null
// var Loca = null
var currType = ''
var legendType = ''
var markerList = []
var bfplistmarker = []
var labelList = []
var lineList = []
var polygonList = []
var polygonLabels = []
var pathLayerList = []
var passedLineList = []
var animarkerList = []
var sourceAnimarkerList = []
var devApiSourceList = []
var devApiSourceListSTD = []
var rabbitMQReplaceDataInterval = null
var aniLabelList = []
var stdHeatData = []
var imageLayer = null
var heatmap = null

var ovdhHeatmap = null
var list = []
var infoWindow = null
var roadTrafficLayer = null
var addMarkerAniInterval = null
var delMarkerAniInterval = null
var timeout1 = null
var timeout2 = null
var tempImageLayers = []
var tscy = undefined
var lyxf = undefined
export default {
    data() {
        return {
            showMask: true,
            active: 0,
            dcStage1Flag: true,
            dcStage2Flag: false,
            dcStage3Flag: false,
            dcStage4Flag: false,
            curDCstage: ''
        }
    },
    mixins: [msgclient, dcStage],
    computed: {
        ...mapGetters(['headerType', 'menuType'])
    },
    watch: {
        headerType(type) {
            this.watchHeaderType(type)

            if (type == 'iw' || type == 'cs' || type == 'std') {
                if (this.client && this.client.connected) {
                    this.onRabbitMQDisconnect()
                }
                this.connectRabbitMQ()
            } else if (type != 'iw' || type != 'cs' || type != 'std') {
                if (this.client && this.client.connected) {
                    this.onRabbitMQDisconnect()
                }
            }
        },
        menuType(type) {
            if (type) {
                this.watchMenuType(type)
            }
        }
    },
    components: {
        klinfoPop,
        tcinfoPop,
        bjzinfoPop
    },
    mounted() {
        this.initMap()
    },
    methods: {
        async initMap() {
            AMapLoader.load(mapConfig.base).then(res => {
                // 初始化地图
                AMap = res
                map = new res.Map('mapContainer', mapConfig.style)
                // Loca = window.Loca
                // loca = new Loca.Container({
                //     map
                // })

                window.loca = loca
                window.map = map
                window.AMap = AMap
                map.setZoom(14.68)

                map.on('zoomend', () => {
                    console.log(map.getZoom())
                })
                map.on('moveend', () => {
                    console.log('地图中心点:' + map.getCenter())
                    console.log('旋转角度:' + map.getRotation())
                    console.log('倾斜角:' + map.getPitch())
                    console.log('zoom:' + map.getZoom())
                })
                map.on('complete', () => {
                    this.mapComplete()
                    setTimeout(() => {
                        map.setZoom(mapConfig.style.zoom, false, 3000)
                    }, 500)
                })
                const i = new AMap.InfoWindow({
                    isCustom: true, //使用自定义窗体
                    content: this.$refs.iwInfo.$el,
                    offset: new AMap.Pixel(300, -245)
                })
                i.open(map, [0, 0])
                const ii = new AMap.InfoWindow({
                    isCustom: true, //使用自定义窗体
                    content: this.$refs.tcInfo.$el,
                    offset: new AMap.Pixel(300, -245)
                })
                ii.open(map, [0, 0])
                const iii = new AMap.InfoWindow({
                    isCustom: true, //使用自定义窗体
                    content: this.$refs.bjzInfo.$el,
                    offset: new AMap.Pixel(300, -245)
                })
                iii.open(map, [0, 0])

                const mapBg = new AMap.ImageLayer({
                    url: `/images/com/map-bg.png`,
                    // url: `/images/com/td821.png`,
                    bounds: new AMap.Bounds(
                        // [120.32777054745598, 36.069298701440644],
                        // [120.36593833005162, 36.101799003025555]
                        [120.32777054745598, 36.069298701440644],
                        [120.36593833005162, 36.101799003025555]
                        // [120.3256358214555, 36.06891546794108],
                        // [120.36684953697807, 36.10245480219525]
                        // [120.35347642754023, 36.08390651024466]
                        // , [120.32780045241746, 36.06941068177729]
                    ),
                    zooms: [10, 20]
                })
                map.add(mapBg)

                let trafficLayer = new AMap.TileLayer.Traffic({
                    zIndex: 20
                })
                roadTrafficLayer = new AMap.LayerGroup([trafficLayer])
                roadTrafficLayer.setMap(map)
                roadTrafficLayer.hide()

                // let count = 0
                // let imagePath = []
                map.on('click', ({ lnglat }) => {
                    console.log(lnglat.lng + ',' + lnglat.lat)
                    if (infoWindow) {
                        map.clearInfoWindow()
                    }

                    // this.clearMap()
                    // if (count == 2) {
                    //   count = 0
                    // }
                    // imagePath[count] = [lnglat.lng, lnglat.lat]
                    // if (imagePath.length == 2) {
                    //   mapBg.setBounds(new AMap.Bounds(imagePath[0], imagePath[1]))
                    // }
                    // count++
                })

                // var path = [
                //   [120.349602, 36.079489],
                //   [120.353389, 36.077239],
                //   [120.351469, 36.080794],
                //   [120.353207, 36.082719],
                //   [120.361017, 36.077742]
                // ]
                // var markers = []
                // for (var i = 0; i < path.length; i++) {
                //   var marker = new AMap.Marker({
                //     position: path[i]
                //   })
                //   markers.push(marker)
                // }
                // map.add(markers)
            })
        },
        mapComplete() {
            this.showMask = false
            this.$emit('map-complete')
            this.watchHeaderType(this.headerType)

            this.$eventBus.$on('reset-mapCenter', () => {
                this.resetMapCenter()
                if (this.$route.name == 'dc') {
                    this.dcStage1Flag = true
                    this.dcStage2Flag = false
                    this.dcStage3Flag = false
                    this.dcStage4Flag = false
                    this.curDCstage = ''
                    this.clearMap()
                    map.setZoomAndCenter(16.77, [120.352486, 36.082912])
                    map.setPitch(mapConfig.style.pitch)
                    map.setRotation(mapConfig.style.rotation)
                    setTimeout(() => {
                        this.isDcFlag = true
                        map &&
                            map.setStatus({
                                dragEnable: false,
                                doubleClickZoom: false,
                                scrollWheel: false
                            })
                    })
                }
            })
        },
        /**
         * 一级菜单  变化监听事件
         * @param type
         */
        watchHeaderType(type) {
            this.clearCsCover()
            this.clearMap()
            currType = type
            legendType = ''
            list = []
            if (rabbitMQReplaceDataInterval) {
                clearInterval(rabbitMQReplaceDataInterval)
                rabbitMQReplaceDataInterval = undefined
            }

            switch (type) {
                case 'ov':
                    this.watchMenuType(this.menuType)

                    this.$eventBus.$off('iw-dc-legend')
                    this.$eventBus.$on('iw-dc-legend', item => {
                        console.log(item, 'dc 发展理念')
                        if (item === 'dc') {
                            this.dcStage1Flag = true
                            this.dcStage2Flag = false
                            this.dcStage3Flag = false
                            this.dcStage4Flag = false
                            this.curDCstage = ''
                            map.setZoomAndCenter(16.77, [120.352486, 36.082912])
                            map.setPitch(mapConfig.style.pitch)
                            map.setRotation(mapConfig.style.rotation)

                            this.clearMap()
                            setTimeout(() => {
                                this.isDcFlag = true
                                map &&
                                    map.setStatus({
                                        dragEnable: false,
                                        doubleClickZoom: false,
                                        scrollWheel: false
                                    })
                            })
                        }
                    })
                    break
                case 'iw': {
                    this.fetchIwData(null)

                    rabbitMQReplaceDataInterval = setInterval(() => {
                        this.clearMap()
                        this.formatItemForData(devApiSourceList)
                        console.log('rabbitMQ更新设备数据')
                    }, 1000 * 60)
                    this.$eventBus.$off('iw-legend')
                    this.$eventBus.$on('iw-legend', item => {
                        legendType = item.type
                        this.clearMap()
                        this.fetchIwData(item)
                        map.setZoomAndCenter(16.77, [120.352486, 36.082912])
                        map.setPitch(mapConfig.style.pitch)
                        map.setRotation(mapConfig.style.rotation)

                        clearInterval(rabbitMQReplaceDataInterval)
                        rabbitMQReplaceDataInterval = null
                        rabbitMQReplaceDataInterval = setInterval(() => {
                            this.clearMap()
                            this.fetchIwData(item)
                            console.log('rabbitMQ更新设备数据')
                        }, 1000 * 60)
                    })

                    this.$eventBus.$off('iw-loopSwitch')
                    this.$eventBus.$on('iw-loopSwitch', val => {
                        legendType = 'zndg'
                        console.log(val, '灯杆')

                        setTimeout(() => {
                            this.fetchIwDataRefesh()
                        }, 2000)
                        map.setZoomAndCenter(16.77, [120.352486, 36.082912])
                        map.setPitch(mapConfig.style.pitch)
                        map.setRotation(mapConfig.style.rotation)
                    })
                    break
                }
                case 'cs':
                    {
                        this.aniList = [
                            {
                                type: 'online',
                                lnglat: [120.348522, 36.081618],
                                name: '小帅1'
                            },
                            { type: 'trade', lnglat: [120.349803, 36.08306], name: '小美1' },
                            {
                                type: 'ordering',
                                lnglat: [120.350702, 36.08213],
                                name: '小帅2'
                            },
                            { type: 'rate', lnglat: [120.349997, 36.081384], name: '小美2' },
                            {
                                type: 'activity',
                                lnglat: [120.349826, 36.080853],
                                name: '小帅3'
                            },

                            {
                                type: 'online',
                                lnglat: [120.357665, 36.085048],
                                name: '小美3'
                            },
                            { type: 'trade', lnglat: [120.35714, 36.083357], name: '小帅4' },
                            {
                                type: 'ordering',
                                lnglat: [120.356159, 36.084597],
                                name: '小美4'
                            },
                            { type: 'rate', lnglat: [120.355465, 36.084046], name: '小帅5' },
                            {
                                type: 'activity',
                                lnglat: [120.356342, 36.086873],
                                name: '小美5'
                            },
                            {
                                type: 'online',
                                lnglat: [120.352634, 36.081425],
                                name: '小帅1'
                            },
                            {
                                type: 'online',
                                lnglat: [120.353164, 36.08186],
                                name: '小美1'
                            },
                            { type: 'trade', lnglat: [120.353891, 36.0826], name: '小帅2' },
                            {
                                type: 'trade',
                                lnglat: [120.354525, 36.083227],
                                name: '小美2'
                            },
                            { type: 'ordering', lnglat: [120.353324, 36.081], name: '小帅3' },
                            {
                                type: 'ordering',
                                lnglat: [120.354143, 36.081573],
                                name: '小美3'
                            },
                            { type: 'rate', lnglat: [120.354319, 36.080361], name: '小帅4' },
                            {
                                type: 'rate',
                                lnglat: [120.355141, 36.081049],
                                name: '小美4'
                            },
                            {
                                type: 'activity',
                                lnglat: [120.356301, 36.082262],
                                name: '小帅5'
                            },
                            {
                                type: 'activity',
                                lnglat: [120.357135, 36.083281],
                                name: '小美5'
                            },

                            {
                                type: 'online',
                                lnglat: [120.357185, 36.081201],
                                name: '小帅1'
                            },
                            {
                                type: 'online',
                                lnglat: [120.358289, 36.082078],
                                name: '小美1'
                            },
                            {
                                type: 'trade',
                                lnglat: [120.359279, 36.082992],
                                name: '小帅2'
                            },
                            {
                                type: 'trade',
                                lnglat: [120.358638, 36.079876],
                                name: '小美2'
                            },
                            {
                                type: 'ordering',
                                lnglat: [120.359387, 36.080199],
                                name: '小帅3'
                            },
                            {
                                type: 'ordering',
                                lnglat: [120.35151, 36.083872],
                                name: '小美3'
                            },
                            {
                                type: 'rate',
                                lnglat: [120.352007, 36.083552],
                                name: '小帅4'
                            },
                            {
                                type: 'rate',
                                lnglat: [120.352879, 36.082959],
                                name: '小美4'
                            },
                            {
                                type: 'activity',
                                lnglat: [120.353698, 36.083812],
                                name: '小帅5'
                            },
                            {
                                type: 'activity',
                                lnglat: [120.354568, 36.084888],
                                name: '小美5'
                            },
                            {
                                type: 'online',
                                lnglat: [120.348833, 36.081409],
                                name: '小帅1'
                            },
                            {
                                type: 'online',
                                lnglat: [120.349227, 36.080617],
                                name: '小美1'
                            },
                            {
                                type: 'trade',
                                lnglat: [120.349668, 36.079946],
                                name: '小帅2'
                            },
                            {
                                type: 'trade',
                                lnglat: [120.351051, 36.081177],
                                name: '小美2'
                            },
                            {
                                type: 'ordering',
                                lnglat: [120.354721, 36.083223],
                                name: '小帅3'
                            },

                            { type: 'trade', lnglat: [120.353757, 36.085671], name: '小帅1' },
                            {
                                type: 'ordering',
                                lnglat: [120.352148, 36.085511],
                                name: '小美1'
                            },
                            { type: 'rate', lnglat: [120.352313, 36.084334], name: '小帅2' },

                            {
                                type: 'activity',
                                lnglat: [120.357616, 36.085328],
                                name: '小美2'
                            },
                            {
                                type: 'online',
                                lnglat: [120.356462, 36.085409],
                                name: '小帅3'
                            },
                            { type: 'trade', lnglat: [120.356628, 36.084373], name: '小美3' },
                            {
                                type: 'ordering',
                                lnglat: [120.357989, 36.083732],
                                name: '小帅4'
                            },
                            { type: 'rate', lnglat: [120.358929, 36.083594], name: '小美4' },

                            {
                                type: 'activity',
                                lnglat: [120.358458, 36.084549],
                                name: '小帅5'
                            },
                            {
                                type: 'online',
                                lnglat: [120.356899, 36.086567],
                                name: '小美5'
                            },
                            { type: 'trade', lnglat: [120.355961, 36.087179], name: '小帅1' },
                            {
                                type: 'ordering',
                                lnglat: [120.355094, 36.087791],
                                name: '小美1'
                            },
                            { type: 'rate', lnglat: [120.35, 36.081501], name: '小帅2' },
                            {
                                type: 'activity',
                                lnglat: [120.349453, 36.083329],
                                name: '小美2'
                            },
                            { type: 'online', lnglat: [120.35095, 36.081742], name: '小帅3' },
                            { type: 'trade', lnglat: [120.350261, 36.082301], name: '小美3' }
                        ]
                        roadTrafficLayer.show()
                        this.fetchCsData(null)
                        this.$eventBus.$off('cs-legend')
                        this.$eventBus.$on('cs-legend', item => {
                            this.clearMap()
                            this.fetchCsData(item)
                        })
                        map.setZoomAndCenter(mapConfig.style.zoom, [120.353356, 36.08167])
                    }

                    break
                case 'do':
                    this.aniList = [
                        {
                            type: 'online',
                            lnglat: [120.353094, 36.082639],
                            name: '小帅1'
                        },
                        { type: 'trade', lnglat: [120.353588, 36.083228], name: '小美1' },
                        {
                            type: 'ordering',
                            lnglat: [120.354312, 36.084044],
                            name: '小帅2'
                        },
                        { type: 'rate', lnglat: [120.355183, 36.085051], name: '小美2' },
                        {
                            type: 'activity',
                            lnglat: [120.354371, 36.083471],
                            name: '小帅3'
                        },
                        {
                            type: 'online',
                            lnglat: [120.354879, 36.08316],
                            name: '小美3'
                        },
                        { type: 'trade', lnglat: [120.355528, 36.08277], name: '小帅4' },
                        {
                            type: 'ordering',
                            lnglat: [120.356058, 36.082363],
                            name: '小美4'
                        },
                        { type: 'rate', lnglat: [120.356786, 36.081934], name: '小帅5' },
                        {
                            type: 'activity',
                            lnglat: [120.357564, 36.081431],
                            name: '小美5'
                        },
                        {
                            type: 'online',
                            lnglat: [120.358374, 36.080853],
                            name: '小帅1'
                        },
                        {
                            type: 'online',
                            lnglat: [120.357585, 36.080983],
                            name: '小美1'
                        },
                        { type: 'trade', lnglat: [120.356859, 36.082605], name: '小帅2' },
                        {
                            type: 'trade',
                            lnglat: [120.355436, 36.082086],
                            name: '小美2'
                        },
                        {
                            type: 'ordering',
                            lnglat: [120.35549, 36.083386],
                            name: '小帅3'
                        },
                        {
                            type: 'ordering',
                            lnglat: [120.353999, 36.082803],
                            name: '小美3'
                        },
                        { type: 'rate', lnglat: [120.354771, 36.083822], name: '小帅4' },
                        {
                            type: 'rate',
                            lnglat: [120.353011, 36.083264],
                            name: '小美4'
                        },
                        {
                            type: 'activity',
                            lnglat: [120.354081, 36.084447],
                            name: '小帅5'
                        },
                        {
                            type: 'activity',
                            lnglat: [120.353752, 36.081766],
                            name: '小美5'
                        },
                        {
                            type: 'online',
                            lnglat: [120.351422, 36.081879],
                            name: '小帅1'
                        },
                        {
                            type: 'online',
                            lnglat: [120.351689, 36.082945],
                            name: '小美1'
                        },
                        {
                            type: 'trade',
                            lnglat: [120.352137, 36.083837],
                            name: '小帅2'
                        },
                        {
                            type: 'trade',
                            lnglat: [120.355445, 36.084391],
                            name: '小美2'
                        },
                        {
                            type: 'ordering',
                            lnglat: [120.355098, 36.0822],
                            name: '小帅3'
                        },
                        {
                            type: 'ordering',
                            lnglat: [120.355213, 36.08265],
                            name: '小美3'
                        },
                        {
                            type: 'rate',
                            lnglat: [120.356745, 36.081358],
                            name: '小帅4'
                        },
                        {
                            type: 'rate',
                            lnglat: [120.357714, 36.081681],
                            name: '小美4'
                        },
                        {
                            type: 'activity',
                            lnglat: [120.357631, 36.080682],
                            name: '小帅5'
                        },
                        {
                            type: 'activity',
                            lnglat: [120.358558, 36.080414],
                            name: '小美5'
                        },
                        {
                            type: 'online',
                            lnglat: [120.35911, 36.080724],
                            name: '小帅1'
                        },
                        {
                            type: 'online',
                            lnglat: [120.358038, 36.082299],
                            name: '小美1'
                        },
                        {
                            type: 'trade',
                            lnglat: [120.356827, 36.083002],
                            name: '小帅2'
                        },
                        {
                            type: 'trade',
                            lnglat: [120.355625, 36.083865],
                            name: '小美2'
                        },
                        {
                            type: 'ordering',
                            lnglat: [120.351504, 36.082052],
                            name: '小帅3'
                        },

                        { type: 'trade', lnglat: [120.350106, 36.082644], name: '小帅1' },
                        {
                            type: 'ordering',
                            lnglat: [120.350184, 36.081609],
                            name: '小美1'
                        },
                        { type: 'rate', lnglat: [120.350104, 36.079995], name: '小帅2' },
                        {
                            type: 'activity',
                            lnglat: [120.349279, 36.080632],
                            name: '小美2'
                        },
                        {
                            type: 'online',
                            lnglat: [120.351078, 36.080896],
                            name: '小帅3'
                        },
                        { type: 'trade', lnglat: [120.353052, 36.081235], name: '小美3' },
                        {
                            type: 'ordering',
                            lnglat: [120.354401, 36.081054],
                            name: '小帅4'
                        },
                        { type: 'rate', lnglat: [120.35369, 36.083751], name: '小美4' },
                        {
                            type: 'activity',
                            lnglat: [120.354093, 36.083686],
                            name: '小帅5'
                        },
                        {
                            type: 'online',
                            lnglat: [120.355424, 36.085314],
                            name: '小美5'
                        },
                        { type: 'trade', lnglat: [120.354567, 36.082746], name: '小帅1' },
                        {
                            type: 'ordering',
                            lnglat: [120.356071, 36.083424],
                            name: '小美1'
                        },
                        { type: 'rate', lnglat: [120.356617, 36.081992], name: '小帅2' },
                        {
                            type: 'activity',
                            lnglat: [120.356424, 36.082438],
                            name: '小美2'
                        },
                        { type: 'online', lnglat: [120.351467, 36.081717], name: '小帅3' },
                        { type: 'trade', lnglat: [120.352019, 36.083021], name: '小美3' }
                    ]
                    this.addOnlineUserMarker()
                    map.setZoomAndCenter(mapConfig.style.zoom, [120.353356, 36.08167])
                    break
                case 'bdg':
                    this.watchMenuType(this.menuType)
                    break
                case 'std':
                    this.fetchstdHeatData()
                    this.stdType = 'afjk'
                    this.clearMap()
                    clearInterval(rabbitMQReplaceDataInterval)
                    rabbitMQReplaceDataInterval = null
                    rabbitMQReplaceDataInterval = setInterval(() => {
                        // this.clearMap()
                        this.fetchStdData(this.stdType)
                        console.log('rabbitMQ更新设备数据-->安全台东')
                    }, 1000 * 60)
                    // this.fetchStdData(null)
                    // 上次注册的事件不会随着组件销毁,导致事件多次注册和执行,注册前注销即可
                    this.$eventBus.$off('ov-std-deviceList')
                    this.$eventBus.$on('ov-std-deviceList', res => {
                        // list =[...this.list1,...res]
                        list = [...res]
                        devApiSourceListSTD = [...res]
                        this.fetchStdData(this.stdType)
                    })
                    //过街天桥预警
                    this.$eventBus.$off('std-r1-iswarning-map')
                    this.$eventBus.$on('std-r1-iswarning-map', res => {
                        this.stdBridgeWarn = res.flag
                        this.stdType = 'afjk'
                    })
                    this.$eventBus.$off('std-legend-map')

                    this.$eventBus.$on('std-legend-map', item => {
                        this.stdType = item.type
                        // console.log(item,'测试 std-legend')
                        if (item.type === 'rlrl') {
                            this.fetchStdData(this.stdType)
                        }
                    })

                    this.$eventBus.$off('std-legedAFJK-map')
                    this.$eventBus.$on('std-legedAFJK-map', () => {
                        this.stdType = 'afjk'
                    })
                    break
            }
        },

        /**
         * 二级菜单  变化监听事件
         * @param type
         */

        async watchMenuType(type) {
            this.clearMap()
            currType = type
            list = []
            switch (type) {
                case 'br':
                    this.fetchOvBrData()
                    this.addOvBrOverlays()
                    this.$eventBus.$off('ov-br-legend')
                    this.$eventBus.$on('ov-br-legend', item => {
                        this.filterOvBrData(item)
                    })
                    this.$eventBus.$off('ov-br-r3')
                    this.$eventBus.$on('ov-br-r3', item => {
                        this.$eventBus.$emit('ov-br-r3ToLegned', item)
                        this.filterOvBrData(item)
                        this.openInfoWindow(item)
                    })
                    this.$eventBus.$off('ov-br-l2ToMap')
                    this.$eventBus.$on('ov-br-l2ToMap', item => {
                        this.filterOvBrData(item)
                    })
                    break
                case 'bfp':
                    this.fetchOvBfpData('sxlz')
                    this.fetchOvbfpR1Data()
                    this.$eventBus.$off('ov-bfp-l1ToMap')
                    this.$eventBus.$on('ov-bfp-l1ToMap', type => {
                        this.clearMap()
                        this.fetchOvBfpData(type)
                    })
                    this.$eventBus.$off('ov-bfp-r1map')
                    this.$eventBus.$on('ov-bfp-r1map', type => {
                        this.clearMap()
                        this.fetchOvBfpData(type)
                    })
                    break
                case 'dh':
                    this.$eventBus.$off('ov-dh-node-loadMarker')
                    this.$eventBus.$on('ov-dh-node-loadMarker', item => {
                        this.clearMap()
                        this.fetchOvDhData(item)
                    })
                    this.$eventBus.$off('ov-dh-node-updateMarker')
                    this.$eventBus.$on('ov-dh-node-updateMarker', item => {
                        this.clearMap()
                        this.fetchOvDhData(item)
                    })
                    break
                case 'jqdj':
                    await this.getJqdjMapLegend()
                    this.fetchBdgJqdjData()
                    // 上次注册的事件不会随着组件销毁,导致事件多次注册和执行,注册前注销即可
                    this.$eventBus.$off('bdg-jqdj-legend')
                    this.$eventBus.$on('bdg-jqdj-legend', item => {
                        this.clearMap()
                        this.filterBdgJqdjData(item)
                    })
                    break
                case 'jqzl':
                    await this.getJqzlMapLegend()
                    this.fetchBdgJqzlData()
                    this.$eventBus.$off('bdg-jqzl-legend')
                    this.$eventBus.$on('bdg-jqzl-legend', item => {
                        this.clearMap()
                        this.filterBdgJqzlData(item)
                    })
                    break
                case 'jqfw':
                    await this.getJqfwMapLegend()
                    this.fetchBdgJqfwData()
                    this.$eventBus.$off('bdg-jqfw-legend')
                    this.$eventBus.$on('bdg-jqfw-legend', item => {
                        this.clearMap()
                        this.filterBdgJqfwData(item)
                    })
                    break
            }
        },

        listenerRabbitConnect(queueName) {
            let RABBITMQ_QUEUE = ''
            if (currType == 'iw' || currType == 'std') {
                RABBITMQ_QUEUE = '/exchange/wdt.ioc.exchange'
            } else if (currType == 'cs') {
                RABBITMQ_QUEUE = '/exchange/wdt.user.operate.exchange'
            }
            this.client.subscribe(RABBITMQ_QUEUE, this.onRabbitMQresponseCallback, {
                'x-queue-name': queueName
            })
        },
        /**
         * RabbitMQ  设备信息消息监听事件
         * @param msgData
         */
        listenerRabbitMQ(msgData) {
            console.log(msgData)
            if (!msgData.data) return
            if (msgData.messageType == 'device') {
                if (this.$route.name === 'iw') {
                    this.findByTypeMsgData(
                        devApiSourceList,
                        msgData.data,
                        msgData.messageType
                    )
                    let tempList = []
                    devApiSourceList.map(item => {
                        if (item.isDisplay && Number(item.isDisplay) === 0) return
                        item.type = this.markerType(item.deviceType)
                        item.name = item.deviceName
                        item.label = item.deviceName
                        item.lnglat =
                            item.longitude && item.latitude
                                ? [item.longitude, item.latitude]
                                : []
                        if (item.lnglat.length == 0) return
                        tempList.push(item)
                    })
                    list = tempList
                } else if (this.$route.name === 'std') {
                    this.findByTypeMsgDataSTD(
                        devApiSourceListSTD,
                        msgData.data,
                        msgData.messageType
                    )
                    list = devApiSourceListSTD
                }
            } else if (msgData.messageType == 'alarm') {
                console.log()
            }
        },
        /**
         * RabbitMQ  用户上线消息监听事件
         * @param msgData
         */
        listenerUserRabbitMQ(msgData) {
            console.log(msgData)
        },

        findByTypeMsgData(list, obj, type) {
            list.map((item, i) => {
                if (type == 'device' && obj.deviceId == item.deviceId) {
                    devApiSourceList[i] = obj
                } else if (type == 'alarm') {
                    console.log()
                }
            })
        },
        findByTypeMsgDataSTD(list, obj, type) {
            list.map((item, i) => {
                if (type == 'device' && obj.deviceId == item.allData.deviceId) {
                    if (obj.isDisplay && Number(obj.isDisplay) === 0) return
                    devApiSourceListSTD[i].deviceType = obj.deviceType
                    devApiSourceListSTD[i].lnglat = [
                        Number(obj.longitude),
                        Number(obj.latitude)
                    ]
                    devApiSourceListSTD[i].status = obj.deviceStatus
                    devApiSourceListSTD[i].cameraUrl = obj.cameraUrl
                    devApiSourceListSTD[i].alarm = obj.alarm
                    devApiSourceListSTD[i].deviceName = obj.deviceName
                    devApiSourceListSTD[i].deviceLocation = obj.deviceLocation
                    devApiSourceListSTD[i].allData = obj
                } else if (type == 'alarm') {
                    console.log()
                }
            })
        },
        async fetchCategoryData(code, subCode, page, size) {
            const { resultCode, resultData } = await fetchData(
                code,
                subCode,
                page,
                size
            )
            if (resultCode === '200' && resultData) {
                return resultData
            }
        },
        async fetchCategoryDataovR1(code) {
            const { resultCode, resultData } = await _axios.post(
                '/cmsv2/content/page',
                {
                    needDraft: true,
                    page: 0,
                    size: 20,
                    typeCode: code
                }
            )
            if (resultCode === '200' && resultData) {
                // console.log(resultCode,resultData,'00000000000000000000000')
                return resultData
            }
        },

        async fetchDevMarkerData() {
            let params = {
                source: currType == 'iw' ? '1' : currType == 'cs' ? '1' : ''
            }
            const { resultCode, resultData } = await map_dev_marker(params)
            if (resultCode === '200' && resultData) {
                return resultData
            }
        },

        formatItemForData(data) {
            switch (currType) {
                case 'br':
                    {
                        let tempList = []
                        data.map(obj => {
                            if (obj.title == '艺术文化') return
                            obj.type = this.markerType(obj.sub_type_code)
                            obj.label = obj.title
                            obj.lnglat = obj.location_coordinates
                            if (obj.zonghe && 'zonghe' in obj) {
                                if (obj.type == 'wh') {
                                    obj.location.map(item2 => {
                                        item2.lnglat = [item2.lng, item2.lat]
                                        item2.type = obj.type
                                        item2.label = item2.title
                                        const marker = this.createMarker(item2)
                                        markerList.push(marker)
                                        const label = this.createLabel(item2)
                                        labelList.push(label)
                                    })
                                } else {
                                    const marker = this.createMarker(obj)
                                    markerList.push(marker)
                                    const label = this.createLabel(obj)
                                    labelList.push(label)
                                }
                            }
                            tempList.push(obj)
                        })
                        list = tempList
                        map && map.add(markerList)
                        map && map.add(labelList)
                    }
                    break
                case 'iw':
                    {
                        data.sort(compare('installStatus', true))
                        data.map(item => {
                            if (item.isDisplay && Number(item.isDisplay) === 0) return
                            item.type = this.markerType(item.deviceType)
                            item.name = item.deviceName
                            item.label = item.deviceName
                            item.lnglat =
                                item.longitude && item.latitude
                                    ? [item.longitude, item.latitude]
                                    : []
                            if (item.lnglat.length == 0) return
                            list.push(item)

                            // if (item.type == 'fwss') {
                            //   let params = {
                            //     lnglat: item.lnglat,
                            //     type: item.type,
                            //     label: item.deviceName,
                            //     data: item
                            //   }
                            //   const marker = this.createMarker(params)
                            //   markerList.push(marker)
                            //   const label = this.createLabel(params)
                            //   labelList.push(label)
                            // } else {
                            let params = {
                                width: 50,
                                height: 162,
                                name: item.deviceName,
                                img: `/images/map/cs/${item.type}-n.png`,
                                lnglat: item.lnglat,
                                data: item,
                                type: item.type,
                                label: item.deviceName
                            }
                            //        if(item.deviceStatus =='0'){
                            //     params.img =`/images/map/cs/${item.type}-w.png`
                            // }
                            // 适中 sz，空闲 kx ，紧张 jz
                            if (item.type == 'tcc') {
                                if (item.viewType) {
                                    // "2"：空闲，"3"：适中，"4"：紧张
                                    if (item.viewType == '2') {
                                        params.img = `/images/map/cs/tcc-k.png`
                                    } else if (item.viewType == '3') {
                                        params.img = `/images/map/cs/tcc-s.png`
                                    } else if (item.viewType == '4') {
                                        params.img = `/images/map/cs/tcc-j.png`
                                    }
                                }
                                // else{
                                //   console.log(item,'非智能')
                                // }
                            } else if (item.type == 'gc') {
                                if (item.installStatus && item.installStatus == '1') {
                                    params.img = `/images/map/cs/gc-z.png`
                                }
                            } else if (item.type === 'ljt') {
                                if (item.installStatus && item.installStatus == '1') {
                                    params.img = `/images/map/cs/ljt-z.png`
                                }
                            } else if (item.type === 'zndg') {
                                if (item.deviceSwitch !== '1') {
                                    params.img = `/images/map/cs/zndg-c.png`
                                }
                            } else if (item.type === 'afjk' || item.type === 'kljk') {
                                params.img = `/images/map/cs/${item.type}-${item.isBridge == '1' ? 's' : 'n'
                                    }.png`
                                if (item.deviceStatus == '0') {
                                    params.img = `/images/map/cs/${item.type}-w.png`
                                }
                            } else if (item.type === 'yyjk') {
                                if (item.deviceStatus == '0') {
                                    params.img = `/images/map/cs/${item.type}-w.png`
                                }
                            } else if (item.type === 'db') {
                                if (item.deviceStatus == '0') {
                                    params.img = `/images/map/cs/${item.type}-w.png`
                                }
                                let gjc = wgs84togcj02(
                                    Number(item.longitude),
                                    Number(item.latitude)
                                )
                                params.lnglat = gjc
                            }
                            const marker = this.createIconMarker(params)
                            markerList.push(marker)
                        })
                        map.setZoomAndCenter(mapConfig.style.zoom, mapConfig.style.center)
                        map.setPitch(mapConfig.style.pitch)
                        map.setRotation(mapConfig.style.rotation)
                        map && map.add(markerList)
                        map && map.add(labelList)
                    }
                    break
                case 'cs':
                    {
                        data.map(item => {
                            item.type = this.markerType(item.deviceType)
                            item.name = item.deviceName
                            item.lnglat =
                                item.longitude && item.latitude
                                    ? [item.longitude, item.latitude]
                                    : []
                            if (item.lnglat.length == 0) return
                            list.push(item)
                            let params = {
                                width: 50,
                                height: 162,
                                name: item.deviceName,
                                img: `/images/map/cs/${item.type}-n.png`,
                                lnglat: item.lnglat,
                                data: item
                            }
                            if (item.type == 'tcc') {
                                if (item.viewType) {
                                    // "2"：空闲，"3"：适中，"4"：紧张
                                    if (item.viewType == '2') {
                                        params.img = `/images/map/cs/tcc-k.png`
                                    } else if (item.viewType == '3') {
                                        params.img = `/images/map/cs/tcc-s.png`
                                    } else if (item.viewType == '4') {
                                        params.img = `/images/map/cs/tcc-j.png`
                                    }
                                } else {
                                    console.log(item, '非智能')
                                }
                            } else if (item.type == 'gc') {
                                if (item.installStatus && item.installStatus == '1') {
                                    params.img = `/images/map/cs/gc-z.png`
                                }
                            } else if (item.type === 'ljt') {
                                if (item.installStatus && item.installStatus == '1') {
                                    params.img = `/images/map/cs/ljt-z.png`
                                }
                            }
                            const marker = this.createIconMarker(params)
                            markerList.push(marker)
                        })
                        map.setZoomAndCenter(mapConfig.style.zoom, mapConfig.style.center)
                        map.setPitch(mapConfig.style.pitch)
                        map.setRotation(mapConfig.style.rotation)
                        map && map.add(markerList)
                    }
                    return
            }
        },

        /* 台东概况-基础资源 */
        fetchOvBrData() {
            this.fetchCategoryData('td_culture', '', 0, 99).then(res => {
                this.formatItemForData(res.content)
            })
            map.setZoomAndCenter(mapConfig.style.zoom, mapConfig.style.center)
            map.setPitch(mapConfig.style.pitch)
            map.setRotation(mapConfig.style.rotation)
        },
        /*台东业态分布右侧*/
        async fetchOvbfpR1Data() {
            let that = this
            await this.fetchCategoryDataovR1('industrial').then(res => {
                console.log(res, 'rrrrrrrrrrrrrrrrrrr')
                that.ovbfpr1List = res.content
            })
        },
        /* 台东概况-基础资源-图例 */
        filterOvBrData(item) {
            this.clearMap()
            this.addOvBrOverlays()
            list.map(obj => {
                if (item.type == obj.type && !('model' in item)) {
                    if (obj.type == 'wh') {
                        obj.location.map(item2 => {
                            item2.lnglat = [item2.lng, item2.lat]
                            item2.type = obj.type
                            item2.label = item2.title
                            const marker = this.createMarker(item2)
                            markerList.push(marker)
                            const label = this.createLabel(item2)
                            labelList.push(label)
                        })
                    } else {
                        const marker = this.createMarker(obj)
                        markerList.push(marker)
                        const label = this.createLabel(obj)
                        labelList.push(label)
                    }
                } else if (
                    'model' in item &&
                    item.model == 'ov-br-l2' &&
                    item.label == obj.label
                ) {
                    obj.location.map(item2 => {
                        item2.lnglat = [item2.lng, item2.lat]
                        item2.type = obj.type
                        item2.label = item2.title
                        const marker = this.createMarker(item2)
                        markerList.push(marker)
                        const label = this.createLabel(item2)
                        labelList.push(label)
                    })
                } else if (item.type == 'all') {
                    if (obj.zonghe && 'zonghe' in obj) {
                        if (obj.type == 'wh') {
                            obj.location.map(item2 => {
                                item2.lnglat = [item2.lng, item2.lat]
                                item2.type = obj.type
                                item2.label = item2.title
                                const marker = this.createMarker(item2)
                                markerList.push(marker)
                                const label = this.createLabel(item2)
                                labelList.push(label)
                            })
                        } else {
                            const marker = this.createMarker(obj)
                            markerList.push(marker)
                            const label = this.createLabel(obj)
                            labelList.push(label)
                        }
                    }

                    map.setZoomAndCenter(mapConfig.style.zoom, mapConfig.style.center)
                    map.setPitch(mapConfig.style.pitch)
                    map.setRotation(mapConfig.style.rotation)
                }
            })
            map.add(markerList)
            map.add(labelList)
        },

        /* 台东概况-业态规划 */
        fetchOvBfpData(obj) {
            if (!lyxf) {
                lyxf = new AMap.ImageLayer({
                    url: `/images/map/ov/bfp/lyxfldz.png`,
                    bounds: new AMap.Bounds(
                        [120.32874354201088, 36.069376174598766],
                        [120.36592345849027, 36.10301258044016]
                    ),
                    zooms: [10, 20]
                })
                lyxf.hide()
                map.add(lyxf)
            }
            if (!tscy) {
                tscy = new AMap.ImageLayer({
                    url: `/images/map/ov/bfp/tscytyz.png`,
                    bounds: new AMap.Bounds(
                        [120.32874354201088, 36.069376174598766],
                        [120.36592345849027, 36.10301258044016]
                    ),
                    zooms: [10, 20]
                })
                tscy.hide()
                map.add(tscy)
            }
            if (obj == 'sxlz') {
                const tempImageLayer = new AMap.ImageLayer({
                    url: `/images/map/ov/bfp/map-bg.png`,
                    bounds: new AMap.Bounds(
                        [120.32874354201088, 36.069376174598766],
                        [120.36592345849027, 36.10301258044016]
                    ),
                    zooms: [10, 20]
                })
                imageLayer = tempImageLayer
                map.add(imageLayer)
            } else if (
                obj == 'sx' ||
                obj == 'hlzx' ||
                obj == 'tdzx' ||
                obj == 'slzx'
            ) {
                let tempMarkerList = [
                    {
                        label: '活力之心',
                        type: 'hlzx',
                        lnglat: [120.35672, 36.08201],
                        count: 100
                    },
                    {
                        label: '台东之心',
                        type: 'tdzx',
                        lnglat: [120.354092, 36.083699],
                        count: 100
                    },
                    {
                        label: '商旅之心',
                        type: 'slzx',
                        lnglat: [120.3503, 36.081448],
                        count: 400
                    }
                ]

                let height = 2,
                    count = 0
                tempMarkerList.map((item, i) => {
                    if (count !== item.count * 1) {
                        height += 10 // 根据数量设置波纹高度
                        count = item.count * 1
                    }
                    let el = `<div
            class="circle-marker-content"
            >
              <div class="item item1"
              style='
              height:${height}vh;
              width:${height}vh;
              '></div>
              <div class="item item2" style='
              height:${height}vh;
              width:${height}vh;
              '></div>
              <div class="item item3" style='
              height:${height}vh;
              width:${height}vh;
              '></div>
              <div class="item item4" style='
              height:${height}vh;
              width:${height}vh;
              '></div>
              <div class="item item5" style='
              height:${height}vh;
              width:${height}vh;
              '></div>
            </div>`
                    let width = item.label.length * 50 * 0.4
                    const labEl = `<div id="ov-dh-heatLabel" style="font-weight: bold;font-size: 18px;color:#FFD200;width:${width}px">${item.label}</div>`
                    const label = new AMap.Marker({
                        content: labEl,
                        position: item.lnglat,
                        offset: new AMap.Pixel(0, 10),
                        anchor: 'bottom-center',
                        zIndex: i + 10
                    })
                    if (obj == item.type) {
                        const marker = new AMap.Marker({
                            content: el,
                            position: item.lnglat,
                            offset: new AMap.Pixel(0, 0),
                            anchor: 'bottom-center',
                            zIndex: i + 1
                        })
                        markerList.push(marker)
                        labelList.push(label)
                    } else if (obj == 'sx') {
                        const marker = new AMap.Marker({
                            content: el,
                            position: item.lnglat,
                            offset: new AMap.Pixel(0, 0),
                            anchor: 'bottom-center',
                            zIndex: i + 1
                        })
                        markerList.push(marker)
                        labelList.push(label)
                    }
                })
                map.add(labelList)
                map.add(markerList)
            } else if (obj == 'lz' || obj == 'tyz' || obj == 'ldz') {
                let p1 = {
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            properties: {
                                type: 1
                            },
                            geometry: {
                                type: 'LineString',
                                coordinates: [
                                    [120.343967, 36.080918],
                                    [120.345478, 36.081048],
                                    [120.347146, 36.081282],
                                    [120.348224, 36.081415],
                                    [120.349252, 36.081463],
                                    [120.350564, 36.081423],
                                    [120.352004, 36.081242],
                                    [120.354152, 36.083598],
                                    [120.360392, 36.079332]
                                ]
                            }
                        }
                    ]
                }
                let p2 = {
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            properties: {
                                type: 2
                            },
                            geometry: {
                                type: 'LineString',
                                coordinates: [
                                    [120.344628, 36.078357],
                                    [120.347927, 36.078921],
                                    [120.349095, 36.079165],
                                    [120.349618, 36.079341],
                                    [120.350137, 36.079617],
                                    [120.350729, 36.080002],
                                    [120.351364, 36.080524],
                                    [120.351963, 36.081232],
                                    [120.354066, 36.083724],
                                    [120.352535, 36.084555],
                                    [120.354574, 36.086928]
                                ]
                            }
                        }
                    ]
                }
                if (obj == 'lz') {
                    this.drawDynamicLine(p1, {
                        headColor: '#769BFF',
                        trailColor: '#295FFF',
                        type: 'lyxfldz'
                    })
                    this.drawDynamicLine(p2, {
                        headColor: '#BCFFFF',
                        trailColor: '#00F9AA',
                        type: 'tscytyz'
                    })
                } else if (obj == 'tyz') {
                    this.drawDynamicLine(p2, {
                        headColor: '#BCFFFF',
                        trailColor: '#00F9AA',
                        type: 'tscytyz'
                    })
                } else {
                    this.drawDynamicLine(p1, {
                        headColor: '#769BFF',
                        trailColor: '#295FFF',
                        type: 'lyxfldz'
                    })
                }
            } else if (
                obj == 'djd' ||
                obj == 'xxgy' ||
                obj == 'fhkt' ||
                obj == 'tskt' ||
                obj == 'xxgc'
            ) {
                let tempList = [
                    {
                        type: 'xxgy',
                        list: [
                            {
                                label: '悦动狂欢',
                                lnglat: [120.350303, 36.08048]
                            },
                            {
                                label: '居民广场',
                                lnglat: [120.352145, 36.082682]
                            }
                        ],
                        color: '#22FFA4'
                    },
                    {
                        type: 'fhkt',
                        list: [
                            {
                                label: '大悦客厅1',
                                lnglat: [120.344248, 36.082277]
                            },
                            {
                                label: '大悦客厅2',
                                lnglat: [120.34704, 36.0815]
                            },
                            {
                                label: '十字广场',
                                lnglat: [120.354792, 36.083231]
                            },
                            {
                                label: '台东东大门',
                                lnglat: [120.360117, 36.079868]
                            }
                        ],
                        color: '#CB00FF'
                    },
                    {
                        type: 'tskt',
                        list: [
                            {
                                label: '啤酒花园',
                                lnglat: [120.346515, 36.079286]
                            },
                            {
                                label: '台东时光',
                                lnglat: [120.349868, 36.083366]
                            },
                            {
                                label: 'THINK PARK',
                                lnglat: [120.355927, 36.08266]
                            },
                            {
                                label: '你好台东',
                                lnglat: [120.355759, 36.081754]
                            },
                            {
                                label: 'show time',
                                lnglat: [120.356739, 36.081523]
                            },
                            {
                                label: '动感地带',
                                lnglat: [120.357602, 36.081057]
                            },
                            {
                                label: '记忆重现',
                                lnglat: [120.358786, 36.081438]
                            },
                            {
                                label: '小憩时光',
                                lnglat: [120.358659, 36.080333]
                            }
                        ],
                        color: '#00A7FF'
                    },
                    {
                        type: 'xxgc',
                        list: [
                            {
                                label: '世界啤酒舞台',
                                lnglat: [120.345258, 36.078279]
                            },
                            {
                                label: '欢聚庭院',
                                lnglat: [120.348027, 36.078853]
                            },
                            {
                                label: '庭院深深',
                                lnglat: [120.352441, 36.084703]
                            },
                            {
                                label: '口袋公园1',
                                lnglat: [120.35343, 36.084709]
                            },
                            {
                                label: '口袋公园2',
                                lnglat: [120.358834, 36.083031]
                            }
                        ],
                        color: '#FF845F'
                    }
                ]

                tempList.map(item => {
                    item.list.map(item2 => {
                        let params = {
                            width: 63,
                            height: 181,
                            name: item.label,
                            img: `/images/map/ov/bfp/${item.type}.png`,
                            lnglat: item2.lnglat,
                            data: item
                        }

                        let width = item2.label.length * 50 * 0.8
                        let offset = new AMap.Pixel(30, -140)
                        const el = `<div class="ov-bfp-label" style="width:${width}px;>
                          <span class="t">${item2.label}</span>
                          <span class="type" style="color:${item.color
                            }">${this.markerType(item.type)}</span>
                        </div>`
                        const label = new AMap.Marker({
                            content: el,
                            position: item2.lnglat,
                            offset: offset,
                            anchor: 'bottom-left'
                        })
                        if (obj == 'djd') {
                            const marker = this.createIconMarker(params)
                            markerList.push(marker)
                            labelList.push(label)
                        } else if (obj == item.type) {
                            const marker = this.createIconMarker(params)
                            markerList.push(marker)
                            labelList.push(label)
                        }
                    })
                })
                // var polygon = new AMap.Polygon({
                //   path: polygonPath
                // })
                map.add(markerList)
                map.add(labelList)
            } else if (
                obj == 'dzt' ||
                obj == 'qwsq' ||
                obj == 'hlsyq' ||
                obj == 'cxysq' ||
                obj == 'sssyq' ||
                obj == 'slhxq' ||
                obj == 'ljsnq' ||
                obj == 'ydxxq'
            ) {
                let tempList = [
                    {
                        type: 'qwsq',
                        label: '趣玩社区',
                        tags: '市井啤酒/社区商业/零售/餐饮/儿童/康体娱乐/综合便民',
                        path: [
                            [120.350509, 36.083788],
                            [120.353621, 36.087556],
                            [120.35598, 36.086019],
                            [120.353279, 36.08284],
                            [120.351525, 36.084023],
                            [120.351177, 36.083715]
                        ],
                        center: [120.353093, 36.085555],
                        angle: -8,
                        color: '#0054FF'
                    },
                    {
                        type: 'hlsyq',
                        label: '活力商业区',
                        tags:
                            '购物中心/文化体验/年代体验/服装服饰/轻餐饮/儿童业态/文化创意/亲自及家庭服务/外贸商品',
                        path: [
                            [120.353305, 36.08282],
                            [120.35601, 36.085997],
                            [120.358733, 36.084181],
                            [120.355818, 36.081149]
                        ],
                        center: [120.355868, 36.083837],
                        angle: -8,
                        color: '#00C921'
                    },
                    {
                        type: 'cxysq',
                        label: '创想艺术区',
                        tags: '青岛特色/青潮主题/动漫风格',
                        path: [
                            [120.355855, 36.081118],
                            [120.358774, 36.084153],
                            [120.360152, 36.083216],
                            [120.360422, 36.0778]
                        ],
                        center: [120.358321, 36.081951],
                        angle: -8,
                        color: '#8100DC'
                    },
                    {
                        type: 'sssyq',
                        label: '国际时尚商业区',
                        tags: '高端化/零售/餐饮/休闲娱乐/文创/儿童/国际化啤酒主题特色/住宿',
                        path: [
                            [120.344624, 36.082525],
                            [120.34488, 36.080973],
                            [120.347418, 36.081359],
                            [120.348031, 36.081431],
                            [120.348667, 36.081495],
                            [120.349482, 36.081502],
                            [120.350435, 36.081441],
                            [120.351246, 36.081385],
                            [120.351954, 36.081258],
                            [120.352557, 36.081924],
                            [120.351007, 36.083245],
                            [120.34773, 36.082734],
                            [120.346821, 36.08261],
                            [120.345893, 36.082503]
                        ],
                        center: [120.348045, 36.082447],
                        angle: 20,
                        color: '#02D693'
                    },
                    {
                        type: 'slhxq',
                        label: '青啤文商旅核心区',
                        tags:
                            '世界知名啤酒的生产/研发/营销/休闲体验/年代体验/美食体验/文创/科创',
                        path: [
                            [120.343268, 36.0829],
                            [120.344551, 36.082568],
                            [120.344836, 36.080916],
                            [120.348818, 36.081478],
                            [120.350152, 36.081417],
                            [120.351493, 36.081294],
                            [120.351999, 36.081197],
                            [120.351739, 36.080914],
                            [120.351368, 36.080524],
                            [120.350824, 36.080047],
                            [120.350233, 36.07966],
                            [120.34951, 36.079293],
                            [120.348674, 36.079046],
                            [120.347335, 36.078781],
                            [120.344663, 36.078318],
                            [120.344639, 36.078318],
                            [120.344639, 36.078318],
                            [120.34463, 36.078312],
                            [120.344104, 36.080584]
                        ],
                        center: [120.347212, 36.080549],
                        angle: 20,
                        color: '#E09310'
                    },
                    {
                        type: 'ljsnq',
                        label: '链接枢纽区',
                        tags: '市井啤酒/社区商业/零售/餐饮/儿童/康体娱乐/综合便民',
                        path: [
                            [120.350742, 36.08326],
                            [120.350563, 36.083797],
                            [120.351199, 36.083692],
                            [120.351514, 36.083988],
                            [120.356677, 36.080489],
                            [120.355453, 36.078973],
                            [120.352004, 36.081262],
                            [120.352629, 36.081943],
                            [120.351038, 36.083294]
                        ],
                        center: [120.354703, 36.081173],
                        angle: 76,
                        color: '#FF0096'
                    },
                    {
                        type: 'ydxxq',
                        label: '活力运动休闲区',
                        tags: '',
                        path: [
                            [120.343286, 36.082824],
                            [120.341512, 36.083158],
                            [120.341204, 36.080886],
                            [120.341646, 36.080084],
                            [120.342021, 36.079445],
                            [120.341726, 36.079062],
                            [120.341985, 36.077703],
                            [120.344619, 36.078356],
                            [120.34404, 36.080796]
                        ],
                        center: [120.343111, 36.080186],
                        angle: 40,
                        color: '#00DEFF'
                    }
                ]

                tempList.map(item => {
                    const polygon = new AMap.Polygon({
                        path: item.path,
                        strokeColor: item.color,
                        strokeOpacity: 0.7,
                        fillColor: item.color,
                        fillOpacity: 0.7
                    })
                    polygon.on('click', ({ lnglat }) => {
                        console.log(lnglat.lng + ',' + lnglat.lat)
                    })
                    let width = item.label.length * 50 * 0.7
                    let tagOffsetY = 60
                    if (item.type == 'hlsyq') {
                        tagOffsetY = 80
                    } else if (item.type == 'cxysq') {
                        tagOffsetY = 40
                    } else if (item.type == 'sssyq') {
                        tagOffsetY = 80
                    } else if (item.type == 'slhxq') {
                        tagOffsetY = 80
                    }
                    const el = `<div id="ov-dh-heatLabel" style="font-weight: bold;font-size:30px;color:#fff;width:${item.type == 'ydxxq' ? 0 : width
                        }px">${item.label}</div>`
                    const label = new AMap.Marker({
                        content: el,
                        angle: item.angle,
                        offset: new AMap.Pixel(0, item.type == 'ydxxq' ? 120 : 10),
                        position: item.center,
                        anchor: 'bottom-center'
                    })

                    const tagEl = `<div class="ov-dh-tags" style="font-size:20px;color:#fff;width:${item.type == 'ydxxq' ? 0 : 300
                        }px">${item.tags}</div>`
                    const tagLabel = new AMap.Marker({
                        content: tagEl,
                        angle: item.angle,
                        offset: new AMap.Pixel(0, tagOffsetY),
                        position: item.center,
                        anchor: 'bottom-center'
                    })
                    if (obj == 'dzt') {
                        labelList.push(label)
                        labelList.push(tagLabel)
                        lineList.push(polygon)
                    } else if (obj == item.type) {
                        labelList.push(label)
                        labelList.push(tagLabel)
                        lineList.push(polygon)
                    }
                })
                map.add(labelList)
                map.add(lineList)
            } else if (
                obj == 'bwg' ||
                obj == 'pjwh' ||
                obj == 'pjc' ||
                obj == 'dywhhd' ||
                obj == 'fwx' ||
                obj == 'gjj'
            ) {
                // 10：博物馆，20：啤酒文化，30：啤酒厂，40：引入，50：改造，60：提升，70：多元文化活动，80：商业业态，90：旅游目的地
                // console.log(this.ovbfpr1List,'000000000000000000000')
                this.ovbfpr1List.map(item => {
                    console.log(item.sub_type_code, obj, 'ooooooooooooooo')
                    if (item.sub_type_code === '10' && obj === 'bwg') {
                        //博物馆
                        console.log(item)
                        this.$eventBus.$emit('ov-bfp-r1map-qpgj', item.content)
                    } else if (item.sub_type_code === '20' && obj == 'pjwh') {
                        //博物馆
                        this.$eventBus.$emit('ov-bfp-r1map-qpgj', item.content)
                    } else if (item.sub_type_code === '30' && obj == 'pjc') {
                        //博物馆
                        this.$eventBus.$emit('ov-bfp-r1map-qpgj', item.content)
                    } else if (item.sub_type_code === '70' && obj == 'dywhhd') {
                        //博物馆
                        this.$eventBus.$emit('ov-bfp-r1map-qpgj', item.content)
                    } else if (item.sub_type_code === '80' && obj == 'fwx') {
                        //博物馆
                        this.$eventBus.$emit('ov-bfp-r1map-qpgj', item.content)
                    } else if (item.sub_type_code === '90' && obj == 'gjj') {
                        //博物馆
                        this.$eventBus.$emit('ov-bfp-r1map-qpgj', item.content)
                    }
                })
                // console.log(this.ovbfpr1List,'ccccccccccccc')
                // console.log(obj)
                map
            } else if (obj == 'yr' || obj == 'gz' || obj == 'ts') {
                if (bfplistmarker.length > 0) {
                    map && map.remove(bfplistmarker)
                    bfplistmarker = []
                }

                this.ovbfpr1List.map(item => {
                    if (item.sub_type_code === '40' && obj == 'yr') {
                        //博物馆
                        const marker = this.createLabelMarker(item)
                        bfplistmarker.push(marker)
                        map.add(bfplistmarker)
                    } else if (item.sub_type_code === '50' && obj == 'gz') {
                        //博物馆
                        const marker = this.createLabelMarker(item)
                        bfplistmarker.push(marker)
                        map.add(bfplistmarker)
                    } else if (item.sub_type_code === '60' && obj == 'ts') {
                        //博物馆
                        const marker = this.createLabelMarker(item)
                        bfplistmarker.push(marker)
                        map.add(bfplistmarker)
                    }
                })
            }

            if (obj == 'yr') {
                map.setZoomAndCenter(18, [120.35506, 36.083447])
            } else if (obj == 'ts') {
                map.setZoomAndCenter(17.62, [120.351667, 36.082158])
            } else if (obj == 'gz') {
                map.setZoomAndCenter(16.84, [120.354383, 36.082764])
            } else {
                map.setZoomAndCenter(16.84, [120.35283, 36.080867])
            }

            // map.setPitch(28.87237762237762)
            // map.setRotation(0.08819195922400924)
        },
        /* 台东概况-发展历程-热力图 */
        fetchOvDhData(data) {
            data.markers &&
                data.markers.forEach((item, i) => {
                    if (item.lnglat.length == 0) return
                    const icon = new AMap.Icon({
                        image: item.icon.url,
                        size: new AMap.Size(item.icon.width, item.icon.height),
                        imageSize: new AMap.Size(item.icon.width, item.icon.height)
                    })
                    const marker = new AMap.Marker({
                        icon,
                        position: item.lnglat,
                        offset: new AMap.Pixel(0, 0),
                        anchor: 'bottom-center',
                        zIndex: i + 10
                    })
                    markerList.push(marker)

                    const label = this.createDHLabelMarker(item, i)
                    labelList.push(label)
                })

            map.add(markerList)
            map.add(labelList)

            let heats = []
            data.heats.forEach(heat => {
                heats.push({
                    lng: heat.lnglat[0],
                    lat: heat.lnglat[1],
                    count: heat.count //parseInt(Math.random() * 500)
                })
            })
            if (!ovdhHeatmap) {
                map.plugin(['AMap.HeatMap'], () => {
                    ovdhHeatmap = new AMap.HeatMap(map, {
                        radius: 60, //给定半径
                        opacity: [0, 0.8],
                        gradient: {
                            0.5: 'red',
                            0.65: 'red',
                            0.7: 'red',
                            0.9: 'red',
                            1.0: 'red'
                        },
                        '3d': {
                            // //热度转高度的曲线控制参数，可以利用左侧的控制面板获取
                            heightBezier: [0.4, 0.2, 0.4, 0.8],
                            // //取样精度，值越小，曲面效果越精细，但同时性能消耗越大
                            gridSize: 1,
                            heightScale: 0
                        }
                    })
                    //设置数据集
                    ovdhHeatmap.setDataSet({
                        data: heats,
                        max: 1500
                    })
                    ovdhHeatmap.show()
                })
            }
        },
        drawDynamicLine(url, options) {
            var geo = new window.Loca.GeoJSONSource({
                data: url
            })
            const pathLayer = new window.Loca.PulseLineLayer({
                // loca,
                zIndex: 10,
                opacity: 1,
                visible: true,
                zooms: [2, 22]
            })

            // var headColors = ['#FBF9E3']

            pathLayer.setSource(geo)
            pathLayer.setStyle({
                altitude: 0,
                lineWidth: 8,
                // 脉冲头颜色
                headColor: options.headColor,
                // 脉冲尾颜色
                trailColor: options.trailColor, //'#CA9D71',
                // 脉冲长度，0.25 表示一段脉冲占整条路的 1/4
                interval: 0.25,
                // 脉冲线的速度，几秒钟跑完整段路
                duration: 1500
            })
            loca.add(pathLayer)
            loca.animate.start()

            pathLayerList.push(pathLayer)
            if (options.type == 'tscytyz') {
                if (tscy) {
                    tscy.show()
                }
            } else {
                if (lyxf) lyxf.show()
            }

            //   tempImageLayers.push(

            map.add(tempImageLayers)
        },

        /* 智慧管理 */
        fetchIwData(item) {
            if (item) {
                let params = {}
                if (item.type === 'afjk' || item.type === 'kljk') {
                    list.sort(compare('isBridge', true))
                } else {
                    list.sort(compare('installStatus', true))
                }

                list.forEach(obj => {
                    if (item.type == obj.type) {
                        // if (obj.type == 'fwss') {
                        //   let params = {
                        //     lnglat: obj.lnglat,
                        //     type: item.type,
                        //     label: obj.label,
                        //     data:obj
                        //   }
                        //   const marker = this.createMarker(params)
                        //   markerList.push(marker)
                        //   const label = this.createLabel(params)
                        //   labelList.push(label)
                        // } else {
                        params = {
                            width: 50,
                            height: 162,
                            name: obj.name,
                            img: `/images/map/cs/${obj.type}-n.png`,
                            lnglat: obj.lnglat,
                            data: obj,
                            type: item.type,
                            label: item.deviceName
                        }

                        if (obj.type == 'tcc') {
                            if (obj.viewType) {
                                // "2"：空闲，"3"：适中，"4"：紧张
                                if (obj.viewType == '2') {
                                    params.img = `/images/map/cs/tcc-k.png`
                                } else if (obj.viewType == '3') {
                                    params.img = `/images/map/cs/tcc-s.png`
                                } else if (obj.viewType == '4') {
                                    params.img = `/images/map/cs/tcc-j.png`
                                }
                            }
                        } else if (obj.type == 'gc') {
                            if (obj.installStatus && obj.installStatus == '1') {
                                params.img = `/images/map/cs/gc-z.png`
                            }
                        } else if (obj.type === 'ljt') {
                            if (obj.installStatus && obj.installStatus == '1') {
                                params.img = `/images/map/cs/ljt-z.png`
                            }
                        } else if (obj.type === 'zndg') {
                            // console.log(obj,obj.deviceSwitch,'obj 测试！！！')
                            if (obj.deviceSwitch !== '1') {
                                params.img = `/images/map/cs/zndg-c.png`
                            }
                        } else if (obj.type === 'afjk' || obj.type === 'kljk') {
                            params.img = `/images/map/cs/${obj.type}-${obj.isBridge == '1' ? 's' : 'n'
                                }.png`
                            if (obj.deviceStatus == '0') {
                                params.img = `/images/map/cs/${obj.type}-w.png`
                            }
                        } else if (obj.type === 'yyjk') {
                            if (obj.deviceStatus == '0') {
                                params.img = `/images/map/cs/${obj.type}-w.png`
                            }
                        } else if (obj.type === 'db') {
                            if (obj.deviceStatus == '0') {
                                params.img = `/images/map/cs/${obj.type}-w.png`
                            }
                            let gjc = wgs84togcj02(
                                Number(obj.longitude),
                                Number(obj.latitude)
                            )
                            params.lnglat = gjc
                        }
                        const marker = this.createIconMarker(params)
                        markerList.push(marker)
                        // }
                    } else if (item.type == 'dev') {
                        // if (obj.type == 'fwss') {
                        //   let params = {
                        //     lnglat: obj.lnglat,
                        //     type: item.type,
                        //     label: obj.label,
                        //     data:obj
                        //   }
                        //   const marker = this.createMarker(params)
                        //   markerList.push(marker)
                        //   const label = this.createLabel(params)
                        //   labelList.push(label)
                        // } else {
                        params = {
                            width: 50,
                            height: 162,
                            name: obj.name,
                            img: `/images/map/cs/${obj.type}-n.png`,
                            lnglat: obj.lnglat,
                            data: obj,
                            type: item.type,
                            label: item.deviceName
                        }

                        if (obj.type == 'tcc') {
                            if (obj.viewType) {
                                // "2"：空闲，"3"：适中，"4"：紧张
                                if (obj.viewType == '2') {
                                    params.img = `/images/map/cs/tcc-k.png`
                                } else if (obj.viewType == '3') {
                                    params.img = `/images/map/cs/tcc-s.png`
                                } else if (obj.viewType == '4') {
                                    params.img = `/images/map/cs/tcc-j.png`
                                }
                            }
                        } else if (obj.type == 'gc') {
                            if (obj.installStatus && obj.installStatus == '1') {
                                params.img = `/images/map/cs/gc-z.png`
                            }
                        } else if (obj.type === 'ljt') {
                            if (obj.installStatus && obj.installStatus == '1') {
                                params.img = `/images/map/cs/ljt-z.png`
                            }
                        } else if (obj.type === 'zndg') {
                            // console.log(obj,obj.deviceSwitch,'obj 测试！！！')
                            if (obj.deviceSwitch !== '1') {
                                params.img = `/images/map/cs/zndg-c.png`
                            }
                        } else if (obj.type === 'afjk' || obj.type === 'kljk') {
                            params.img = `/images/map/cs/${obj.type}-${obj.isBridge == '1' ? 's' : 'n'
                                }.png`
                            if (obj.deviceStatus == '0') {
                                params.img = `/images/map/cs/${obj.type}-w.png`
                            }
                        } else if (obj.type === 'yyjk') {
                            if (obj.deviceStatus == '0') {
                                params.img = `/images/map/cs/${obj.type}-w.png`
                            }
                        } else if (obj.type === 'db') {
                            if (obj.deviceStatus == '0') {
                                params.img = `/images/map/cs/${obj.type}-w.png`
                            }
                            let gjc = wgs84togcj02(
                                Number(obj.longitude),
                                Number(obj.latitude)
                            )
                            params.lnglat = gjc
                        }
                        const marker = this.createIconMarker(params)
                        markerList.push(marker)
                        // }
                    }
                })
                if (markerList.length == 0) {
                    this.$message('暂无数据')
                }
                map && map.add(markerList)
                map && map.add(labelList)
            } else {
                this.fetchDevMarkerData().then(res => {
                    devApiSourceList = res.sort(compare('installStatus', true))
                    this.formatItemForData(res)
                })
            }
        },
        async fetchIwDataRefesh() {
            const { resultCode, resultData } = await map_dev_marker({
                source: '1',
                deviceTypes: 'lighting'
            })
            // 删除路灯
            for (let i = list.length - 1; i > 0; i--) {
                if (list[i].type == 'zndg') {
                    list.splice(i, 1)
                }
            }

            if (resultCode === '200' && resultData) {
                this.clearMap()

                resultData.map(item => {
                    if (item.isDisplay && Number(item.isDisplay) === 0) return
                    item.type = this.markerType(item.deviceType)
                    if (item.type == 'zndg') {
                        item.name = item.deviceName
                        item.label = item.deviceName
                        item.lnglat =
                            item.longitude && item.latitude
                                ? [item.longitude, item.latitude]
                                : []
                        if (item.lnglat.length == 0) return
                        let params = {
                            width: 50,
                            height: 162,
                            name: item.deviceName,
                            img: `/images/map/cs/${item.type}-n.png`,
                            lnglat: item.lnglat,
                            data: item,
                            type: item.type,
                            label: item.deviceName
                        }

                        if (item.deviceSwitch !== '1') {
                            params.img = `/images/map/cs/zndg-c.png`
                        }
                        list.unshift(item)
                        const marker = this.createIconMarker(params)
                        markerList.push(marker)
                    }
                })
                map.setZoomAndCenter(16.77, [120.352486, 36.082912])
                map.setPitch(mapConfig.style.pitch)
                map.setRotation(mapConfig.style.rotation)
                map && map.add(markerList)
                // map && map.add(labelList)
            }
        },
        /* 综合态势 */
        fetchCsData(item) {
            if (item) {
                if (item.type == 'dev') {
                    roadTrafficLayer.show()
                    this.addCsAreaPolygon()
                    this.addOnlineUserMarker()
                } else if (item.type == 'areaUsers') {
                    this.addCsAreaPolygon()
                } else if (item.type == 'online') {
                    this.addOnlineUserMarker()
                }
            } else {
                roadTrafficLayer.show()
                this.addCsAreaPolygon()
                this.addOnlineUserMarker()
            }
        },

        addOvBrOverlays() {
            let tempLabelList = [
                { label: '顺兴路', center: [120.351609, 36.085789], angle: 80 },
                { label: '台东八路', center: [120.355779, 36.086143], angle: 62 },
                { label: '延安三路', center: [120.360925, 36.080569], angle: 38 },
                { label: '台东一路', center: [120.357993, 36.078945], angle: 80 },
                { label: '万寿路', center: [120.355823, 36.079956], angle: 80 },
                { label: '桑梓路', center: [120.353046, 36.079893], angle: 80 },
                { label: '登州路', center: [120.347707, 36.078207], angle: 22 },
                { label: '泰山路', center: [120.341093, 36.079887], angle: 50 },
                { label: '松山路', center: [120.340715, 36.081888], angle: 67 },
                { label: '辽宁路', center: [120.346401, 36.082933], angle: 20 },
                { label: '滨县路', center: [120.350257, 36.083695], angle: 20 }
            ]
            tempLabelList.map(item => {
                let size = '24px'
                let padding = '5px 20px'
                let fontWeight = '500'
                let width = item.label.length * 40 * 0.6
                let whiteSpace = 'nowrap'
                let offset = new AMap.Pixel(0, 0)
                let zIndex = 1

                if (
                    item.label == '顺兴路' ||
                    item.label == '延安三路' ||
                    item.label == '万寿路' ||
                    item.label == '寿光路' ||
                    item.label == '泰山路' ||
                    item.label == '松山路'
                ) {
                    padding = '5px'
                    width = 27
                    whiteSpace = 'wrap'
                    offset = new AMap.Pixel(0, 30)
                } else {
                    padding = '5px 20px'
                    width = item.label.length * 40 * 0.6
                }
                const el = `<div class="cs-label" style="white-space:${whiteSpace};font-size:${size};font-weight:${fontWeight};padding:${padding};width:${width}px;">${item.label}</div>`
                const label = new AMap.Marker({
                    content: el,
                    angle: item.angle,
                    offset,
                    position: item.center,
                    anchor: 'bottom-center',
                    zIndex
                })
                polygonLabels.push(label)
            })

            let path = [
                [120.345607, 36.082529],
                [120.34524, 36.08252],
                [120.34487, 36.082538],
                [120.344548, 36.082582],
                [120.343238, 36.08294],
                [120.341555, 36.083325],
                [120.341445, 36.083341],
                [120.341212, 36.082083],
                [120.341118, 36.081487],
                [120.341046, 36.08094],
                [120.341035, 36.080842],
                [120.341039, 36.080801],
                [120.341054, 36.080747],
                [120.341132, 36.080643],
                [120.341329, 36.080302],
                [120.341551, 36.079892],
                [120.341739, 36.079506],
                [120.341763, 36.079444],
                [120.341738, 36.079354],
                [120.341675, 36.079246],
                [120.34156, 36.079147],
                [120.341382, 36.079086],
                [120.341397, 36.078951],
                [120.341653, 36.077476],
                [120.342026, 36.077571],
                [120.34306, 36.077839],
                [120.344016, 36.078075],
                [120.344882, 36.078271],
                [120.346605, 36.078615],
                [120.348729, 36.079016],
                [120.349234, 36.079142],
                [120.349674, 36.079315],
                [120.35026, 36.079653],
                [120.350698, 36.079958],
                [120.351128, 36.080296],
                [120.351544, 36.0807],
                [120.351992, 36.081224],
                [120.355456, 36.078942],
                [120.356705, 36.080471],
                [120.360419, 36.077793],
                [120.360124, 36.083268],
                [120.353623, 36.087583],
                [120.350529, 36.083814],
                [120.350692, 36.083233],
                [120.349546, 36.083059],
                [120.348528, 36.082887],
                [120.347352, 36.082693],
                [120.347008, 36.082639],
                [120.34665, 36.0826],
                [120.346152, 36.082546],
                [120.34561, 36.082527]
            ]

            var polyline = new AMap.Polyline({
                path: path,
                strokeColor: '#fff',
                strokeOpacity: 0.6,
                strokeWeight: 6,
                strokeStyle: 'solid',
                strokeDasharray: [10, 5],
                lineJoin: 'round',
                lineCap: 'round'
            })

            polygonList = [polyline]

            // map.add(polygonList)
            map.add(polygonLabels)
            map.add(polygonList)
        },
        addCsAreaPolygon() {
            let tempLabelList = [
                { label: '顺兴路', center: [120.351609, 36.085789], angle: 80 },
                { label: '台东八路', center: [120.355779, 36.086143], angle: 62 },
                { label: '延安三路', center: [120.360925, 36.080569], angle: 38 },
                { label: '台东一路', center: [120.357993, 36.078945], angle: 80 },
                { label: '万寿路', center: [120.355823, 36.079956], angle: 80 },
                { label: '桑梓路', center: [120.353046, 36.079893], angle: 80 },
                { label: '登州路', center: [120.347707, 36.078207], angle: 22 },
                { label: '寿光路', center: [120.342775, 36.080019], angle: 57 },
                { label: '辽宁路', center: [120.346401, 36.082933], angle: 20 }
            ]
            tempLabelList.map(item => {
                // const offsetY = 10
                let size = ''
                let padding = ''
                let fontWeight = ''
                let width = null
                let whiteSpace = 'nowrap'
                let offset = new AMap.Pixel(0, 0)
                let zIndex = 1
                if (
                    item.label == '西段' ||
                    item.label == '中段' ||
                    item.label == '东段'
                ) {
                    size = '30px'
                    padding = '5px 15px'
                    fontWeight = 'bold'
                    width = item.label.length * 50 * 0.65
                } else {
                    size = '24px'
                    fontWeight = '500'

                    if (
                        item.label == '顺兴路' ||
                        item.label == '延安三路' ||
                        item.label == '万寿路' ||
                        item.label == '寿光路'
                    ) {
                        padding = '5px'
                        width = 27
                        whiteSpace = 'wrap'
                        offset = new AMap.Pixel(0, 30)
                    } else {
                        padding = '5px 20px'
                        width = item.label.length * 40 * 0.6
                    }
                }
                const el = `<div class="cs-label" style="white-space:${whiteSpace};font-size:${size};font-weight:${fontWeight};padding:${padding};width:${width}px;">${item.label}</div>`
                const label = new AMap.Marker({
                    content: el,
                    angle: item.angle,
                    offset,
                    position: item.center,
                    anchor: 'bottom-center',
                    zIndex
                })
                polygonLabels.push(label)
            })

            let tempAreaTotalList = [
                {
                    type: '青啤区',
                    businId: '30',
                    center: [120.347112, 36.081131],
                    angle: 22,
                    path: [
                        [120.35191, 36.081203],
                        [120.350597, 36.083212],
                        [120.347441, 36.082674],
                        [120.345562, 36.082489],
                        [120.344336, 36.082577],
                        [120.343251, 36.082886],
                        [120.341585, 36.083317],
                        [120.34152, 36.08291],
                        [120.341341, 36.081925],
                        [120.341237, 36.08083],
                        [120.341577, 36.079744],
                        [120.341722, 36.079049],
                        [120.342038, 36.077628],
                        [120.343798, 36.078113],
                        [120.345635, 36.078522],
                        [120.347459, 36.078849],
                        [120.348877, 36.079158],
                        [120.34942, 36.079337],
                        [120.349894, 36.079565],
                        [120.350332, 36.079864],
                        [120.350961, 36.080292]
                    ]
                },
                {
                    type: '商业区',
                    businId: '20',
                    center: [120.355534, 36.08286],
                    angle: -9,
                    path: [
                        [120.356031, 36.085943],
                        [120.35199, 36.08122],
                        [120.354534, 36.079591],
                        [120.358726, 36.084167]
                    ]
                },
                {
                    type: '生活区',
                    businId: '40',
                    center: [120.353203, 36.084619],
                    angle: -9,
                    path: [
                        [120.353613, 36.087554],
                        [120.350469, 36.083703],
                        [120.350569, 36.083306],
                        [120.35194, 36.081208],
                        [120.356001, 36.085958],
                        [120.35502, 36.086619]
                    ]
                },
                {
                    type: '畅想区',
                    businId: '10',
                    center: [120.358776, 36.081296],
                    angle: -9,
                    path: [
                        [120.355855, 36.081118],
                        [120.358774, 36.084153],
                        [120.360152, 36.083216],
                        [120.360422, 36.0778]
                    ]
                }
            ]
            this.fetchAreaData().then(res => {
                res.map(item => {
                    tempAreaTotalList.map(item2 => {
                        if (item.businId == item2.businId) {
                            this.fetchAreaTotal(item.businId).then(res2 => {
                                item2.total = res2[res2.length - 1].totalTourist
                            })
                        }
                    })
                })
            })
            setTimeout(() => {
                if (tempAreaTotalList[0].total) {
                    tempAreaTotalList.sort(compare('total', false))
                    let colors = ['#FF0096', '#E09310', '#02D693', '#0054FF']
                    tempAreaTotalList.map((item, i) => {
                        console.log(item.type, item.total)
                        let str = `客流数：${item.total}人`
                        let width = str.length * 40 * 0.55
                        const el = `<div class="cs-total-label" style="width:${width}px;">
                      <span class="t">${item.type}</span>
                      <span class="v">客流数：${item.total}人</span>
                    </div>`
                        const label = new AMap.Marker({
                            content: el,
                            angle: item.angle,
                            offset: new AMap.Pixel(0, 40),
                            position: item.center,
                            anchor: 'bottom-center'
                        })
                        polygonLabels.push(label)

                        const polygon = new AMap.Polygon({
                            path: item.path,
                            strokeColor: '#fff',
                            strokeWeight: 6,
                            strokeOpacity: 0.7,
                            fillColor: colors[i],
                            fillOpacity: 0.4,
                            zIndex: i + 1 + 10
                        })
                        polygon.on('click', ({ lnglat }) => {
                            console.log(lnglat.lng + ',' + lnglat.lat)
                            console.log(item)
                        })
                        polygonList.push(polygon)
                    })
                    map.add(polygonList)
                    map.add(polygonLabels)
                }
            }, 1500)
        },

        async fetchAreaData() {
            const { resultCode, resultData } = await fetchFindByType('device_area')
            if (resultCode === '200' && resultData) {
                console.log('cs_area_data', resultData)
                return resultData
            }
        },
        async fetchAreaTotal(fenceId) {
            let params = {
                dateType: 'day',
                fenceId
            }
            const { resultCode, resultData } = await cs_l1(params)
            if (resultCode === '200' && resultData) {
                return resultData
            }
        },

        /* 数字运营 */
        fetchDoData(item) {
            if (item) {
                list.map(obj => {
                    if (item.type == 'online') return
                    let params = {
                        ...{
                            width: 70,
                            height: 100,
                            img: `/images/map/do/${obj.type}.png`
                        },
                        ...obj
                    }
                    if (item.type == obj.type) {
                        const marker = this.createIconMarker(params)
                        markerList.push(marker)
                        const label = this.createLabel(params)
                        labelList.push(label)
                    }
                })
            } else {
                list.map(obj => {
                    if (obj.type == 'online') {
                        // let params = {
                        //   ...{
                        //     width: 70,
                        //     height: 100,
                        //     img: `/images/map/do/${obj.type}.png`
                        //   },
                        //   ...obj
                        // }
                        // const marker = this.createIconMarker(params)
                        // markerList.push(marker)
                        // const label = this.createLabel(params)
                        // labelList.push(label)
                    }
                })
            }

            map.setZoomAndCenter(mapConfig.style.zoom, mapConfig.style.center)
            map.setPitch(mapConfig.style.pitch)
            map.setRotation(mapConfig.style.rotation)
            map && map.add(markerList)
            map && map.add(labelList)
        },
        async getJqdjMapLegend() {
            let par = {
                'PartyJointConstructionUnits[]': {
                    PartyJointConstructionUnit: {
                        '@column': 'id,longitude,latitude',
                        'longitude!{}': [0]
                    },
                    count: 0,
                    query: 2
                }
            }
            let UnitMember = await requestPostBi('', par)
            // let PartyMember = await this.getJqdjpartList()
            let arr = [
                ...UnitMember['PartyJointConstructionUnits[]']
                // ...PartyMember['PartyMember[]']
            ]
            list = arr.map(item => ({
                type: 'ljdw',
                lnglat: [
                    item.PartyJointConstructionUnit.longitude,
                    item.PartyJointConstructionUnit.latitude
                ],
                id: item.PartyJointConstructionUnit.id
            }))
        },
        //获取党员坐标
        async getJqdjpartList() {
            let par = {
                'PartyMember[]': {
                    PartyMember: {
                        '@column': 'user_id,user_name,type_name,address,longitude,latitude'
                    },
                    page: 0,
                    count: 10
                }
            }
            let arr = await requestPostBi('', par)
            return arr
        },
        // 商圈治理-街区党建
        fetchBdgJqdjData() {
            const _this = this
            list.map(item => {
                if (item.type == 'ljdw') {
                    let par = {
                        PartyJointConstructionUnit: {
                            '@column':
                                'id,unit_category,unit_org,unit_name,unit_address,service_item,party_num,contacts,mobile',
                            'id{}': [item.id]
                        }
                    }
                    requestPostBi('', par)
                        .then(res => {
                            if (res.code == 200) {
                                item.contacts = res['PartyJointConstructionUnit'].contacts
                                item.mobile = res['PartyJointConstructionUnit'].mobile
                                item.party_num = res['PartyJointConstructionUnit'].party_num
                                item.unit_address =
                                    res['PartyJointConstructionUnit'].unit_address
                                item.unit_category =
                                    res['PartyJointConstructionUnit'].unit_category
                                item.unit_name = res['PartyJointConstructionUnit'].unit_name
                                item.unit_org = res['PartyJointConstructionUnit'].unit_org
                                const marker = _this.createMarker(item)
                                markerList.push(marker)
                                map.setZoomAndCenter(
                                    mapConfig.style.zoom,
                                    mapConfig.style.center
                                )
                                map.setPitch(mapConfig.style.pitch)
                                map.setRotation(mapConfig.style.rotation)
                                map && map.add(markerList)
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
            })
        },
        // 商圈治理-街区党建-图例
        filterBdgJqdjData(item) {
            // this.clearMap()
            list.map(obj => {
                if (item.type == obj.type) {
                    const marker = this.createMarker(obj)
                    markerList.push(marker)
                }
            })
            map.add(markerList)
        },
        //获取街区治理图例
        async getJqzlMapLegend() {
            let par = {
                'GovernLayers[]': {
                    GovernLayer: {
                        '@column': 'ID,PLACE_NAME,ICON,LONGITUDE,LATITUDE',
                        'LONGITUDE!{}': [0]
                    },
                    count: 0,
                    query: 2
                }
            }
            let arr = await requestPostBi('', par)

            // if (!arr['PlaceResource[]']) {
            //     clearTimeout(timeout3)
            //     timeout3 = null
            //     this.$eventBus.$emit('bdg-jqzl-legend', {
            //         name: '全部',
            //         type: 'all'
            //     })
            //     return
            // }
            list = arr['GovernLayers[]'].map((item, i) => ({
                type:
                    i == 0
                        ? 'emergency_response'
                        : i == 1
                            ? 'enterprise_inspect'
                            : i == 2
                                ? 'city_management'
                                : 'public_service',
                id: item.GovernLayer.ID,
                lnglat: [item.GovernLayer.LONGITUDE, item.GovernLayer.LATITUDE]
            }))
        },
        // 商圈治理-街区治理
        fetchBdgJqzlData() {
            list.map(item => {
                const marker = this.createMarker(item)
                markerList.push(marker)
            })
            map.setZoomAndCenter(mapConfig.style.zoom, [120.3552, 36.078908])
            map.setPitch(mapConfig.style.pitch)
            map.setRotation(mapConfig.style.rotation)
            map && map.add(markerList)
        },
        // 商圈治理-街区治理-图例
        filterBdgJqzlData(item) {
            list.map(obj => {
                if (item.type == obj.type) {
                    const marker = this.createMarker(obj)
                    markerList.push(marker)
                } else if (item.type == 'all') {
                    const marker = this.createMarker(obj)
                    markerList.push(marker)
                    const label = this.createLabel(obj)
                    labelList.push(label)

                    map.setZoomAndCenter(mapConfig.style.zoom, mapConfig.style.center)
                    map.setPitch(mapConfig.style.pitch)
                    map.setRotation(mapConfig.style.rotation)
                }
            })
            map.add(markerList)
        },
        //获取街区服务图例
        async getJqfwMapLegend() {
            let par = {
                'ServiceMerchants[]': {
                    ServiceMerchant: {
                        '@column': 'id,star,longitude,latitude',
                        'star{}': ['一星', '二星', '三星', '四星', '五星']
                    },
                    count: 0,
                    query: 2
                }
            }
            let arr = await requestPostBi('', par)
            list = arr['ServiceMerchants[]'].map(item => ({
                type:
                    item.ServiceMerchant.star == '五星'
                        ? 'wuxing'
                        : item.ServiceMerchant.star == '四星'
                            ? 'sixing'
                            : item.ServiceMerchant.star == '三星'
                                ? 'sanxing'
                                : item.ServiceMerchant.star == '二星'
                                    ? 'erxing'
                                    : 'yixing',
                lnglat: [
                    item.ServiceMerchant.longitude || '',
                    item.ServiceMerchant.latitude || ''
                ],
                id: item.ServiceMerchant.id
            }))
        },
        // 商圈治理-街区服务
        fetchBdgJqfwData() {
            list.map(item => {
                const marker = this.createMarker(item)
                markerList.push(marker)
            })
            map.setZoomAndCenter(16.74, [120.351507, 36.083935])
            map.setPitch(mapConfig.style.pitch)
            map.setRotation(mapConfig.style.rotation)
            map && map.add(markerList)
        },
        // 商圈治理-街区服务-图例
        filterBdgJqfwData(item) {
            list.map(obj => {
                if (item.type == obj.type) {
                    const marker = this.createMarker(obj)
                    markerList.push(marker)
                } else if (item.type == 'all') {
                    const marker = this.createMarker(obj)
                    markerList.push(marker)
                    const label = this.createLabel(obj)
                    labelList.push(label)

                    map.setZoomAndCenter(mapConfig.style.zoom, mapConfig.style.center)
                    map.setPitch(mapConfig.style.pitch)
                    map.setRotation(mapConfig.style.rotation)
                }
            })
            map.add(markerList)
        },
        // 安全台东
        fetchStdData(type) {
            this.clearMap()
            console.log(list, 'list 安全台东')
            if (!type) {
                this.setSTDHeatMap()
            } else {
                let params = {}
                switch (type) {
                    case 'rlrl':
                        this.setSTDHeatMap()
                        break
                    case 'afjk':
                        list.sort(compare('isBridge', true))
                        list.map(item => {
                            if (item.legendType === 'afjk') {
                                params = {
                                    width: 40,
                                    height: 60,
                                    name: item.name || '',
                                    img: `/images/map/std/marker/${item.labeltype}-n.png`,
                                    lnglat: item.lnglat,
                                    type: item.labeltype,
                                    status: item.status ? item.status : '',
                                    alarm: item.alarm ? item.alarm : false,
                                    allData: item.allData
                                }
                                if (item.allData.deviceStatus == '0') {
                                    params.img = `/images/map/std/marker/${item.labeltype}-w.png`
                                }
                                if (item.labeltype === 'afjk') {
                                    params.width = 50
                                    params.height = 170
                                    if (item.allData.isBridge == '1') {
                                        params.img = `/images/map/cs/${item.labeltype}-s.png`
                                    }
                                } else if (item.labeltype === 'rlrl') {
                                    params.visitiors = item.visitiors ? item.visitiors : 0
                                    params.addr = item.addr || '无地址'
                                    params.deviceName = item.deviceName
                                    params.deviceLocation = item.deviceLocation
                                    // if (item.status !== '正常') {
                                    if (item.alarm) {
                                        params.img = `/images/map/std/marker/${item.labeltype}-w.png`
                                    }
                                } else if (item.labeltype === 'gdyy') {
                                    params.width = 45
                                    params.height = 150
                                }
                                const marker = this.createIconMarker(params)
                                markerList.push(marker)
                            }
                        })
                        break
                    case 'scdb':
                        list.map(item => {
                            // console.log(item,'ppppppppppppppppppppppppppp')
                            if (item.legendType === 'scdb') {
                                params = {
                                    width: 40,
                                    height: 60,
                                    name: item.name || '',
                                    img: `/images/map/std/marker/${item.labeltype}-n.png`,
                                    lnglat: item.lnglat,
                                    type: item.legendType,
                                    status: item.status ? item.status : '',
                                    allData: item.allData
                                }
                                if (item.allData.deviceStatus === '0') {
                                    params.img = `/images/map/std/marker/${item.labeltype}-w.png`
                                }
                                let gjc = wgs84togcj02(
                                    Number(item.allData.longitude),
                                    Number(item.allData.latitude)
                                )
                                item.lnglat = gjc
                                params.lnglat = gjc
                                const marker = this.createIconMarker(params)
                                markerList.push(marker)
                            }
                        })
                        break
                    default:
                        break
                }

                map.setZoomAndCenter(mapConfig.style.zoom, [120.353312, 36.082818])
                map.setPitch(mapConfig.style.pitch)
                map.setRotation(mapConfig.style.rotation)
                map && map.add(markerList)
            }
        },
        createIconMarker(item) {
            const icon = new AMap.Icon({
                image: item.img,
                size: new AMap.Size(item.width, item.height),
                imageSize: new AMap.Size(item.width, item.height)
            })
            let marker
            if (this.stdBridgeWarn && item.type === 'rlrl' && item.alarm) {
                let el = `<div class = "animarkerrlrl"  style="width:${item.width}px;height:${item.height}px;background: url(${item.img}) no-repeat;background-size: 100% 100%;"></div>`

                marker = new AMap.Marker({
                    content: el,
                    position: item.lnglat,
                    offset: new AMap.Pixel(0, 0),
                    anchor: 'bottom-center'
                })
            } else {
                marker = new AMap.Marker({
                    icon,
                    position: item.lnglat,
                    offset: new AMap.Pixel(0, 0),
                    anchor: 'bottom-center'
                    // anchor: 'bottom-right'
                })
            }
            var label = undefined

            marker.on('click', () => {
                console.log('marker', item.label || item.name)
                if (this.headerType === 'std') {
                    if (item.allData.isBridge === '1' && item.type == 'afjk') {
                        this.$eventBus.$emit('iw-bridge3D')
                    } else {
                        this.createInfoWindow(item)
                    }
                } else if (this.headerType == 'iw') {
                    let data = item.data
                    if (item.data.type == 'gc') {
                        console.log('智能厕所', data.installStatus)
                        console.log('公厕坑位', data.stallNumFemaleTotal)
                    }
                    if (data.isBridge == '1' && data.type !== 'bz') {
                        console.log(data.label)
                        this.$eventBus.$emit('iw-bridge3D')
                    } else {
                        this.createInfoWindow(item.data, marker)
                    }
                }

                if (label) {
                    map.remove(label)
                    label = undefined
                }
            })

            if (currType == 'iw' || currType == 'std') {
                var data,
                    offset = new AMap.Pixel(30, -120)
                if (currType == 'iw') {
                    data = item.data
                } else {
                    data = item.allData
                    if (item.type == 'rlrl' || item.type == 'scdb') {
                        offset = new AMap.Pixel(30, -20)
                    }
                }

                marker.on('mouseover', () => {
                    console.log('鼠标移入')
                    let width = data.deviceName.length * 50 * 0.4

                    const el = `<div id='do-markerLabel' style="width:${width}px;height:46px;"><div class="label-box" style="width:${width}px;"><p class="content">${data.deviceName}</p></div></div>`
                    label = new AMap.Marker({
                        content: el,
                        position: item.lnglat,
                        offset: offset,
                        anchor: 'bottom-left'
                    })
                    map.add(label)
                })
                marker.on('mouseout', () => {
                    if (label) {
                        map.remove(label)
                        label = undefined
                    }
                })
            }

            return marker
        },
        async getChartData(deviceId) {
            const { resultCode, resultData } = await parkAndPssengerFlowDetail({
                deviceId: deviceId
            })
            if (resultCode === '200' && resultData) {
                return resultData
            }
        },
        addOnlineUserMarker() {
            const tempList = this.aniList
            const size = 26
            const result = tempList.slice(0, size)
            let count = 0
            let index
            result.map(item => {
                let params = {
                    lnglat: item.lnglat,
                    img: `/images/map/cs/${item.type}.png`,
                    name: item.name,
                    width: 28,
                    height: 33
                }
                let mEl = `<div class="animarker-n" style="width:${params.width}px;height:${params.height}px;background: url(${params.img}) no-repeat;background-size: 100% 100%;">
                <img src="/images/map/cs/${params.name}.png" />
              </div>`
                const marker = new AMap.Marker({
                    content: mEl,
                    position: item.lnglat,
                    offset: new AMap.Pixel(0, 0),
                    anchor: 'bottom-center'
                })
                animarkerList.push(marker)
                sourceAnimarkerList.push(item)
            })
            map.add(animarkerList)

            addMarkerAniInterval = setInterval(() => {
                const length = this.aniList.length
                let tempmarkers = []
                let num = parseInt(Math.random() * 4) + 1
                for (let i = 0; i < num; i++) {
                    if (count + size + i >= length) {
                        index =
                            count - (length - size - i) >= 0 ? count - (length - size - i) : 0
                        console.log('大于' + length, index, 'count：' + count)
                    } else {
                        index = count + size + i
                        console.log('小于' + length, index, 'count：' + count)
                    }
                    if (index >= 0 && index < length) {
                        const item = tempList[index]
                        tempmarkers.push(item)
                    }
                }

                tempmarkers.length &&
                    tempmarkers.map(item => {
                        if (item) {
                            let params = {
                                lnglat: item.lnglat,
                                img: `/images/map/cs/${item.type}.png`,
                                name: item.name,
                                width: 28,
                                height: 33
                            }
                            const marker = this.createMarker(params)
                            animarkerList.push(marker)
                            let labelStr = this.labelType(item.type)
                            let width = labelStr.length * 40 * 0.4
                            const el = `<div class='animation-label' style="width:${width}px;height:30px;"><div class="label-box" style="width:${width}px;"><p class="content">${labelStr}</p></div></div>`
                            const label = new AMap.Marker({
                                content: el,
                                position: item.lnglat,
                                offset: new AMap.Pixel(0, -45),
                                anchor: 'bottom-center',
                                zIndex: count + 100
                            })
                            aniLabelList.push(label)
                            sourceAnimarkerList.push(item)

                            map.add(label)
                            map.add(marker)
                            timeout1 = setTimeout(() => {
                                map && map.remove(aniLabelList)
                                aniLabelList = []
                            }, 8000)
                        }
                    })
                console.log(
                    '新增===========>>>',
                    num + '个',
                    '当前' + animarkerList.length + '个'
                )
                if (count < length) {
                    count += num
                } else {
                    count = index + size - length
                }

                // if (!this.aniInterval) {
                //   console.log('头像加载结束,等待下次请求')
                // }
            }, 1000 * 10)

            timeout2 = setTimeout(() => {
                console.log('删除marker的计时器开启')
                delMarkerAniInterval = setInterval(() => {
                    let num = parseInt(Math.random() * 4) + 1
                    if (animarkerList.length < size) return
                    map && map.remove(animarkerList)
                    animarkerList = []
                    for (let i = 0; i < num; i++) {
                        // animarkerList.shift(i)
                        sourceAnimarkerList.shift(i)
                    }
                    sourceAnimarkerList.map(item => {
                        let params = {
                            lnglat: item.lnglat,
                            img: `/images/map/cs/${item.type}.png`,
                            name: item.name,
                            width: 28,
                            height: 33
                        }
                        let mEl = `<div class="animarker-n" style="width:${params.width}px;height:${params.height}px;background: url(${params.img}) no-repeat;background-size: 100% 100%;">
                <img src="/images/map/cs/${params.name}.png" />
              </div>`
                        const marker = new AMap.Marker({
                            content: mEl,
                            position: item.lnglat,
                            offset: new AMap.Pixel(0, 0),
                            anchor: 'bottom-center'
                        })
                        animarkerList.push(marker)
                    })
                    map.add(animarkerList)

                    console.log(
                        '删除===========>>>',
                        num + '个',
                        '当前' + animarkerList.length + '个'
                    )
                }, 1000 * 10)
            }, 1000 * 15)
        },
        createMarker(item) {
            const { headerType, menuType } = this
            let params = {
                width:
                    currType == 'jqdj'
                        ? 52
                        : currType == 'jqfw'
                            ? 52
                            : currType == 'jqzl'
                                ? 52
                                : 71,
                height:
                    currType == 'jqdj'
                        ? 67
                        : currType == 'jqfw'
                            ? 67
                            : currType == 'jqzl'
                                ? 67
                                : 200
            }
            let el
            if (currType == 'cs' || currType == 'do') {
                el = `<div id="cs-animarker" class='animarker' style="width:${item.width}px;height:${item.height}px;background: url(${item.img}) no-repeat;background-size: 100% 100%;">
                <img src="/images/map/cs/${item.name}.png" />
              </div>`
            } else if (currType == 'iw') {
                el = `<div id="ov-br-marker" style="width:${params.width}px;height:${params.height}px;background: url('/images/map/cs/fwss-n.png') no-repeat;background-size: 100% 100%;"></div>`
            } else if (currType == 'jqdj') {
                el = `<div style="display: flex;flex-direction: row;align-items: center;"><div id="ov-br-marker" style="width:${params.width}px;height:${params.height}px;background: url('/images/map/${headerType}/${menuType}/${item.type}.png') no-repeat;background-size: 100% 100%;"></div><div id="ov-br-markerLabel"  style="color:#fff;width: 150px;margin:-14px 0 0 -7px"><span style="padding:10px">${item.unit_name}<span></div></div>`
            } else {
                el = `<div id="ov-br-marker" style="width:${params.width}px;height:${params.height}px;background: url('/images/map/${headerType}/${menuType}/${item.type}.png') no-repeat;background-size: 100% 100%;"></div>`
            }

            const marker = new AMap.Marker({
                content: el,
                position: item.lnglat,
                offset: new AMap.Pixel(0, 0),
                anchor: 'bottom-center'
            })
            marker.on('click', () => {
                if (currType == 'br' && item.type != 'whdkd') return
                console.log('marker', item.type, item.label)
                console.log(item)
                if (currType == 'br' && item.type == 'whdkd') {
                    if (!item.content) return
                    this.$eventBus.$emit('do_activity-detail', item.content)
                } else {
                    this.openInfoWindow(item)
                }
            })
            if (item.type && item.type.substr(item.type.length - 4, 4) == 'xing') {
                let Star
                let name
                let par = {
                    'ServiceMerchants[]': {
                        ServiceMerchant: {
                            '@column':
                                'id,place_name,place_category,place_type,place_area,place_address,scale,contacts,mobile,star',
                            'id{}': [item.id]
                        }
                    }
                }
                requestPostBi('', par)
                    .then(res => {
                        let num = res['ServiceMerchants[]'][0]['ServiceMerchant'].star
                        name = res['ServiceMerchants[]'][0]['ServiceMerchant'].place_name
                        Star =
                            num == '五星'
                                ? 5
                                : num == '四星'
                                    ? 4
                                    : num == '三星'
                                        ? 3
                                        : num == '二星'
                                            ? 2
                                            : 1
                    })
                    .catch(err => {
                        console.log(err)
                    })
                setTimeout(() => {
                    let el1
                    switch (Star) {
                        case 1:
                            el1 = `<div class="ov-br-infowindow" style='padding: 30px;align-items: center;'>
          <span class="t" style='top:12px'>
            <div id="strBox"><div><div>${name}</div><i class="icon-font el-icon-star-on"></i></div></div></span>`
                            break
                        case 2:
                            el1 = `<div class="ov-br-infowindow" style='padding: 30px;align-items: center;'>
          <span class="t" style='top:12px'>
            <div id="strBox"><div>${name}</div><div><i class="icon-font el-icon-star-on"></i><i class="icon-font el-icon-star-on"></i></div></div></span>`
                            break
                        case 3:
                            el1 = `<div class="ov-br-infowindow" style='padding: 30px;align-items: center;'>
          <span class="t" style='top:12px'>
            <div id="strBox"><div>${name}</div><i class="icon-font el-icon-star-on"></i><i class="icon-font el-icon-star-on"></i><i class="icon-font el-icon-star-on"></i></div></span>`
                            break
                        case 4:
                            el1 = `<div class="ov-br-infowindow" style='padding: 30px;align-items: center;'>
          <span class="t" style='top:12px'>
            <div id="strBox"><div>${name}</div><i class="icon-font el-icon-star-on"></i><i class="icon-font el-icon-star-on"></i><i class="icon-font el-icon-star-on"></i><i class="icon-font el-icon-star-on"></i></div></span>`
                            break
                        case 5:
                            el1 = `<div class="ov-br-infowindow" style='padding: 30px;align-items: center;'>
          <span class="t" style='top:12px'>
            <div id="strBox"><div>${name}</div><i class="icon-font el-icon-star-on"><i class="icon-font el-icon-star-on"></i><i class="icon-font el-icon-star-on"></i><i class="icon-font el-icon-star-on"></i><i class="icon-font el-icon-star-on"></i></i></div></span>`
                            break

                        default:
                            break
                    }
                    const infoWindow = new AMap.InfoWindow({
                        offset: new AMap.Pixel(0, -80),
                        isCustom: true, //使用自定义窗体
                        content: el1 //使用默认信息窗体框样式，显示信息内容
                    })
                    marker.on('mouseover', function (e) {
                        infoWindow.setContent(el1)
                        infoWindow.open(map, item.lnglat)
                    })
                    marker.on('mouseout', function (e) {
                        infoWindow.close(map, item.lnglat)
                    })
                }, 1000)
            }
            return marker
        },
        changeBZstatus(id, status) {
            let that = this
            this.$prompt('', '提示', {
                customClass: 'popup-conirm',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPlaceholder: '请输入密码',
                inputType: 'password'
            })
                .then(({ value }) => {
                    console.log(value, 'this is password')
                    that.baISchecked(id, status, value)
                })
                .catch(() => { })
        },
        baISchecked(id, status, password) {
            let that = this
            let changeStatus = '0'

            if (status == '0') {
                changeStatus = '1'
            }
            this.changeDevSwitch(changeStatus, id, password).then(e => {
                if (e) {
                    console.log(list, id)
                    list.forEach(item => {
                        if (item.deviceId == id) {
                            item.deviceSwitch = changeStatus

                            let bzImg
                            let bzStatus = '关'
                            if (changeStatus == '1') {
                                bzImg = '/images/components/iw/bz-open.png'
                                bzStatus = '开'
                            } else {
                                bzImg = '/images/components/iw/bz-close.png'
                                bzStatus = '关'
                            }

                            // let devicetype = item.
                            let el = `
<div class="afjkbox" style='width:430px;height: 470px;transform: translate(30px,25%);'>
        <div class='tools'>
          <div class='title' >
            <span class='text'>摆闸</span>
            <img style="width:30px;height:30px;" src="/images/components/std/del-n.png" alt="" onclick="closeInfoWindow()"/>
          </div>
        </div>
        <div class='content' style='display: flex;width: 100%;flex-direction: column;font-size: 18px;'>
          <div class='item' style='flex:1;margin-left: 15px;'>${item.name}</div>
          <div class='item' style='flex:1;margin-left: 15px;display: flex;align-items: center;'>
            <img style="width:17px;height:17px;margin-right:10px;" src="/images/components/std/pos.png" alt="" />
            ${item.deviceLocation}</div>
            <div class='item' style='flex:5;margin-left:0 ;width: 90%;margin: 10px auto;margin-bottom: 20px;'>
              <!--            <img src='/images/components/iw/l1.png' width='300px' height='150px' alt=''>-->

        <div  class='videoContainer' style='height: 100%;width: 100%'>
        <div  class="iframe-box">
     
           <iframe
                            src="${item.cameraUrl}"
                            align="middle"
                            frameborder="0"
                            allowfullscreen
                            referrerPolicy="no-referrer"
            ></iframe>
           <div class="videoFull" onclick="videoFull('${item.cameraUrl}')"> </div>
        </div>

        <div class='videoFooter' style='height: 30px'>
          <div class='videoName'>${bzStatus}</div>
         
       <img src= ${bzImg} style="cursor:pointer;pointer-events:auto;position: absolute; left: 70px;" width='40px' height='20px' onclick="changeBZstatus('${item.deviceId}','${item.deviceSwitch}')" alt=''>
        </div>



        </div>

        </div>


        </div>

        `

                            that.infoWindow.setContent(el)
                        }
                    })
                }
            })
        },
        createDHLabelMarker(item, i) {
            let content = item.name
            let offset = new AMap.Pixel(-1, -10)
            let imgUrl = item.img
            let color =
                item.color == 'red'
                    ? '#ff0054'
                    : item.color == 'blue'
                        ? '#009cff'
                        : item.color == 'green'
                            ? '#62df00'
                            : item.color == 'orange'
                                ? '#ff9600'
                                : ''
            if (!window.imageFull) {
                window.imageFull = this.imageFull
            }
            let imgEl = imgUrl
                ? `<div onclick="imageFull('${imgUrl}')" style="background:url('${imgUrl}') no-repeat;width:200px;height:120px;background-size:cover;background-position: top left ;"></div>`
                : ''
            let el = `
          <div class="dhMarkerLabel" style='border-left:1px solid ${color}'>
            <div class="box" style='border-top:1px solid ${color};border-right:1px solid ${color};border-bottom:1px solid ${color}'>
              ${imgEl}
              <div class="title">
                <span>
                  ${content}
                </span>
              </div>
            </div>
            <div class="line"></div>
          </div>
        `

            const label = new AMap.Marker({
                content: el,
                position: item.lnglat,
                offset: offset,
                anchor: 'bottom-left',
                zIndex: i + 20
            })

            return label
        },

        imageFull(url) {
            let html = `<p><img src="${url}" /></p>`
            this.$eventBus.$emit('ov-dh-popImg', html)
        },
        createLabelMarker(item) {
            let content = item.title
            let offset = new AMap.Pixel(0, 0)
            let imgUrl = ''
            item.content.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/g,
                (match, capture) => {
                    console.log(capture, 'capture')
                    // let _u = capture.substring(7)
                    // imgUrl = 'https://images.weserv.nl/?url=' + _u
                    imgUrl = capture
                }
            )
            let el = `
          <div id="marker000" style="display: flex;">
        <div class="right" style="background-color:rgba(0,0,0,0.5);border-top: 1px solid #ff7c1d;border-bottom: 1px solid #ff7c1d;display: flex;flex-direction: column;flex:1;" >
          <div style="height: 40px;background-color: #EF9C00 ;display:flex;font-size:22px;align-items: center;text-align: center"><span style="padding:0 20px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${content}</span></div>
          <div
               style="background:url('${imgUrl}') no-repeat;width:100%;height:90px;background-size:cover;background-position: top left ;"
          >
        </div>
      </div>
        `

            var str = undefined
            const label = new AMap.Marker({
                content: el,
                position: item.location_coordinates,
                offset: offset,
                anchor: 'bottom-left'
            })
            label.on('click', () => {
                this.$eventBus.$emit('ov-bfp-r1map-qpgj', item.content)
                if (str) {
                    map.remove(str)
                    str = undefined
                }
                // console.log(item,'IIIIIIII')
                // this.openInfoWindow(item)
            })

            label.on('mouseover', () => {
                console.log('鼠标移入')
                let width = content.length * 50 * 0.4
                const el = `<div id='do-markerLabel' style="width:${width}px;height:46px;"><div class="label-box" style="width:${width}px;"><p class="content">${content}</p></div></div>`
                str = new AMap.Marker({
                    content: el,
                    position: item.location_coordinates,
                    offset: new AMap.Pixel(0, -140),
                    anchor: 'bottom-left'
                })
                map.add(str)
            })
            label.on('mouseout', () => {
                if (str) {
                    map.remove(str)
                    str = undefined
                }
            })

            return label
        },
        createLabel(item) {
            let content = ''
            if (currType == 'br' || currType == 'iw') {
                content = item.label
            } else if (currType == 'do') {
                if (item.type == 'online') {
                    content = `${item.label}上线`
                } else if (item.type == 'pay') {
                    content = `${item.label}消费金额${item.money}元`
                } else if (item.type == 'message') {
                    content = `${item.label}发布${item['length']}条新内容`
                }
            }
            let width = content.length * 50 * 0.4
            let offset = new AMap.Pixel(44, -44)
            let className = 'do-markerLabel'
            if (currType == 'br' || currType == 'iw') {
                offset = new AMap.Pixel(24, -127)
                className = 'ov-br-markerLabel'
            }

            const el = `<div id="${className}" style="width:${width}px;height:46px;"><div class="label-box" style="width:${width}px;"><p class="content">${content}</p></div></div>`
            const label = new AMap.Marker({
                content: el,
                position: item.lnglat,
                offset: offset,
                anchor: 'bottom-left'
            })
            label.on('click', () => {
                if (currType == 'br' && item.type != 'whdkd') return
                console.log('label', item.type, item.label)
                console.log(item)
                if (currType == 'br' && item.type == 'whdkd') {
                    if (!item.content) return
                    this.$eventBus.$emit('do_activity-detail', item.content)
                } else {
                    this.openInfoWindow(item)
                }
            })
            return label
        },
        openInfoWindow(item) {
            if (infoWindow) {
                map.clearInfoWindow()
            }
            if (currType == 'br') {
                let lng = item.lnglat[0] - 0.002
                let lat = item.lnglat[1] + 0.002
                map.setCenter([lng, lat])
            }
            // if (currType == 'br' && item.desc) {
            //   this.createInfoWindow(item)
            //   let lng = item.lnglat[0] - 0.002
            //   let lat = item.lnglat[1] + 0.002
            //   map.setCenter([lng, lat])
            // }
            if (currType == 'jqdj') {
                this.createInfoWindow(item)
            }
            if (currType == 'jqfw') {
                if (item.type && item.type.substr(item.type.length - 4, 4) == 'xing') {
                    let par = {
                        'ServiceMerchants[]': {
                            ServiceMerchant: {
                                '@column':
                                    'id,place_name,place_category,place_type,place_area,place_address,scale,contacts,mobile,star',
                                'id{}': [item.id]
                            }
                        }
                    }
                    requestPostBi('', par)
                        .then(res => {
                            if (res.code == 200) {
                                item.contacts =
                                    res['ServiceMerchants[]'][0]['ServiceMerchant'].contacts
                                item.mobile =
                                    res['ServiceMerchants[]'][0]['ServiceMerchant'].mobile
                                item.place_address =
                                    res['ServiceMerchants[]'][0]['ServiceMerchant'].place_address
                                item.place_area =
                                    res['ServiceMerchants[]'][0]['ServiceMerchant'].place_area
                                item.place_category =
                                    res['ServiceMerchants[]'][0]['ServiceMerchant'].place_category
                                item.place_name =
                                    res['ServiceMerchants[]'][0]['ServiceMerchant'].place_name
                                item.place_type =
                                    res['ServiceMerchants[]'][0]['ServiceMerchant'].place_type
                                item.scale =
                                    res['ServiceMerchants[]'][0]['ServiceMerchant'].scale
                                item.star = res['ServiceMerchants[]'][0]['ServiceMerchant'].star
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
                const _this = this
                setTimeout(() => {
                    _this.createInfoWindow(item)
                }, 500)
            }
            if (currType == 'jqzl') {
                let par = {
                    GovernLayer: {
                        '@column':
                            'ID,PLACE_TYPE,PLACE_AREA,PLACE_NAME,PLACE_ADDRESS,PLACE_SCALE,PLACE_HEAD,MOBILE',
                        id: item.id
                    }
                }
                requestPostBi('', par)
                    .then(res => {
                        if (res.code == 200) {
                            item.location = res['GovernLayer'].PLACE_ADDRESS
                            item.child_place_type = res['GovernLayer'].PLACE_TYPE
                            item.place_area = res['GovernLayer'].PLACE_AREA
                            item.place_name = res['GovernLayer'].PLACE_NAME
                            item.user_name = res['GovernLayer'].PLACE_HEAD
                            item.telephone = res['GovernLayer'].MOBILE
                            item.people = res['GovernLayer'].PLACE_SCALE
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                const _this = this
                setTimeout(() => {
                    _this.createInfoWindow(item)
                }, 500)
            }
            if (currType == 'bfp') {
                let offset = new AMap.Pixel(300, 300)

                let el = ` <div id='bfpimgdiv' style='width:300px;'>
 ${item.content}
</div>`

                let tempInfoWindow = new AMap.InfoWindow({
                    isCustom: true, //使用自定义窗体
                    content: el, //使用默认信息窗体框样式，显示信息内容
                    offset
                })
                infoWindow = tempInfoWindow
                infoWindow.open(map, item.location_coordinates)
                // map && map.add(label)
            }
            // if(item.type == 'fwss'){
            //   this.createInfoWindow(item.data)
            // }
        },
        async fectchdeviceAlarmNum(data) {
            const { resultCode, resultData } = await deviceAlarmNum({
                deviceId: data.deviceId,
                deviceType: data.deviceType
            })
            if (resultCode === '200' && resultData) {
                return resultData
            }
        },
        async createInfoWindow(item) {
            let infoWindow = ''
            let el = ''

            if (!window.closeInfoWindow) {
                window.closeInfoWindow = this.closeInfoWindow
            }
            if (!window.videoFull) {
                window.videoFull = this.videoFull
            }
            if (!window.swithDevice) {
                window.swithDevice = this.swithDevice
            }
            if (!window.changeVolum) {
                window.changeVolum = this.changeVolum
            }
            if (!window.changeBZstatus) {
                window.changeBZstatus = this.changeBZstatus
            }
            // if (!window.oneLDswitch) {
            //   window.oneLDswitch = this.oneLDswitch
            // }

            // if (item.type === 'scdb') {
            //   let scdbImgStauts = '-n'
            //   if (item.status === '0') {
            //     scdbImgStauts = '-w'
            //   }
            //   el = `<div style="width:246px;height:186px;position:relative;padding:10px 20px;background:url('/images/components/std/kuang-bg${scdbImgStauts}.png') no-repeat; background-size: 100% 100%;">
            //     <img style="width:100px;height:100px;position: absolute; bottom: -100px; left:-60px;" src="/images/components/std/kuang-line${scdbImgStauts}.png" alt="" />
            //     <img style="width:30px;height:30px;position: absolute; top:15px;right:15px;cursor:pointer;" src="/images/components/std/del${scdbImgStauts}.png" alt="" onclick="closeInfoWindow()"/>
            //     <p style="font-size:20px;">手持单兵</p>
            //     <p>位置： ${item.allData.deviceLocation}</p>
            //     <p>姓名： ${item.personName}</p>
            //     <p>电话：：${item.tel}</p>
            //   </div>`
            // }
            //  if (item.type === 'rlrl') {
            //   let rlrlImgStauts = '-n'
            //   let rlrlImgColor = '#00FFFF'
            //   let rlrlImgShow = 'none'
            //   // if (item.status !== '正常') {
            //   if (item.alarm) {
            //     rlrlImgStauts = '-w'
            //     rlrlImgColor = '#F6000A'
            //     rlrlImgShow = 'block'
            //   }
            //   el = `<div style="width:350px;height:186px;font-size:18px;position:relative;padding:10px 20px 20px;background:url('/images/components/std/kuang-bg${rlrlImgStauts}.png') no-repeat; background-size: 100% 100%;">
            //     <img style="width:100px;height:100px;position: absolute; bottom: -100px; left:-60px;" src="/images/components/std/kuang-line${rlrlImgStauts}.png" alt="" />
            //     <img style="width:30px;height:30px;position: absolute; top:15px;right:15px;" src="/images/components/std/del${rlrlImgStauts}.png" alt="" onclick="closeInfoWindow()"/>
            //     <p style="font-size:24px;display:flex;"> <img style="display: ${rlrlImgShow};margin-right: 10px;" src="/images/components/std/warn-icon.png" alt=""><span>客流监控</span></p>
            //     <p style="display: flex;"><img style="width:20px;height:20px;margin-right:10px;" src="/images/components/std/pos.png" alt="" />${item.addr}</p>
            //     <p>当前客流</p>
            //     <p style="color:${rlrlImgColor};font-size:26px;">${item.visitiors} <span style="font-size:20px;">人</span><img style="width:20px;height:20px;margin-left:20px;" src="/images/components/std/shangsheng${rlrlImgStauts}.png" alt=""></p>
            //   </div>`
            // } else
            if (item.type === 'afjk' || item.type === 'yyjk' || item.type == 'gdyy') {
                let data
                console.log(item, 'ssssssssssssss')
                let text = '安防监控'
                if (currType === 'std') {
                    data = item.allData
                    data.type = item.type
                } else {
                    data = item
                }

                if (item.type === 'yyjk') {
                    text = '鹰眼监控'
                }
                if (item.type == 'gdyy') {
                    text = '高点鹰眼'
                }
                data.cameraUrl = data.cameraUrl.replace(/\n+$/, '')

                el = `
        <div class="afjkbox" style='width:430px;height: 470px;transform: translate(30px,20%);'>
        <div class='tools'>
          <div class='title' >
            <span class='text'>${text}</span>
            <img style="width:30px;height:30px;" src="/images/components/std/del-n.png" alt="" onclick="closeInfoWindow()"/>
          </div>
        </div>
        <div class='content' style='display: flex;width: 100%;flex-direction: column;font-size: 18px;'>
          <div class='item' style='flex:1;margin-left: 15px;'>${data.deviceName}</div>
          <div class='item' style='flex:1;margin-left: 15px;display: flex;align-items: center;'>
            <img style="width:17px;height:17px;margin-right:10px;" src="/images/components/std/pos.png" alt="" />
            ${data.deviceLocation}</div>
            <div class='item' style='flex:5;margin-left:0 ;width: 90%;margin: 10px auto;margin-bottom: 20px;'>


        <div  class='videoContainer' style='height: 100%;width: 100%'>
        <div  class="iframe-box">
                          <iframe
                            style="height:135%"
                            src="${data.cameraUrl}"
                            align="middle"
                            frameborder="0"
                            allowfullscreen
                            referrerPolicy="no-referrer"
                        ></iframe>
        </div>
        <div class='videoFooter' style='height: 30px'>
        <div class='videoName'>${data.deviceName}</div>
        <div class="videoFull" onclick="videoFull('${data.cameraUrl}','${data.type}')"> </div>
        </div>
        </div>
        </div>
        </div>

        `

                // this.$eventBus.$emit(
                //   'dev-afjk',
                //   'http://172.16.10.23:10089/ShowVideo.html?para1=172.16.11.92'
                // )
            } else if (currType == 'br') {
                el = `<div class="ov-br-infowindow">
          <div onclick="closeInfoWindow()" class="close"><img src="/images/map/ov/br/desc-close.png" alt="" /></div>
          <span class="t">简介</span>
          <span class="v">${item.desc}</span>
        </div>`
            } else if (item.type == 'jg' || item.type == 'bjz') {
                let list = await this.fectchAlarmDatas(item)
                let alarmNumInfo = await this.fectchdeviceAlarmNum(item)
                if (list) {
                    console.log(list, '获取数据')
                    this.$refs.bjzInfo.list = list.list
                    this.$refs.bjzInfo.dataTotal = list.resultData.totalElements
                    this.$refs.bjzInfo.curPage = 0
                    this.$refs.bjzInfo.alarmInfo = alarmNumInfo
                }
                this.$refs.bjzInfo.opacity = 1
                this.$refs.bjzInfo.data = item
                if (item.type == 'jg') {
                    this.$refs.bjzInfo.title = '井盖'
                } else {
                    this.$refs.bjzInfo.title = '报警柱'
                }
                this.$refs.bjzInfo.opacity = 1
                el = this.$refs.bjzInfo.$el
            } else if (item.type == 'bz') {
                console.log(item, '摆闸')
                let status = item.deviceSwitch
                let bzImg
                let bzStatus = '关'
                if (status == '1') {
                    bzImg = '/images/components/iw/bz-open.png'
                    bzStatus = '开'
                } else {
                    bzImg = '/images/components/iw/bz-close.png'
                    bzStatus = '关'
                }

                // let devicetype = item.
                el = `
<div class="afjkbox" style='width:430px;height: 470px;transform: translate(30px,25%);'>
        <div class='tools'>
          <div class='title' >
            <span class='text'>摆闸</span>
            <img style="width:30px;height:30px;" src="/images/components/std/del-n.png" alt="" onclick="closeInfoWindow()"/>
          </div>
        </div>
        <div class='content' style='display: flex;width: 100%;flex-direction: column;font-size: 18px;'>
          <div class='item' style='flex:1;margin-left: 15px;'>${item.name}</div>
          <div class='item' style='flex:1;margin-left: 15px;display: flex;align-items: center;'>
            <img style="width:17px;height:17px;margin-right:10px;" src="/images/components/std/pos.png" alt="" />
            ${item.deviceLocation}</div>
            <div class='item' style='flex:5;margin-left:0 ;width: 90%;margin: 10px auto;margin-bottom: 20px;'>
              <!--            <img src='/images/components/iw/l1.png' width='300px' height='150px' alt=''>-->

        <div  class='videoContainer' style='height: 100%;width: 100%'>
        <div  class="iframe-box">
  
             <iframe
                            src="${item.cameraUrl}"
                            align="middle"
                            frameborder="0"
                            allowfullscreen
                            referrerPolicy="no-referrer"
            ></iframe>
      
        </div>

        <div class='videoFooter' style='height: 30px'>
          <div class='videoName'>${bzStatus}</div>
       <img src= ${bzImg} style="cursor:pointer;pointer-events:auto;position: absolute; left: 70px;" width='40px' height='20px' onclick="changeBZstatus('${item.deviceId}','${item.deviceSwitch}')" alt=''>
     <div class="videoFull" onclick="videoFull('${item.cameraUrl}')"> </div>
        </div>



        </div>

        </div>


        </div>

        `
            } else if (item.type == 'wifi') {
                console.log(item)
                el = `<div class="ljt-infowindow" style="width:300px;height:230px;transform: translate(30px,0);">
          <div onclick="closeInfoWindow()" class="close"><img src="/images/map/cs/close.png" alt="" /></div>
          <div class="t" style='background-color:rgba(7, 64, 68, 0.5);border-bottom: 1px solid #00ffff'>WIFI</div>
          <div class="t2 ljtitem" >${item.deviceName}</div>

          <p style="position: relative;left: 15px">位置： ${item.deviceLocation
                    }</p>
          <p style="position: relative;left: 15px">状态： ${item.deviceStatus == '0'
                        ? '离线'
                        : item.deviceStatus == '1'
                            ? '在线'
                            : item.deviceStatus == '2'
                                ? '故障'
                                : ''
                    }</p>
             
        </div>`
            } else if (item.type == 'gb') {
                console.log(item, item.swithDevice)
                let gbimg = '/images/components/iw/gb-0.png'
                if (item.deviceSwith == '1') {
                    gbimg = '/images/components/iw/gb-1.png'
                }
                el = `<div class="ljt-infowindow" style='width: 350px;height:300px;transform: translate(30px,0);'>
                <div onclick="closeInfoWindow()" class="close"><img src="/images/map/cs/close.png" alt="" /></div>
                <div class="t" style='background-color:rgba(7, 64, 68, 0.5);border-bottom: 1px solid #00ffff'>广播</div>
                <div class="t2 ljtitem" style="margin-bottom: 5px;
    margin-left: 5px;
    height: 35px;" >${item.name}</div>
                <div class="address ljtitem" style="height:50px;font-size:17px;margin-left:5px;align-items: center;">
                  <img class="icon"  src="/images/map/cs/location-icon.png" />
                  <span>${item.deviceLocation}</span>
                </div>
                <div class="row gbitem" style='width: 100%;background-color: rgba(9,48,75,0.0);height: 110px;border-radius: 10px;margin: 0 auto;flex-direction: column;align-items: center;'>
                    <div style='background-color: rgba(9,48,75,0.0);width: 100%;height: 50px;border-radius: 10px;line-height: 50px;display: flex;'>
<!--oninput="changeVolum(value,${item.deviceId})"-->
 <div style="margin-left:20px;margin-right:36px;width:10%">音量</div>
<div style="width: 70%;align-items: center;display: flex;flex-direction: row;"> <div style="font-size: 35px;transform:translate(-10px,-3px)">-</div>
<input style='width: 70%;height:5px;' id="range" type="range" min="0" max="100" value="${item.volumeLevel}" step="1"  onchange="changeVolum(value,${item.deviceId})">
<div style="font-size: 25px;transform:translate(10px,-2px)">+</div>
</div>
</div>
                    <div style='background-color: rgba(9,48,75,0.0);width: 90%;height: 50px;border-radius: 10px;line-height: 50px;align-items: center;'>
<span>播放</span>
<img id='gb-swithImg' width='25px' height='25px' src="${gbimg}" alt=""  style="position:absolute;left:80px;cursor:pointer;transform:translateY(50%)" onclick="swithDevice(${item.swithDevice},${item.deviceId})" />
<!--<img src="/images/components/iw/gb-0.png" width='25px' height='25px' alt=""  style="position:absolute;left:70px;cursor:pointer;transform:translateY(50%)""/>-->
</div>
</div>
                </div>

              </div>`
            } else if (item.type == 'zndg') {
                let status = item.deviceSwitch
                let bzImg, dgFlag
                if (status == '1') {
                    bzImg = '/images/components/iw/bz-open.png'
                    dgFlag = '开'
                } else {
                    bzImg = '/images/components/iw/bz-close.png'
                    dgFlag = '关'
                }

                this.$eventBus.$emit('ldpop-demo', {
                    data: {
                        bzImg: bzImg,
                        dgFlag: dgFlag
                    }
                })

                el = `<div class="ljt-infowindow" style='width:300px;height:200px;transform: translate(30px,0);'>
                <div onclick="closeInfoWindow()" class="close"><img src="/images/map/cs/close.png" alt="" /></div>
                <div class="t" style='background-color:rgba(7, 64, 68, 0.5);border-bottom: 1px solid #00ffff'>智能路灯</div>
                <div class="t2 ljtitem" style=''>${item.name}</div>
                <div class="address ljtitem" >
                  <img class="icon" src="/images/map/cs/location-icon.png" />
                  <span>${item.deviceLocation}</span>
                </div>
<p class='gcitem' style='display: flex;'>当前状态：${dgFlag}

</p>
              </div>`
            } else if (item.type == 'db' || item.type === 'scdb') {
                console.log(item, 'dn')
                let data
                if (currType === 'std') {
                    data = item.allData
                } else {
                    data = item
                }
                data.cameraUrl = data.cameraUrl.replace(/\n+$/, '')
                el = `

        <div class="afjkbox" style='width:430px;height: 470px;transform: translate(30px,25%);'>
        <div class='tools'>
          <div class='title' >
            <span class='text'>单兵</span>
            <img style="width:30px;height:30px;" src="/images/components/std/del-n.png" alt="" onclick="closeInfoWindow()"/>
          </div>
        </div>
        <div class='content' style='display: flex;width: 100%;flex-direction: column;font-size: 18px;'>
          <div class='item' style='flex:1;margin-left: 15px;display: flex;'>
<div>${data.deviceName}</div>
<div style='position: absolute;right:10px;'>${data.personName}：${data.tel}</div>
</div>
          <div class='item' style='flex:1;margin-left: 15px;display: flex;align-items: center;'>
            <img style="width:17px;height:17px;margin-right:10px;" src="/images/components/std/pos.png" alt="" />
            ${data.deviceLocation}</div>
            <div class='item' style='flex:5;margin-left:0 ;width: 90%;margin: 10px auto;margin-bottom: 20px;'>
              <!--            <img src='/images/components/iw/l1.png' width='300px' height='150px' alt=''>-->

        <div  class='videoContainer' style='height: 100%;width: 100%'>
        <div  class="iframe-box">
<!--        <img src='/images/components/iw/l1.png' width='280px' height='130px' alt=''>-->
                          <iframe
                            src="${data.cameraUrl}"
                            align="middle"
                            frameborder="0"
                            allowfullscreen
                            referrerPolicy="no-referrer"
                        ></iframe>
        </div>

        <div class='videoFooter' style='height: 30px'>
        <div class='videoName'>${data.deviceName}</div>
        <div class="videoFull" onclick="videoFull('${data.cameraUrl}')"> </div>
        </div>



        </div>

        </div>


        </div>
        `
            } else if (item.type == 'kljk' || item.type === 'rlrl') {
                let data
                if (currType === 'std') {
                    data = item.allData
                } else {
                    data = item
                }
                let options = {
                    grid: {
                        top: '5%',
                        left: '8%',
                        right: '3%',
                        bottom: '15%'
                    },
                    tooltip: {
                        trigger: 'axis',
                        confine: true,
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                        padding: 0,
                        formatter: params => {
                            let el = ``
                            params.forEach(item => {
                                el = `<div class="tooltip-box column center">
                      <span class="time">${item.name}</span>
                      <div class="text marginTop">
                        <span class="t">客流趋势：</span>
                        <span class="v">${Number(item.value).toFixed(
                                    0
                                )}人</span>
                      </div>
                    </div>`
                            })
                            return el
                        }
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: {
                            color: '#fff',
                            fontSize: 16
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#2973AC'
                            }
                        },
                        // 不显示刻度线
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#2973AC',
                                type: [3, 5]
                            }
                        },
                        data: []
                    },
                    yAxis: {
                        type: 'value',
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#2973AC',
                                type: [3, 5]
                            }
                        },
                        axisLabel: {
                            color: '#fff',
                            fontSize: 16,
                            formatter: function (val) {
                                return val
                            }
                        }
                    },
                    series: [
                        {
                            name: '客流量',
                            type: 'line',
                            // symbol: 'none',
                            lineStyle: {
                                color: '#fff'
                            },
                            areaStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#00FCFF' // 0% 处的颜色
                                    },
                                    {
                                        offset: 0.5,
                                        color: '#00ff8472' // 100% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#142D3F' // 100% 处的颜色
                                    }
                                ])
                            },
                            data: []
                        }
                    ]
                }
                console.log(data, '客流监控')
                let pfDate = []
                let chartData = await this.getChartData(data.deviceId)

                chartData.pfChart.pfDate.forEach(item => {
                    pfDate.push(item.split(' ')[1] + ' 时')
                })
                options.xAxis.data = pfDate
                options.series[0].data = chartData.pfChart.pfData

                this.$refs.iwInfo.options = options
                this.$refs.iwInfo.data = data
                this.$refs.iwInfo.opacity = 1

                el = this.$refs.iwInfo.$el
            } else if (item.type == 'ljdw') {
                if (item.unit_address) {
                    el = `<div class="ov-br-infowindow" style='padding: 42px 12px 20px;'>
          <div onclick="closeInfoWindow()" class="close"><img src="/images/map/ov/br/desc-close.png" alt="" /></div>
          <span class="t" style='top:12px'>${item.unit_name}</span>
          <div class='bdg-jqdj-ljdw'> <p>分类: ${item.unit_category}</p><p>所属组织: ${item.unit_org}</p><p>单位名称: ${item.unit_name}</p><p>单位地址: ${item.unit_address}</p><p>在职党员: ${item.party_num}人</p><p>联系人: ${item.contacts}</p><p>联系电话: ${item.mobile}</p></div>
        </div>`
                } else {
                    el = `<div class="ov-br-infowindow">
          <div onclick="closeInfoWindow()" class="close"><img src="/images/map/ov/br/desc-close.png" alt="" /></div>
          <span class="t">暂无数据</span>
        </div>`
                }
            } else if (
                item.type &&
                item.type.substr(item.type.length - 4, 4) == 'xing'
            ) {
                if (item.place_address) {
                    el = `<div class="ov-br-infowindow" style='padding: 42px 12px 20px;'>
          <div onclick="closeInfoWindow()" class="close"><img src="/images/map/ov/br/desc-close.png" alt="" /></div>
          <span class="t" style='top:12px'>${item.place_name}</span>
          <div class='bdg-jqdj-ljdw'> <p>场所类别: ${item.place_category}</p><p>场所类型: ${item.place_type}</p><p>场所区域: ${item.place_area}</p><p>场所名称: ${item.place_name}</p><p>场所地址: ${item.place_address}</p><p>规模:${item.scale}人</p><p>联系人: ${item.contacts}</p><p>联系电话: ${item.mobile}</p></div>
        </div>`
                } else {
                    el = `<div class="ov-br-infowindow">
          <div onclick="closeInfoWindow()" class="close"><img src="/images/map/ov/br/desc-close.png" alt="" /></div>
          <span class="t">暂无数据</span>
        </div>`
                }
            } else if (
                item.type == 'emergency_response' ||
                item.type == 'enterprise_inspect' ||
                item.type == 'city_management' ||
                item.type == 'public_service'
            ) {
                if (item.location) {
                    el = `<div class="ov-br-infowindow" style='padding: 42px 12px 20px;'>
          <div onclick="closeInfoWindow()" class="close"><img src="/images/map/ov/br/desc-close.png" alt="" /></div>
          <span class="t" style='top:12px'>${item.place_name}</span>
          <div class='bdg-jqdj-ljdw'> <p>场所类型: ${item.child_place_type}</p><p>场所区域: ${item.place_area}</p><p>场所名称: ${item.place_name}</p><p>场所地址: ${item.location}</p><p>规模: ${item.people}人</p><p>联系人: ${item.user_name}</p><p>联系电话: ${item.telephone}</p></div>
        </div>`
                } else {
                    el = `<div class="ov-br-infowindow">
          <div onclick="closeInfoWindow()" class="close"><img src="/images/map/ov/br/desc-close.png" alt="" /></div>
          <span class="t">暂无数据</span>
        </div>`
                }
            }
            //<p>规模: 21人</p>
            //     else if (item.type == 'emergency_response') {
            //         el = `<div class="ov-br-infowindow">
            //   <div onclick="closeInfoWindow()" class="close"><img src="/images/map/ov/br/desc-close.png" alt="" /></div>
            //   <span class="t">暂无数据</span>
            // </div>`
            //     } else if (item.type == 'enterprise_inspectelse') {
            //         el = `<div class="ov-br-infowindow">
            //   <div onclick="closeInfoWindow()" class="close"><img src="/images/map/ov/br/desc-close.png" alt="" /></div>
            //   <span class="t">暂无数据</span>
            // </div>`
            //     } else if (item.type == 'city_management') {
            //         el = `<div class="ov-br-infowindow">
            //   <div onclick="closeInfoWindow()" class="close"><img src="/images/map/ov/br/desc-close.png" alt="" /></div>
            //   <span class="t">暂无数据</span>
            // </div>`
            //     } else if (item.type == 'city_management') {
            //         el = `<div class="ov-br-infowindow">
            //   <div onclick="closeInfoWindow()" class="close"><img src="/images/map/ov/br/desc-close.png" alt="" /></div>
            //   <span class="t">暂无数据</span>
            // </div>`
            //     } else if (item.type == 'enterprise_inspect') {
            //         el = `<div class="ov-br-infowindow">
            //   <div onclick="closeInfoWindow()" class="close"><img src="/images/map/ov/br/desc-close.png" alt="" /></div>
            //   <span class="t">暂无数据</span>
            // </div>`
            //     } else if (item.type == 'comprehensive_control') {
            //         el = `<div class="ov-br-infowindow">
            //   <div onclick="closeInfoWindow()" class="close"><img src="/images/map/ov/br/desc-close.png" alt="" /></div>
            //   <span class="t">暂无数据</span>
            // </div>`
            //     }

            if (item.type == 'tcc') {
                let options = {
                    grid: {
                        top: '5%',
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                        confine: true,
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                        padding: 0,
                        formatter: params => {
                            let el = ``
                            params.forEach(item => {
                                el = `<div class="tooltip-box column center">
                      <span class="time">${item.name} 时</span>
                      <div class="text marginTop">
                        <span class="t">车位预测：</span>
                        <span class="v">${Number(item.value).toFixed(
                                    0
                                )}个</span>
                      </div>
                    </div>`
                            })
                            return el
                        }
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: {
                            color: '#fff',
                            fontSize: 16,
                            formatter: function (val) {
                                return val
                            }
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#2973AC'
                            }
                        },
                        // 不显示刻度线
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#2973AC',
                                type: [3, 5]
                            }
                        },
                        data: [1, 2, 3, 4, 5, 6]
                    },
                    yAxis: {
                        type: 'value',
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#2973AC',
                                type: [3, 5]
                            }
                        },
                        axisLabel: {
                            color: '#fff',
                            fontSize: 16,
                            formatter: function (val) {
                                return val
                            }
                        }
                    },
                    series: [
                        {
                            type: 'bar',
                            barWidth: '50%',
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#0EDFC3'
                                    },
                                    {
                                        offset: 1,
                                        color: '#142D3F'
                                    }
                                ])
                            },
                            data: [50, 55, 65, 70, 90, 100]
                        }
                    ]
                }
                // if (item.picUrl && item.parkForecastModelList) {
                let chartData = await this.getChartData(item.deviceId)
                if (chartData.parkForecastModelList && chartData.parkForecastModelList.length) {
                    let data = []
                    let date = []
                    // console.log(this.data.parkForecastModelList,this.data.picUrl,'ppppppppppppppppppp')
                    chartData.parkForecastModelList.forEach(ii => {
                        data.push(ii.empty)
                        date.push(ii.time)
                    })
                    options.xAxis.data = date
                    options.series[0].data = data
                    this.$refs.tcInfo.options = options
                    this.$refs.tcInfo.data = item
                    this.$refs.tcInfo.opacity = 1
                    el = this.$refs.tcInfo.$el
                } else {
                    console.log(item,'停车场')
                    var picUrl,tempel=``,totalel=``,
                    height='250px',transform='translate(30px,0)'
                    if(item.picUrl && item.picUrl.length){
                        height = '475px'
                        // picUrl = item.picUrl.split(',')[0]
                        picUrl = item.picUrl.split(',')
                        picUrl.forEach(url=>{
                            tempel =  tempel.concat(
                        ` <img src="${url}" width='100%' height='100%' alt=''>`
                    )
                        })

                        totalel=`
                 
                         <div  class="iframe-box tccbox" style='display: flex;flex-direction: column;margin-top:20px; '>
            
                  <div style=" border: 2px solid #074044;height:255px;" class="tccbox1">
                        ${tempel}
                  </div>
                </div>
              
                        `
                //         tempel =
                //          `<div  class="iframe-box" style='display: flex;flex-direction: column;margin-top:20px; '>

                //   <div style=" border: 2px solid #074044;height:255px;">
                //         <img src="${picUrl}" width='100%' height='100%' alt=''>
                //   </div>
                // </div>`
                    }
                    
                    el = `<div class="ljt-infowindow" style='width: 410px;height:${height};transform:${transform};'>
                <div onclick="closeInfoWindow()" class="close"><img src="/images/map/cs/close.png" alt="" /></div>
                <div class="t" style='background-color:rgba(7, 64, 68, 0.5);border-bottom: 1px solid #00ffff'>停车场</div>
                <div class="t2 ljtitem" style=''>${item.name}</div>
                <div class="address ljtitem" >
                  <img class="icon" src="/images/map/cs/location-icon.png" />
                  <span>${item.deviceLocation || '--'}</span>
                </div>
               <div class='ljtitem' style='display: flex;margin-top:15px;' >
                  <div style='margin-bottom: 7px;'> 车位数量 </div>
                  <div><span style='color:#00FCFF;margin:0 5px;font-size: 20px;'>${item.parkingPlaceNumTotal
                        }</span>  个</div>
            </div>
            ${totalel} 
              </div>`
                }
            } else if (item.type == 'gc') {
                console.log(item, item.installStatus)
                let manTotal = item.stallNumMaleTotal
                let womanTotal = item.stallNumFemaleTotal
                let man = item.stallNumMaleTotal - item.stallNumMaleUsed //总数-已使用
                let woman = item.stallNumFemaleTotal - item.stallNumFemaleUsed
                let manEl = ``
                let womanEl = ``
                for (let i = 0; i < manTotal; i++) {
                    manEl = manEl.concat(
                        `<img class="man" src="/images/map/cs/man-${i < man ? 's' : 'u'
                        }.png">`
                    )
                }
                for (let i = 0; i < womanTotal; i++) {
                    womanEl = womanEl.concat(
                        `<img class="woman" src="/images/map/cs/woman-${i < woman ? 's' : 'u'
                        }.png">`
                    )
                }

                let zn = item.installStatus === '1' ? true : false
                let name, height
                // let  smellLevel =item.smellLevel
                let manSlEL = ``
                let womanSlEL = ``
                // item.smellLevel = 5
                if (item.smellLevel || item.smellLevel == 0) {
                    switch (Number(item.smellLevel)) {
                        case 0:
                        case 1:
                            manSlEL = `
           <span class="zngctxt">异味程度：<span  style="color:#00FF0C;font-weight: bold">无异味</span></span>
           <div class="zngcITEMbox" style="display: flex;flex-direction: row;margin-left: 20px;">
            <div class="zngcITEM">
                <div class="item" style="background: #00FF0C;"></div>
            </div>
            <div class="zngcITEM" style="background: #556e6c ;">
              <div class="item" style="background: #fff ;"></div>
            </div>
               <div class="zngcITEM" style="background: #556e6c ;">
              <div class="item" style="background: #fff ;"></div>
            </div>
           </div>
           `
                            break
                        case 2:
                        case 3:
                            manSlEL = `
           <span>异味程度：<span  style="color:#FFFD35;font-weight: bold">微弱异味</span></span>
           <div class="zngcITEMbox" style="display: flex;flex-direction: row;margin-left: 20px;align-items: center;">
            <div class="zngcITEM">
                <div class="item" style="background: #00FF0C;;opacity: 1"></div>
            </div>
            <div class="zngcITEM" style="background: #888608 ;;opacity: 1">
              <div class="item"  style="background: #FFFD35 ;opacity: 1"></div>
            </div>
               <div class="zngcITEM" style="background: #556e6c;">
              <div class="item" style="background: #fff ;"></div>
            </div>
           </div>
           `
                            break
                        case 4:
                        case 5:
                            manSlEL = `
           <span>异味程度：<span  style="color:#E80000;font-weight: bold">强烈异味</span></span>
           <div class="zngcITEMbox" style="display: flex;flex-direction: row;margin-left: 20px;">
            <div class="zngcITEM">
                <div class="item" style="background: #00FF0C;"></div>
            </div>
            <div class="zngcITEM" style="background: #888608 ;">
              <div class="item" style="background: #FFFD35 ;"></div>
            </div>
            <div class="zngcITEM"   style="background: #853939;">
              <div class="item" style="background: #E80000;"></div>
            </div>
           </div>
           `
                            break
                    }
                }

                if (item.smellLevelFemaleToilet || item.smellLevelFemaleToilet == 0) {
                    switch (Number(item.smellLevelFemaleToilet)) {
                        case 0:
                        case 1:
                            womanSlEL = `
           <span class="zngctxt">异味程度：<span  style="color:#00FF0C;font-weight: bold">无异味</span></span>
           <div class="zngcITEMbox" style="display: flex;flex-direction: row;margin-left: 20px;">
            <div class="zngcITEM">
                <div class="item" style="background: #00FF0C;"></div>
            </div>
            <div class="zngcITEM" style="background: #556e6c ;">
              <div class="item" style="background: #fff ;"></div>
            </div>
               <div class="zngcITEM" style="background: #556e6c ;">
              <div class="item" style="background: #fff ;"></div>
            </div>
           </div>
           `
                            break
                        case 2:
                        case 3:
                            womanSlEL = `
           <span>异味程度：<span  style="color:#FFFD35;font-weight: bold">微弱异味</span></span>
           <div class="zngcITEMbox" style="display: flex;flex-direction: row;margin-left: 20px;align-items: center;">
            <div class="zngcITEM">
                <div class="item" style="background: #00FF0C;;opacity: 1"></div>
            </div>
            <div class="zngcITEM" style="background: #888608 ;;opacity: 1">
              <div class="item"  style="background: #FFFD35 ;opacity: 1"></div>
            </div>
               <div class="zngcITEM" style="background: #556e6c;">
              <div class="item" style="background: #fff ;"></div>
            </div>
           </div>
           `
                            break
                        case 4:
                        case 5:
                            womanSlEL = `
           <span>异味程度：<span  style="color:#E80000;font-weight: bold">强烈异味</span></span>
           <div class="zngcITEMbox" style="display: flex;flex-direction: row;margin-left: 20px;">
            <div class="zngcITEM">
                <div class="item" style="background: #00FF0C;"></div>
            </div>
            <div class="zngcITEM" style="background: #888608 ;">
              <div class="item" style="background: #FFFD35 ;"></div>
            </div>
            <div class="zngcITEM"   style="background: #853939;">
              <div class="item" style="background: #E80000;"></div>
            </div>
           </div>
           `
                            break
                    }
                }

                // console.log(smellLevle)
                if (zn) {
                    name = '智能公厕'
                    height = '470px'
                } else {
                    name = '公厕'
                    height = '300px'
                }
                let zngcEl =
                    item.installStatus == '1'
                        ? `<div class="row" style="margin-left:15px;height:45px">
                <span>男：</span>
                <span class="num" style="margin-right:15px">空${man +
                        '/' +
                        manTotal}</span>
                <div>${manEl}</div>
              </div>
              <div class="row" style="margin-left:15px;display: flex">${manSlEL}</div>
              <div class="row" style="margin-left:15px;height:45px">
                <span>女：</span>
                <span class="num"  style="margin-right:15px">空${woman +
                        '/' +
                        womanTotal}</span>
                <div>${womanEl}</div>
              </div>
              <div class="row" style="margin-left:15px;display: flex">${womanSlEL}</div>`
                        : ``
                el = `<div class='gc-infowindow' style='height: ${height};width: 450px;transform: translate(30px,25%);'>
                <div onclick='closeInfoWindow()' class='close'><img src='/images/map/cs/close.png' alt='' /></div>
                 <div class='t' style='background-color:rgba(7, 64, 68, 0.5);border-bottom: 1px solid #00ffff'>${name}</div>
                <div class='t2 ljtitem' style=''>${item.name}</div>
                <p class='address ' style='margin-left:15px'>
                  <img class='icon' src='/images/map/cs/location-icon.png' />
                  <span>${item.deviceLocation}</span>
                </p>
                <p class='gcitem' style="height:45px;">
                  <img class='icon' src='/images/components/iw/gctime.png' width='17px' height='17px' />
                  <span>00:00-24:00</span>
                </p>
                <div class='gcitem' style='display:flex;margin-top: 20px;margin-bottom: 20px;justify-content: space-between;width:90%;'>
                    <div class='gcitemc'>
                    <img src='/images/components/iw/gcwza.png' width='35px' height='35px' alt=''>
                    <div class='gcitem2'>   <div>无障碍设施</div>  <div>${item.barrierFree ||
                    '无'}</div>  </div>
                    </div>
<div class='gcitemc'>
                    <img src='/images/components/iw/gcmy.png' width='35px' height='35px' alt=''>
                    <div class='gcitem2'>   <div>母婴室</div>  <div>${item.babyCareRoom ||
                    '无'}</div>  </div>
                    </div>
<div class='gcitemc'>
                    <img src='/images/components/iw/gcbj.png' width='35px' height='35px' alt=''>
                    <div class='gcitem2'>   <div>专人保洁</div>  <div>${item.cleaner ||
                    '无'}</div>  </div>
                    </div>

</div>

                ${zngcEl}
              </div>`
            } else if (item.type == 'ljt') {
                console.log(item, item.installStatus)
                let ljtTem = []
                let name = '垃圾桶'
                let zn = false
                if (item.installStatus == '1') {
                    name = '智能垃圾桶'
                    zn = true
                    ljtTem = await this.getLJTInfo(item.ibmsId)
                }

                if (zn) {

                    el = `<div class="ljt-infowindow" style='width:350px;height:355px;transform:translate(30px,0);'>
                <div onclick="closeInfoWindow()" class="close"><img src="/images/map/cs/close.png" alt="" /></div>
                <div class="t" style='background-color:rgba(7, 64, 68, 0.5);border-bottom: 1px solid #00ffff'>${name}</div>
                <div class="t2 ljtitem" style=''>${item.name}</div>
                <div class="address ljtitem" >
                  <img class="icon" src="/images/map/cs/location-icon.png" />
                  <span>${item.deviceLocation}</span>
                </div>
                <div class="row ljtitem" style="height: 180px;justify-content: space-around;">
                      <div class="ljtitem01">
                            <div class="ljtTop"> ${ljtTem[0].temp} ℃  </div>
                            <div class="ljtCenter">
                                <div class="ljtCenter-title">${ljtTem[0].garbageDistance}%</div>
                                <div class="ljtCenter-color" style="height:${ljtTem[2].garbageDistance}px;"></div>
                            </div>
                            <div class="ljtBottom"> ${ljtTem[0].deviceName} </div>
                      </div>
                     <div class="ljtitem01">
                            <div class="ljtTop">  ${ljtTem[2].temp} ℃  </div>
                            <div class="ljtCenter">
                                <div class="ljtCenter-title">${ljtTem[2].garbageDistance}%</div>
                                <div class="ljtCenter-color" style="background-color:#02DD78;height:${ljtTem[2].garbageDistance}px;"></div>
                            </div>
                            <div class="ljtBottom" style="margin-left: -10px;">${ljtTem[2].deviceName} </div>
                      </div>
                      <div class="ljtitem01">
                            <div class="ljtTop"> ${ljtTem[1].temp} ℃  </div>
                            <div class="ljtCenter">
                                <div class="ljtCenter-title">${ljtTem[1].garbageDistance}%</div>
                                <div class="ljtCenter-color" style="background-color:#E80000;height:${ljtTem[2].garbageDistance}px;"></div>
                            </div>
                            <div class="ljtBottom">${ljtTem[1].deviceName} </div>
                      </div>
                </div>
              </div>`
                } else {
                    el = `<div class="ljt-infowindow" style='height: 150px;width:300px;transform:translate(30px,0);'>
                <div onclick="closeInfoWindow()" class="close"><img src="/images/map/cs/close.png" alt="" /></div>
                <div class="t" style='background-color:rgba(7, 64, 68, 0.5);border-bottom: 1px solid #00ffff'>${name}</div>
                <div class="t2 ljtitem" style=''>${item.name}</div>
                <div class="address ljtitem" >
                  <img class="icon" src="/images/map/cs/location-icon.png" />
                  <span>${item.deviceLocation}</span>
                </div>

              </div>`
                }
            } else if (item.type == 'fwss') {
                console.log(item, '2866')
                el = `
<div class="afjkbox" style='width:430px;height: 470px;transform: translate(30px,25%);'>
        <div class='tools'>
          <div class='title' >
            <span class='text'>服务设施</span>
            <img style="width:30px;height:30px;" src="/images/components/std/del-n.png" alt="" onclick="closeInfoWindow()"/>
          </div>
        </div>
        <div class='content' style='display: flex;width: 100%;flex-direction: column;font-size: 18px;'>
          <div class='item' style='flex:1;margin-left: 15px;'>${item.name}</div>
          <div class='item' style='flex:1;margin-left: 15px;display: flex;align-items: center;'>
            <img style="width:17px;height:17px;margin-right:10px;" src="/images/components/std/pos.png" alt="" />
            ${item.deviceLocation || '--'}</div>
            <div class='item' style='flex:5;margin-left:0 ;height:75%;width: 90%;margin: 10px auto;margin-bottom: 20px;'>
              <!--            <img src='/images/components/iw/l1.png' width='300px' height='150px' alt=''>-->

        <div  class='videoContainer' style='height: 100%;width: 100%'>
          <div  class="iframe-box" style='height: 100%;width: 100%'>
          <img src="${item.picUrl}" width='100%' height='100%' alt=''>

        </div>
        </div>
        </div>
        </div>

        `
            }
            let anchor = currType == 'br' ? 'top-left' : ''
            let offset = new AMap.Pixel(0, 0)
            if (currType == 'iw' || currType == 'std') {
                anchor = currType == 'br' ? 'top-left' : 'bottom-left'
            }

            if (currType == 'br') {
                offset = new AMap.Pixel(18, -120)
            }
            // else if (item.type == 'gc') {
            //   offset = new AMap.Pixel(200, -70)
            // } else if (item.type == 'afjk') {
            //   offset = new AMap.Pixel(230, 0)
            // } else if (
            //   item.type == 'kljk' ||
            //   item.type == 'tcc' ||
            //   item.type == 'jg' ||
            //   item.type == 'bjz' ||
            //   item.type == 'rlrl'
            // ) {
            //   offset = new AMap.Pixel(330, 0)
            // } else if (item.type == 'ljt') {
            //   offset = new AMap.Pixel(220, 0)
            // } else if (
            //   item.type == 'bz' ||
            //   item.type == 'db' ||
            //   item.type == 'fwss'
            // ) {
            //   offset = new AMap.Pixel(235, 0)
            // }
            infoWindow = new AMap.InfoWindow({
                isCustom: true, //使用自定义窗体
                content: el, //使用默认信息窗体框样式，显示信息内容
                offset,
                anchor
            })
            this.infoWindow = infoWindow
            infoWindow.open(map, item.lnglat)
            if (currType == 'iw' || currType == 'std') {
                map.setZoomAndCenter(18, [
                    Number(item.lnglat[0]),
                    Number(item.lnglat[1])
                ])
                map.setCenter([
                    Number(item.lnglat[0]) - 0.000375,
                    Number(item.lnglat[1]) + 0.0013719
                ])
            }
        },
        createAlarmWindow() {
            // let infoWindow = ''
            // let el = ''
            // if (!window.closeInfoWindow) {
            //   window.closeInfoWindow = this.closeInfoWindow
            // }
        },
        closeInfoWindow() {
            console.log('关闭', currType)
            if (infoWindow || this.infoWindow) {
                map.clearInfoWindow()
                map.setZoomAndCenter(16.77, [120.352486, 36.082912])
                map.setPitch(mapConfig.style.pitch)
                map.setRotation(mapConfig.style.rotation)
            }
        },
        async getLJTInfo(id) {
            const { resultCode, resultData } = await _axios.post(
                '/stat/deviceInfo/queryDustbinList/' + `${id}`
            )

            if (resultCode === '200' && resultData) {
                return resultData
            }

        },
        async fetchstdHeatData() {
            stdHeatData = []
            const { resultCode, resultData } = await _axios.post(
                '/stat/passengerFlow/getPassengerFlowHeatmapData/'
            )

            if (resultCode === '200' && resultData) {
                resultData.forEach(res => {
                    let e = res.passengerFlowModelList
                    e.forEach(item => {
                        let gjc = wgs84togcj02(Number(item.lng), Number(item.lat))
                        stdHeatData.push({
                            lnglat: gjc,
                            count: Number(item.num)
                        })
                    })
                })
            }
        },
        // oneLDswitch(deviceId, switchStatus) {
        //   this.changeSwitch(deviceId, switchStatus)
        // },
        // async changeSwitch(deviceId) {
        //   const { resultCode, resultData } = await changeLightingMode({
        //     deviceId: deviceId
        //     // "lightingMode": "30",
        //     // "deviceType": "lighting"
        //   })

        //   if (resultCode === '200' && resultData) {
        //     console.log(resultData, 'suc')
        //   }
        // },
        swithDevice(value, id) {
            if (!this.outswith) {
                console.log('00')
                this.outswith = value ? value : '0'
            } else {
                console.log('11')
                this.outswith == '0' ? '1' : '0'
            }

            let gbimg = `/images/components/iw/gb-${this.outswith}.png`

            console.log(value, this.outswith, gbimg, this.switch)
            document.getElementById('gb-swithImg').src = gbimg
            this.changevoluems(this.outswith, id)

            // this.switch =!this.switch
        },
        changeVolum(value, id) {
            this.changevoluems(value, id)
        },
        async changevoluems(value, id) {
            const { resultCode, resultData } = await _axios.post(
                'stat/deviceInfo/controlBoardVolume',
                {
                    deviceId: id,
                    volumeLevel: value
                }
            )
            if (resultCode === '200' && resultData) {
                return resultData
            }
        },
        async changeDevSwitch(value, id, password) {
            const { resultCode, resultData } = await _axios.post(
                'stat/deviceInfo/controlDeviceSwitch',
                {
                    deviceId: id,
                    deviceSwitch: value,
                    password: password
                }
            )
            if (resultCode === '200' && resultData) {
                return resultData
            }
        },
        videoFull(url, type) {
            let isfull = false

            if (type && (type == 'yyjk' || type == 'gdyy')) {
                isfull = true
            }
            if (url) {
                this.$eventBus.$emit('dev-afjk', {
                    url,
                    isFull: isfull
                })
            }
        },
        async fectchAlarmDatas(data) {
            console.log(data)
            const { resultCode, resultData } = await alarmList({
                pageNo: 0,
                pageSize: 3,
                deviceId: data.deviceId
            })
            let list = []
            if (resultCode === '200' && resultData) {
                resultData.content.forEach(item => {
                    list.push({
                        content: item.alarmContent,
                        postion: item.alarmLocation,
                        time: item.alarmTime.split(' ')[0]
                    })
                })
            }

            return { list, resultData }
        },
        // 安全台东-人流热力图
        async setSTDHeatMap() {
            if (!stdHeatData || stdHeatData.length <= 0) {
                await this.fetchstdHeatData()
            }
            var info = []
            stdHeatData.forEach(heat => {
                info.push({
                    lng: heat.lnglat[0],
                    lat: heat.lnglat[1],
                    count: heat.count //3 * 6.4 * Math.round(Math.random() * (10 - 2) + 2)
                })
                // let width = heat.name.length * 50 * 0.4
                // const el = `<div id="ov-dh-heatLabel" style="width:${width}px;">${heat.name}</div>`
                // const label = new AMap.Marker({
                //   content: el,
                //   position: heat.lnglat,
                //   offset: new AMap.Pixel(0, 15),
                //   anchor: 'bottom-center'
                // })
                // this.heatLabels.push(label)
            })

            // if (!heatmap) {
            //   map.plugin(['AMap.Heatmap'], () => {
            //     heatmap = new AMap.Heatmap(map, {
            //       radius: 56, //给定半径
            //       opacity: [0, 0.5]
            //     })
            //     //设置数据集
            //     heatmap.setDataSet({
            //       data: info,
            //       max: 100
            //     })
            //     heatmap.show()
            //   })
            // }
            /* 2.0 */
            if (!heatmap) {
                map.plugin(['AMap.HeatMap'], () => {
                    heatmap = new AMap.HeatMap(map, {
                        radius: 60, //给定半径
                        opacity: [0, 0.8],
                        '3d': {
                            // //热度转高度的曲线控制参数，可以利用左侧的控制面板获取
                            heightBezier: [0.4, 0.2, 0.4, 0.8],
                            // //取样精度，值越小，曲面效果越精细，但同时性能消耗越大
                            gridSize: 1,
                            heightScale: 0
                        }
                    })
                    //设置数据集
                    heatmap.setDataSet({
                        data: info,
                        max: 500
                    })
                    heatmap.show()
                })
            }
        },
        resetMapCenter() {
            map.setZoomAndCenter(mapConfig.style.zoom, mapConfig.style.center)
            if (infoWindow) {
                map.clearInfoWindow()
            }
            infoWindow = null
        },
        clearCsCover() {
            roadTrafficLayer.hide()
            map && map.remove(polygonList)
            map && map.remove(polygonLabels)
            polygonList = []
            polygonLabels = []
        },
        clearMap() {
            map && map.remove(markerList)
            map && map.remove(labelList)
            map && map.remove(polygonList)
            map && map.remove(polygonLabels)
            map && map.remove(lineList)
            map && map.remove(passedLineList)
            map && map.remove(animarkerList)
            map && map.remove(aniLabelList)
            map && map.remove(bfplistmarker)
            map && imageLayer && map.remove(imageLayer)

            /* 1.4.15 */
            // map && heatmap && map.remove(heatmap)
            /* 2.0 */
            map && heatmap && heatmap.setMap(null)
            map && ovdhHeatmap && ovdhHeatmap.setMap(null)

            clearTimeout(timeout1)
            clearTimeout(timeout2)
            timeout1 = null
            timeout2 = null

            if (addMarkerAniInterval) {
                clearInterval(addMarkerAniInterval)
                addMarkerAniInterval = undefined
            }
            if (delMarkerAniInterval) {
                clearInterval(delMarkerAniInterval)
                delMarkerAniInterval = undefined
            }
            if (pathLayerList.length > 0) {
                pathLayerList.forEach(item => {
                    loca.remove(item)
                })
            }
            if (lyxf) {
                lyxf.hide()
            }
            if (tscy) {
                tscy.hide()
            }
            markerList = []
            labelList = []
            polygonList = []
            polygonLabels = []
            lineList = []
            pathLayerList = []
            passedLineList = []
            animarkerList = []
            sourceAnimarkerList = []
            aniLabelList = []
            bfplistmarker = []
            imageLayer = null
            heatmap = null
            ovdhHeatmap = null
            this.removeAlldcOBJS()
            this.isDcFlag = false
            map &&
                map.setStatus({
                    dragEnable: true,
                    keyboardEnable: true,
                    scrollWheel: true
                })
            if (
                currType !== 'iw' ||
                legendType !== 'zndg' ||
                this.$route.name !== 'iw'
            ) {
                this.$eventBus.$emit('ldpop-demo', false)
            }
            if (infoWindow || this.infoWindow) {
                map.clearInfoWindow()
            }
            infoWindow = null
        }
    },
    beforeDestroy() {
        map && map.remove(roadTrafficLayer)
        roadTrafficLayer = null
        map.destroy()
        loca.destroy()
    }
}
</script>
<style lang="scss">
#bfpimgdiv {
    p {
        img {
            width: 300px;
            height: 250px;
        }
    }
}
#strBox {
    color: #ffcd05;
    // color: #e4a90b;
    text-align: center;
}
</style>
