<template>
  <div class="iw-popInfo" :style="{width,height,opacity}" style='background: rgba(11,20,19,0.8); ;border: 1px solid  #00FFF7 ;transform: translate(30px,40%);'>
      <div class="box" style='width: 100%;height: 100%;'>
        <div class='tools'>
          <div class='title' >
            <span class='text'>客流监控</span>
            <img style="width:30px;height:30px;" src="/images/components/std/del-n.png" alt="" onclick="closeInfoWindow()"/>
          </div>
        </div>
        <div class='content' style='display: flex;width: 100%;flex-direction: column;font-size: 18px;'>
          <div class='item' style='flex:1;margin-left: 20px;'>{{data.deviceName}}</div>
          <div class='item' style='flex:1;margin-left: 20px;display: flex;align-items: center;'>
            <img style="width:17px;height:17px;margin-right:10px;" src="/images/components/std/pos.png" alt="" />
            {{data.deviceLocation}}</div>
          <div class='item' style='flex:1;margin-left: 20px;'>当前客流 <span style='color:#00FCFF;margin: 0 5px;font-size: 20px'>{{data.passengerFlow}}</span>  人</div>

          <div class='item' style='flex:4;margin-left:0 ;width: 95%;margin: 0 auto'>
<!--            <img src='/images/components/iw/l1.png' width='300px' height='150px' alt=''>-->

            <div  class='videoContainer' style='height: 320px;width: 100%'>
              <div  class="iframe-box">
<!--                <img :src="data.img" width='300px' height='130px' alt=''>-->
                <iframe
                  :src="data.cameraUrl"
                  align="middle"
                  frameborder="0"
                  allowfullscreen
                  referrerPolicy="no-referrer"
                ></iframe>
              </div>

              <div class='videoFooter' style='height: 30px'>
                <div class='videoName'>{{data.deviceName}}</div>
                <div class="videoFull" @click='videoFull()'> </div>
              </div>

          </div>

          </div>
          <div class='item' style='flex:1;margin-left: 27px;'>客流趋势 / 人</div>
        </div>
        <div class="chart-box1" style='width:90%;height: 20%;padding: 10px;margin: 0 auto'>
          <ek-chart
            style="width: 100%;height: 100%"
            v-if="options.series[0].data"
            :options="options "
             :animation="true"
             :animationData="options.xAxis.data"
          />
          <ek-empty v-else></ek-empty>
        </div>
      </div>
  </div>
</template>


<script>
import * as echarts from 'echarts'
// import chartLegend from './chartLegend'

export default {
  data() {
    return {
      data: {},
      opacity: 0.1,
      empty: false,
      options: {
        grid: {
          top: '16%',
          left: '10%',
          right: '3%',
          bottom: '15%'
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff',
            fontSize: 16
            /* formatter: val => {
              let { holidayVal, dateVal } = this.formData
              let unit = holidayVal
                ? '日'
                : dateVal == 0
                  ? '时'
                  : dateVal == 1
                    ? '日'
                    : dateVal == 2
                      ? '月'
                      : ''
              let date = this.dayjs(val).format(
                holidayVal
                  ? 'DD'
                  : dateVal == 0
                  ? 'HH'
                  : dateVal == 1
                    ? 'DD'
                    : 'MM'
              )
              return date + unit
            }*/
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC'
            }
          },
          // 不显示刻度线
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#2973AC',
              type: [3, 5]
            }
          },
          data: []
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC',
              type: [3, 5]
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 16,
            formatter: function(val) {
              return val
            }
          }
        },
        series: [
          {
            name: '客流量',
            type: 'line',
            symbol: 'none',
            lineStyle: {
              color: '#fff'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#00FCFF' // 0% 处的颜色
                },
                {
                  offset: 0.5,
                  color: '#00ff8472' // 100% 处的颜色
                },
                {
                  offset: 1,
                  color: '#142D3F' // 100% 处的颜色
                }
              ])
            },
            data: [
              502.84,
              205.97,
              332.79,
              281.55,
              398.35,
              214.02,
              502.84,
              205.97,
              332.79,
              281.55,
              398.35,
              214.02,
              502.84,
              205.97,
              332.79,
              281.55,
              398.35,
              214.02,
              502.84,
              205.97,
              332.79,
              281.55,
              398.35,
              214.02
            ]
          }
        ]
      }
    }
  },
  props: {
    width: {
      type: String,
      default: '560px'
    },
    height: {
      type: String,
      default: '700px'
    }
  },
  components: {
    // chartLegend
  },
  computed: {
    calOptions: function() {
      let that = this
      return function(val) {
        // console.log(this.data.parkForecastModelList,this.data.picUrl,'ppppppppppppppppppp')
        that.options.xAxis.data = val.pfDate
        that.options.series[0].data = val.pfData
        return that.options
      }
    }
  },
  mounted() {},
  methods: {
    closeInfoWindow() {
      window.closeInfoWindow()
    },
    videoFull() {
      // console.log(e)
      console.log(this.data)

      this.$eventBus.$emit('dev-afjk', {
        url: this.data.cameraUrl,
        isFull: false
      })
      // this.$eventBus.$emit(
      //   'dev-afjk1',
      //   // e.url?e.url:''
      //   ''
      // )
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  @include flex(column);
  .tools {
    @include flex(column);
    .title {
      height: 50px;
      //width: 100%;
      background: rgba(7, 64, 68, 0.5);
      border-bottom: 1px solid #00fff7;
      line-height: 50px;
      align-items: center;
      width: 100%;
      .text {
        padding-left: 20px;
        font-size: 26px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #00ffff;
      }
      img {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
    }
  }
  .content {
    flex: 1;
    .item {
      display: flex;
      align-items: center;
    }
  }
  .chart-box1 {
    //flex: 1;
    //position: relative;
    //@include flex();
    //justify-content: center;
  }
}
.item {
  margin-left: 30px;
}
.iframe-box {
  &::-webkit-scrollbar {
    display: none;
  }
  overflow: hidden;
}
</style>
