<template>
  <div class="activity-detail" v-if="visible">
    <div class="mask" @click="close"></div>
    <div class="box">
      <div class="i-box" style="width: 100%;height: 100%">
        <div class="t-close" @click="close">
          <img src="/images/map/ov/br/desc-close.png" alt="" />
        </div>
        <div class="t-box" style="height:100%;width:100%;">
          <div class="t-title">天桥监控列表</div>

         <div class="t-body" style="display:flex;flex-direction:column;height:calc(100% - 110px);width:100%;flex-wrap:wrap;">
          
            <div v-for="(item, index) in list" :key="index" class="box1" >
              <div class="t-header" v-if="item.title">{{ item.title }}({{item.num }})</div>
              <div v-else class="t-content">
                <div class="t-item">{{ item.ii }} .</div>
                 <div class="t-item">{{ item.name }}</div>
                 <img
                   class="t-itemBF"
                   width="40px"
                   height="40px"
                   src="/images/components/std/bf-icon.png"
                   alt=""
                   @click="videoFull(item)"
                 />
              </div>
              
            </div>
          </div>
        
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { map_dev_marker } from '@/api/other'
import { _axios } from '@/utils/request'

export default {
  data() {
    return {
      visible: false,
      eelist: [],
      kllist: [],
      aflist: [],
      list:[],
      eeindex:0,
      klindex:0,
      afindex:0
    }
  },
  props: {
    width: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.eeindex=0,
      this.klindex=0,
      this.afindex=0
    this.fetchStdAll()
  },
  methods: {
    async fetchData() {
      const { resultCode, resultData } = await map_dev_marker({ source: '1' })
      if (resultCode === '200' && resultData) {
        console.log(resultData, 'deviceList')
        // for(let item of )
        return resultData
      }
    },
    async fetchStdAll() {
  
      await this.fetchStdData(0, '10')
      await this.fetchStdData(1, '20')
      await this.fetchStdData(2, '30')
      await this.fetchStdData(3, '40')
      await this.fetchStdData(4, '50')
      // this.stdList = [...l1, ...l2, ...l3, ...l4, ...l5]
    },
    async fetchStdData(index, curServerArea) {
      // const temList = []
      const { resultCode, resultData } = await _axios.post(
        '/stat/deviceInfo/deviceListBySource/',
        {
          source: '2',
          businessScenario: curServerArea
        }
      )
      if (resultCode === '200' && resultData) {
        // console.log(resultData, '设备列表')
        resultData.map(item => {
          if (item.deviceLocation.indexOf('天桥') !== -1) {
            if(item.isDisplay && Number(item.isDisplay) === 0) return
            // console.log(item.deviceLocation, item.deviceName, item.deviceType,item.isDisplay)
            if (item.deviceType === 'ee_camera') {
         
            this.eeindex++
              this.eelist.push({
                name: item.deviceName,
                url: item.cameraUrl,
                ii:this.eeindex,
                type:'gdyy'
              })
             
            } else if (item.deviceType === 'pf_camera') {
              //kl
             this.klindex++
              this.kllist.push({
                name: item.deviceName,
                url: item.cameraUrl,
                 ii:this.klindex,
                 type:'kljk'
              })
             
            } else if (item.deviceType == 'se_camera') {
               this.afindex++
              this.aflist.push({
                name: item.deviceName,
                url: item.cameraUrl,
                ii:this.afindex,
                type:'afjk'
              })
            
            }
          }
        })
        
        this.list=[{title:"鹰眼监控",num:this.eelist.length},...this.eelist,{title:"客流监控",num:this.kllist.length},...this.kllist,{title:"安防监控",num:this.aflist.length},...this.aflist]

        // console.log(this.list,this.afindex,'sss')
      } else {
        console.log('无数据')
      }
    },
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    videoFull(e) {
      console.log(e)
      if (!e) {
        this.$message('暂无数据')
        return
      }
      let isfull = false
      if(e.type == 'gdyy') {
        isfull = true
      }
      this.$eventBus.$emit('dev-afjk', {
        url: e.url,
        isFull: isfull
      })
    }
  }
}
</script>

<style scoped lang="scss">
.activity-detail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;

  @include flex();
  align-items: center;
  justify-content: center;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba($color: #000000, $alpha: 0.8);
  }
  .box {
    z-index: 11;
    max-height: 90%;
    @include flex();
    width: 2270px;
    height: 990px;
    background: rgba(6, 23, 25, 0.7);
    border: 1px solid #ef9c00;
    border-radius: 10px;
    position: relative;
  }
}

    .t-title {
      //position: relative;
      //position: absolute;
      margin-left: 41px;
      margin-top: 40px;
      width: 186px;
      height: 30px;
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ef9c00;
      margin-bottom: 40px;
    }

    .t-close {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 25px;
      height: 25px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .t-body {
      width: 390px;
      margin-left: 40px;
        
       
      //float: left;
      //display: flex;
      //flex-direction: column;
      //flex-wrap: wrap;
      
      .t-item {
          margin-left: 32px;
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          line-height: 30px;
          
        }
        .t-itemBF {
          position: absolute;
          right: 30px;
          cursor: pointer;
        }
      .t-content {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 495px;
        height: 70px;
        position: relative;
        align-items: center;

     
      }
    }
    .box1{
        &:nth-child(odd) .t-content {
          // width: 390px;
          // width: 390px;
          // height: 70px;
          background: rgba(8, 74, 80, 0.7);
        }
        &:nth-child(even) .t-content {
          // width: 390px;
          // height: 70px;
          background: rgba(4, 48, 52, 0.7);
        }
    }
    .t-header {
        width: 390px;
      
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        // background: rgba(6, 23, 25, 0.7) !important;
        line-height: 70px;
     
      }
</style>
