import * as THREE from 'three'
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';

let wandaGeo, wandalinkGeo, wandaOtherGeo, otherArray
let stageStepTime = 2000


var map, loca, Loca, AMap

var mapLists = []
let locaLists = []
let yunMarkers = []


let mapLists01=[]
let locaLists01=[]
let mapLists02=[]
let locaLists02=[]
let mapLists03=[]
let mapLists0302=[]
let locaLists03=[]
// let tmeshes=[]
var geo, linkGeo, otherGeo

let wdPt = [[120.355461,36.082757]]
let wdotherArray = [
    [120.354213, 36.082058],
    [120.35586, 36.082399],
    [120.355248, 36.083185]
]

let qpPt = [[120.348745,36.079005]]
let qpotherArray = [
    [120.35018,36.080601],
    [120.346513,36.079967],
    [120.349601,36.078]
]
let ddPt = [[120.354027,36.083619]]
let ddotherArray = [
    [120.352426,36.084865],//景观天桥
    [120.354981,36.084569],
    [120.352498,36.082806],
]
let op
let yunPt = [[120.353482, 36.080472]]
//第一步需要单独清除三角形，额外添加更多线，锁定鼠标
//第二步清除第一步物体，添加更多线条
let S3MainPoint=[
    [120.320874,36.065104],//栈桥
    [120.350783,36.082574],//台东
    [120.320602,36.083791]//游轮母港
]
let lcMainPoint=[
    [120.353272,36.100231]
]
let zqohterPoints=[
    [120.319292,36.061798], //栈桥
    [120.315199,36.06369], //青岛站
    [120.321756,36.065839], //老舍公园
    [120.320635,36.068166],//圣弥厄尔教堂
]
let tdohterPoints=[
    [120.353935,36.083397],//台东步行街
    [120.356051,36.078945],//花鸟鱼市市场
    [120.35271,36.089092], //如家酒店
    [120.348683,36.081173], //青啤街
   [120.350305,36.080591] //大悦城
]
let zqtexts =['栈桥','青岛站','老舍公园','圣弥厄尔教堂']
let tdtexts =['台东步行街','花鸟鱼石市场','如家酒店','青啤街','大悦城']

let tddescrip =
    ["青啤博物馆 ，品刚下线的鲜啤原浆",
    "游览台东夜市 ，尽享网红美食",
    "营口路市场海鲜DIY，尝地道海鲜",]
    let zqdescrip =
    [
       " 大鲍岛体验里院风情，入住特色民宿",
        "酒吧电音节，开启夜场生活",
        "波螺油子转一转，找街坊烟火美食",
    ]
    let yldescrip =
    [
        "筒仓书店，参加樊登主题读书会",
"集装箱部落，赶海鲜美食大集",
"唆了蜜基地，开启露营晚会",
    ]

let wdtextls=[
    '扫码点餐','领优惠券','导览','缴费','互动','停车','线下活动'
]
let zqtextls=[
    '互动','停车','线下活动','扫码点餐','领优惠券','导览','缴费','互动','停车','线下活动'
]
let yltextls=[
    '缴费','互动','停车','线下活动','扫码点餐','领优惠券','导览'
]
let triangleObj = null


var matline
var customCoords
var camera;
var renderer;
var scene;

export default {
    data() {
        return {
            isDcFlag: false,
            yun1flag:false,
            yun2flag:false,
            yun3flag:false
        }
    },
    mounted() {
        let that = this
        setTimeout(() => {
            map = window.map
            loca = window.loca
            Loca = window.Loca
            AMap = window.AMap
            loca.animate.start();
            that.initData()
            that.initScene()
            that.animate()
        }, 5000)
    },
    methods: {
        getDcStage01() {
            let that = this
            that.yun1flag = false
            that.yun2flag = false
            that.yun3flag = false
            this.curDCstage ='1'
            this.removeAlldcOBJS()
            wandaGeo = this.createPointGeo(wdPt)
            wandalinkGeo = this.createLinkGeo(wdPt, wdotherArray)
            setTimeout(that.animateS11)
            // that.animateS31() 
        },
        getDcStage02() {
            console.log('第2步')
           
               this.yun1flag = false
               this.yun2flag = false
               this.yun3flag = false
            
            this.animateS2yun()
            this.curDCstage ='2'
       
        },
        clearS01(){
            mapLists01.forEach(item=>{
                map.remove(item)
            })
            locaLists01.forEach(item=>{
                loca.remove(item)
                }
            )
            // loca.clear()
        },
        getDcStage03() {
            console.log('第3步')
       
            this.curDCstage ='3'
            this.animateS31()
        },
        getDcStage04(){
            this.curDCstage ='4'
            this.animateS4()
        },
        animateS11() {
            // addCanvasLayer([120.355066,36.082302],
            //     [120.355023,36.082684])
            let that = this
            // 镜头动画
            map.setZoom(16.77, true);
            map.setPitch(47.24716321414439, true);
            map.setCenter([120.3502, 36.083508], true);
            map.setRotation(42.44320118885079, true);

            setTimeout(()=>{
                triangleObj = that.createTopBottomZmarker(wandaGeo, './images/map/dc/triangle_blue.png', {
                    size: [80, 130],
                    altitude: 125,
                    transform: 800,
                    width: '120px',
                    height: '120px'
                })
                locaLists01.push(triangleObj)
    
    
                locaLists01.push(that.createScatter(wandaGeo, {
                    unit: 'meter',
                    size:[220,220],
                    texture: './images/map/dc/scan_yellow.png',
                    duration: 1500,
                    animate: true,
                }))
    
                let a = that.createDCMarker([120.355461,36.082757], '步行街', new AMap.Pixel(100, -10))
                mapLists01.push(a)
                setTimeout(() => {
                    locaLists01.push(that.createLasterLine(wandaGeo, {
                        unit: 'meter',
                        height: 150,
                        color: '#C7FBFE',
                        lineWidth: 7,
                        trailLength: 15,
                        angle: 0,
                        repeat: Infinity,
                        // delay: () => {
                        //     return Math.random() * 2000;
                        // },
                    }))
                    locaLists01.push(that.createLasterLine(wandaGeo, {
                        unit: 'meter',
                        height: 200,
                        color: '#42FCFF',
                        lineWidth: 7,
                        trailLength: 3500,
                        repeat: Infinity,
                        angle: 0
                    }))
                }, 700)
            },1000)
            loca.viewControl.addAnimates([{
                    center: {
                        value: [120.35543, 36.08239], //重点
                        control: [[120.35543, 36.08239],
                            [120.35543, 36.08239]],
                        timing: [0.5, 0.5, 0.5, 1],
                        duration: 3000,
                    },
                    zoom: {
                        value: 19.3,
                        control: [[16.77, 19.3], [16.77, 19.3]],
                        timing: [0, 0, 0.8, 1],
                        duration: 3000,
                    },
                    rotation: {
                        value: 20.843201188850795,
                        control: [[20.843201, 20.843201], [20.843201, 20.843201]],
                        timing: [0, 0, 0.7, 1],
                        duration: 3000,
                    },
                    pitch: {
                        value: 57.913829880811,
                        control: [[57.91382, 57.91382], [57.913821, 57.91382]],
                        timing: [0.3, 0, 1, 1],
                        duration: 3000,
                    },
                }],
                function () {
                    //添加点周边标签
                    wandaOtherGeo = that.createPointGeo(wdotherArray)
                    // createImageMarker(wdPt[0],'./images/map/dc/blue-bg.png',{width:'96px',height:'108px'})
                    locaLists01.push(that.createTopBottomZmarker(wandaGeo, './images/map/dc/blue-bg.png', {
                        size: [56, 58],
                        altitude: -6,
                        transform: 8,
                        width: '350px',
                        height: '350px',
                        random: false,
                        alwaysFront: true
                    }))


                    setTimeout(() => {

                        locaLists01.push( that.createPulseLinkLine(wandalinkGeo, {
                            unit: 'meter',
                            dash: [1, 0, 0, 0],
                            lineWidth: function () {
                                return [3.5, 1.5];
                            },
                            height: 50,
                            altitude: 100,
                            smoothSteps: 20,
                            speed: 20,
                            flowLength: 10,
                            lineColors: ['rgb(100,255,242)', 'rgb(80,249,255)', 'rgb(75,253,247)', 'rgba(57,214,232,1)', 'rgb(191,242,255)'],
                            maxHeightScale: 0.5, // 弧顶位置比例
                            headColor: 'rgb(236,255,167)',
                            trailColor: 'rgb(238,248,99)',
                        }))
                        wdotherArray.forEach((item,index) => {
                           mapLists01.push( that.createImageMarker(item, './images/map/dc/triangle-yellow.png', {
                                width: '16px',
                                height: '25px',
                                offset: new AMap.Pixel(0, 0),
                                class: 'bounce-item'
                            }))
            
                            mapLists01.push( that.createDCTextMarkerS1(item, wdtextls[index]))
                        })
                        locaLists01.push(that.createScatter(wandaOtherGeo, {
                            unit: 'meter',
                            size: [50, 50],
                            texture: './images/map/dc/green.png',
                            duration: 2000,
                            animate: true,
                        }))
                    }, 1000)
                    loca.viewControl.addAnimates([{
                            center: {
                                value: [120.35579, 36.082625], //重点
                                control: [[120.35579, 36.082625],
                                    [120.35579, 36.082625]],
                                timing: [0.5, 0.5, 0.5, 1],
                                duration: 2500,
                            },
                            zoom: {
                                value: 18.39,
                                control: [[18.39, 18.39], [18.39, 18.39]],
                                timing: [0, 0, 0.8, 1],
                                duration: 2500,
                            },
                            rotation: {
                                value: 9.843201188842418,
                                control: [[9.843201188842418, 9.843201188842418], [9.843201188842418, 9.843201188842418]],
                                timing: [0, 0, 0.7, 1],
                                duration: 2500,
                            },
                            pitch: {
                                value: 57.151925118905396,
                                control: [[57.151925118905396, 57.151925118905396], [57.151925118905396, 57.151925118905396]],
                                timing: [0.3, 0, 1, 1],
                                duration: 2500,
                            },
                        }],
                        function () {

                            let op = {
                                center: [120.35513, 36.083709],
                                zoom: 17.03,
                                rotation: 14.443201188842838,
                                pitch: 59.34240130938158,
                                timing: 2500
                            }
                            loca.viewControl.addAnimates([{
                                center: {
                                    value: op.center, //重点
                                    control: [op.center,
                                        op.center],
                                    timing: [0.5, 0.5, 0.5, 1],
                                    duration: op.timing,
                                },
                                zoom: {
                                    value: op.zoom,
                                    control: [[16.87, 17.03], [16.87, 17.03]],
                                    timing: [0, 0, 0.8, 1],
                                    duration: op.timing,
                                },
                                rotation: {
                                    value: op.rotation,
                                    control: [op.rotation, op.rotation],
                                    timing: [0, 0, 0.7, 1],
                                    duration: op.timing,
                                },
                                pitch: {
                                    value: op.pitch,
                                    control: [[op.pitch, op.pitch], [op.pitch, op.pitch]],
                                    timing: [0.3, 0, 1, 1],
                                    duration: op.timing,
                                },
                            }])

                            console.log('万达结束')

                            setTimeout(() => {
                                that.animateS12()
                            }, stageStepTime)

                        });
                });
        },
        animateS12() {
            let that = this
            geo = this.createPointGeo(qpPt)
            linkGeo = this.createLinkGeo(qpPt, qpotherArray)
            // 青啤镜头动画
            let op = {
                center: [120.345596, 36.080962],
                zoom: 17.48,
                rotation: 43.94320118884404,
                pitch: 66.00906797604836,
                timing: 2500
            }
           locaLists01.push (this.createTopBottomZmarker(geo, './images/map/dc/blue-bg.png', {
                size: [56, 58],
                altitude: 0,
                transform: 8,
                width: '350px',
                height: '350px',
                random: false,
                alwaysFront: true
            }))
            setTimeout(() => {
              locaLists01.push ( this.createLasterLine(geo, {
                    unit: 'meter',
                    height: 150,
                    color: '#C7FBFE',
                    lineWidth: 10,
                    trailLength: 15,
                    angle: 0,
                    repeat: Infinity,
                    delay: () => {
                        return Math.random() * 2000;
                    },
                }))
              locaLists01.push ( this.createLasterLine(geo, {
                    unit: 'meter',
                    height: 300,
                    color: '#42FCFF',
                    lineWidth: 10,
                    trailLength: 3500,
                    repeat: Infinity,
                    angle: 0
                }))
            }, 1500)
            //添加点周边标签
            otherGeo = this.createPointGeo(qpotherArray)
            otherArray = qpotherArray
            // createImageMarker(geo,'./images/map/dc/blue-bg.png',{width:'156px',height:'158px'})
            setTimeout(() => {
               // mapLists01.push( this.addCanvasLayer([ [120.346971, 36.079416],[120.348257, 36.080348]]) )// 青啤
                locaLists01.push(this.createScatter(geo, {
                    unit: 'meter',
                    size:[220,220],
                    texture: './images/map/dc/scan_yellow.png',
                    duration: 2000,
                    animate: true,
                }))

                mapLists01.push (this.createDCMarker(qpPt[0], '啤酒街', new AMap.Pixel(100, -10)))

              locaLists01.push ( this.createPulseLinkLine(linkGeo, {
                    unit: 'meter',
                    dash: [1, 0, 0, 0],
                    lineWidth: function () {
                        return [3.5, 1.5];
                    },
                    height: 50,
                    // altitude: 100,
                    smoothSteps: 20,
                    speed: 20,
                    flowLength: 10,
                    lineColors: function () {
                        return ['rgb(100,255,242)', 'rgb(80,249,255)', 'rgb(75,253,247)', 'rgba(57,214,232,1)', 'rgb(191,242,255)'];
                    },
                    maxHeightScale: 0.5, // 弧顶位置比例
                    headColor: 'rgb(236,255,167)',
                    trailColor: 'rgb(238,248,99)',
                }))
            }, 2000)
            setTimeout(() => {
                otherArray.forEach((item,index) => {
                    mapLists01.push(this.createImageMarker(item, './images/map/dc/triangle-yellow.png', {
                        width: '16px',
                        height: '25px',
                        offset: new AMap.Pixel(0, 0),
                        class: 'bounce-item'
                    }))
            
                    mapLists01.push( that.createDCTextMarkerS1(item, zqtextls[index]))
                })
                
               locaLists01.push (this.createScatter(otherGeo, {
                    unit: 'meter',
                    size: [50, 50],
                    texture: './images/map/dc/green.png',
                    duration: 2000,
                    animate: true,
                }))
            }, 2500)
            loca.viewControl.addAnimates([{
                    center: {
                        value: op.center, //重点
                        control: [op.center,
                            op.center],
                        timing: [0.5, 0.5, 0.5, 1],
                        duration: op.timing,
                    },
                    zoom: {
                        value: op.zoom,
                        control: [[op.zoom, op.zoom], [op.zoom, op.zoom]],
                        timing: [0, 0, 0.8, 1],
                        duration: op.timing,
                    },
                    rotation: {
                        value: op.rotation,
                        control: [op.rotation, op.rotation],
                        timing: [0, 0, 0.7, 1],
                        duration: op.timing,
                    },
                    pitch: {
                        value: op.pitch,
                        control: [[op.pitch, op.pitch], [op.pitch, op.pitch]],
                        timing: [0.3, 0, 1, 1],
                        duration: op.timing,
                    },
                }],
                function () {
                    setTimeout(() => {
                        that.clearStep1Obj()
                        that.animateS13()
                    }, 1000)
                });
        },
        clearStep1Obj(){
            if(triangleObj){
                loca.remove(triangleObj)
            }

        },
        animateS13() {
            let that = this
            geo = this.createPointGeo(ddPt)
            linkGeo = this.createLinkGeo(ddPt, ddotherArray)
          locaLists01.push(  this.createTopBottomZmarker(geo, './images/map/dc/blue-bg.png', {
                size: [56, 58],
                altitude: 0,
                transform: 8,
                width: '350px',
                height: '350px',
                random: false,
                alwaysFront: true
            }))
            setTimeout(() => {
              locaLists01.push  (this.createLasterLine(geo, {
                    unit: 'meter',
                    height: 150,
                    color: '#C7FBFE',
                    lineWidth: 10,
                    trailLength: 15,
                    angle: 0,
                    repeat: Infinity,
                    delay: () => {
                        return Math.random() * 2000;
                    },
                }))
               locaLists01.push( this.createLasterLine(geo, {
                    unit: 'meter',
                    height: 300,
                    color: '#42FCFF',
                    lineWidth: 10,
                    trailLength: 3500,
                    repeat: Infinity,
                    angle: 0
                }))
            }, 1500)
            //添加点周边标签
            otherGeo = this.createPointGeo(ddotherArray)
            otherArray = ddotherArray
            // createImageMarker(ddPt[0],'./images/map/dc/blue-bg.png',{width:'96px',height:'108px'})

            setTimeout(() => {
                locaLists01.push(this.createScatter(geo, {
                    unit: 'meter',
                    size:[220,220],
                    texture: './images/map/dc/scan_yellow.png',
                    duration: 2000,
                    animate: true,
                }))
               mapLists01.push( this.createDCMarker(ddPt[0], '天桥', new AMap.Pixel(100, -10)))
               locaLists01.push( this.createPulseLinkLine(linkGeo, {
                    unit: 'meter',
                    dash: [1, 0, 0, 0],
                    lineWidth: function () {
                        return [3.5, 1.5];
                    },
                    height: 150,
                    altitude: 100,
                    smoothSteps: 20,
                    speed: 20,
                    flowLength: 10,
                    lineColors: function () {
                        return ['rgb(100,255,242)', 'rgb(80,249,255)', 'rgb(75,253,247)', 'rgba(57,214,232,1)', 'rgb(191,242,255)'];
                    },
                    maxHeightScale: 0.5, // 弧顶位置比例
                    headColor: 'rgb(236,255,167)',
                    trailColor: 'rgb(238,248,99)',
                }))
            }, 1500)
            setTimeout(() => {
                otherArray.forEach((item,index) => {
                    mapLists01.push(this.createImageMarker(item, './images/map/dc/triangle-yellow.png', {
                        width: '16px',
                        height: '25px',
                        offset: new AMap.Pixel(0, 0),
                        class: 'bounce-item'
                    }))
            
                    mapLists01.push( that.createDCTextMarkerS1(item, yltextls[index]))
                })
               
                locaLists01.push(this.createScatter(otherGeo, {
                    unit: 'meter',
                    size: [50, 50],
                    texture: './images/map/dc/green.png',
                    duration: 2000,
                    animate: true,
                }))
            }, 2500)
            // 镜头动画
            let op = {
                center: [120.352435, 36.084615],
                zoom: 17.48,
                rotation: 26.143018947649363,
                pitch: 66.9614489284293,
                timing: 2500
            }
            loca.viewControl.addAnimates([{
                    center: {
                        value: op.center, //重点
                        control: [op.center,
                            op.center],
                        timing: [0.5, 0.5, 0.5, 1],
                        duration: op.timing,
                    },
                    zoom: {
                        value: op.zoom,
                        control: [[op.zoom, op.zoom], [op.zoom, op.zoom]],
                        timing: [0, 0, 0.8, 1],
                        duration: op.timing,
                    },
                    rotation: {
                        value: op.rotation,
                        control: [op.rotation, op.rotation],
                        timing: [0, 0, 0.7, 1],
                        duration: op.timing,
                    },
                    pitch: {
                        value: op.pitch,
                        control: [[op.pitch, op.pitch], [op.pitch, op.pitch]],
                        timing: [0.3, 0, 1, 1],
                        duration: op.timing,
                    },
                }],
                function () {
                    console.log('结束第一个，开始第二个，添加云平台')
                    setTimeout(() => {
                        that.animateS1yun()
                    }, stageStepTime)


                });
        },

        animateS1yun() {
            this.clearStep1Obj()
            let that = this
            geo = this.createPointGeo(ddPt)
            linkGeo = this.createLinkGeo(ddPt, ddotherArray)
       
            let m1 = this.createImageMarker(yunPt[0], './images/map/dc/yun-pingtai.png', {
                width: '169.0px',
                height: '105px',
                offset: new AMap.Pixel(0, -400)
            })
            let m2 = this.createImageMarker(yunPt[0], './images/map/dc/yun-dizuo.png', {
                class: 'shadow-item',
                width: '270px',
                height: '100px',
                offset: new AMap.Pixel(0, -380)
            })
            yunMarkers.push(m1)
            yunMarkers.push(m2)
            setTimeout(() => {
               
                that.yun1flag  = true 
                that.yun2flag = false
                that.yun3flag = false
            let center = this.yun1center
            let others = this.yun1others
            
            that.addCurveLines(center,others, {
                height:500,
                mainheadColor:'#00ffff',
                maintailColor: '#326af6',
                otherheadColor:'#505ADB',
                othertailColor:'#52AFF7',
                linewidth:[8,8,8,
                         5,5,5,2,2,2,2,2,2,2,2,2]
            })
                // locaLists01.push(yunPulseLink)
                // locaLists.push(yunPulseLink)
            }, 1500)
            // 镜头动画
            let op = {
                center: [120.351887,36.082483],
                zoom: 16.77,
                rotation: 45.641220183035934,
                pitch:63.137273104254284,
                timing: 3000
            }
            loca.viewControl.addAnimates([{
                    center: {
                        value: op.center, //重点
                        control: [op.center,
                            op.center],
                        timing: [0.5, 0.5, 0.5, 1],
                        duration: op.timing,
                    },
                    zoom: {
                        value: op.zoom,
                        control: [[op.zoom, op.zoom], [op.zoom, op.zoom]],
                        timing: [0, 0, 0.8, 1],
                        duration: op.timing,
                    },
                    rotation: {
                        value: op.rotation,
                        control: [op.rotation, op.rotation],
                        timing: [0, 0, 0.7, 1],
                        duration: op.timing,
                    },
                    pitch: {
                        value: op.pitch,
                        control: [[op.pitch, op.pitch], [op.pitch, op.pitch]],
                        timing: [0.3, 0, 1, 1],
                        duration: op.timing,
                    },
                }],
                function () {
                    setTimeout(() => {
                        // animateS31()
                       that.dcStage2Flag = true
                       that.dcStage1Flag = false
                       that.dcStage3Flag = false
                       that.dcStage4Flag = false
                    })


                });

        },
        animateS2yun() {
            let that = this
            this.addStage02Yun()
            let width = '台东步行街'.length * 50
            mapLists02.push(that.createTextMarker(S3MainPoint[1],'台东步行街',{width:width,height:'58px'})) 
            width = '邮轮母港'.length * 50
            mapLists02.push(that.createTextMarker(S3MainPoint[2],'邮轮母港',{width:width,height:'58px'}))
            
            width = '历史古城区'.length * 50
            mapLists02.push(that.createTextMarker(S3MainPoint[0],'历史古城区',{width:width,height:'58px'}))
            
            this.addDCBG(`./images/map/dc/yun-noline1.png`)
            setTimeout(()=>{
                this.clearS01()

            },1000)
            let op = {
                center: [120.333987,36.088616],
                zoom: 14.81,
                rotation:-1.2389214168520657,
                pitch:70.61538461538441,
                timing: 1500
            }
            loca.viewControl.addAnimates([{
                center: {
                    value: op.center, //重点
                    control: [op.center,
                        op.center],
                    timing: [0.5, 0.5, 0.5, 1],
                    duration: op.timing,
                },
                zoom: {
                    value: op.zoom,
                    control: [[op.zoom, op.zoom], [op.zoom, op.zoom]],
                    timing: [0, 0, 0.8, 1],
                    duration: op.timing,
                },
                rotation: {
                    value: op.rotation,
                    control: [op.rotation, op.rotation],
                    timing: [0, 0, 0.7, 1],
                    duration: op.timing,
                },
                pitch: {
                    value: op.pitch,
                    control: [[op.pitch, op.pitch], [op.pitch, op.pitch]],
                    timing: [0.3, 0, 1, 1],
                    duration: op.timing,
                },
            }],function(){
                that.dcStage3Flag = true
                that.dcStage1Flag = false
                that.dcStage2Flag = false
                that.dcStage4Flag = false
            })
        },
        addStage02Yun() {
            // let yunpt=[[120.329873,36.088175]]
            let that = this
            let moveTime = 700
            yunMarkers.forEach(mark => {
                // mark.setPosition(new AMap.LngLat(120.331728,36.074966))
                this.startAnimation(mark, [yunPt[0], [120.331728, 36.074966]], moveTime)
            })
            setTimeout(() => { 
                that.yun1flag = false
                that.yun2flag = true
                that.yun3flag = false
                let center = that.yun2center
            let others = that.yun2others
            
            that.addCurveLines(center,others, {
                height:1700,
                mainheadColor:'#00ffff',
                maintailColor: '#326af6',
                otherheadColor:'#246996',
                othertailColor:'#52AFF7',
                linewidth:[8,8,8,
                         5,5,5,5,5,5,5,5,5,5,5,5,
                        2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2]
            })
            }, moveTime + 300)
        },
        
        animateS31() {
            let that = this
            this.removeMarker()
            this.addS3tdOBJS()
            that.yun1flag = false
            that.yun2flag = false
            that.yun3flag = false
            setTimeout(()=>{
                let width = '台东步行街美食住宿'.length * 50
                this.createTextMarker(S3MainPoint[1],'台东步行街美食住宿',{width:width,height:'58px'})
                mapLists0302.push(this.createDCBoardMarker(S3MainPoint[1], tddescrip)) 
            },600)
            geo = this.createLineGeo([S3MainPoint[1],
                S3MainPoint[0]])

            setTimeout(()=>{
                this.drawDynamicLineDC(geo,{
                    altitude: 0,
                    lineWidth:7,
                    // 脉冲头颜色
                    headColor:'rgba(255,234,124,0.0)',
                    // 脉冲尾颜色
                    trailColor: '#ffea7c',
                    // 脉冲长度，0.25 表示一段脉冲占整条路的 1/4
                    interval: 0.15,
                    // 脉冲线的速度，几秒钟跑完整段路
                    duration: 2000
                })
            },3000)
            op={
                center:[120.354916,36.082752],
                zoom:17.08,
                // zoom:16.81,
                rotation:5.945073583021077,
                pitch:56.48525845223961,
                timing:4500
            }

            loca.viewControl.addAnimates([{
                    center: {
                        value: op.center, //重点
                        control: [ op.center,
                            op.center],
                        timing: [0.5,0.5,0.5, 1],
                        duration: op.timing,
                    },
                    zoom: {
                        value: op.zoom,
                        control: [[op.zoom,  op.zoom], [ op.zoom,  op.zoom]],
                        timing: [0, 0, 0.8, 1],
                        duration: op.timing,
                    },
                    rotation: {
                        value: op.rotation,
                        control: [op.rotation, op.rotation],
                        timing: [0, 0, 0.7, 1],
                        duration:  op.timing,
                    },
                    pitch: {
                        value: op.pitch,
                        control: [[op.pitch, op.pitch], [op.pitch, op.pitch]],
                        timing: [0, 0, 1, 1],
                        duration:  op.timing ,
                    },
                }]
                ,
                function () {

                    //添加栈桥景区
                    //    标签  + 栈桥 + 周边  --台东线

                    setTimeout(()=>{
                        that.addS3zqOBJS()
                        mapLists0302.push(that.createDCBoardMarker(S3MainPoint[0], zqdescrip))
                    },1000)
                    setTimeout(()=>{
                        // 清除yunmarker
                        //    标签  + 栈桥 + 周边  --台东线
                        let width = '体验栈桥古城风貌'.length * 50
                        that.createTextMarker(S3MainPoint[0],'体验栈桥古城风貌',{width:width,height:'58px'})
                      
                    },1500)
                  
                        op={
                            center:[120.321067,36.065745],
                    zoom:16.91,
                    rotation:1.2467945788476196,
                    pitch:29.34240130938251,
                    timing:4000
                        }
                        loca.viewControl.addAnimates([{
                                center: {
                                    value: op.center, //重点
                                    control: [ op.center,
                                        op.center],
                                    timing: [0.5,0.5,0.5, 1],
                                    duration: op.timing,
                                },
                                zoom: {
                                    value: op.zoom,
                                    control: [[ op.zoom,  op.zoom], [ op.zoom,  op.zoom]],
                                    timing: [0, 0, 0.8, 1],
                                    duration: op.timing,
                                },
                                rotation: {
                                    value: op.rotation,
                                    control: [op.rotation, op.rotation],
                                    timing: [0, 0, 0.7, 1],
                                    duration:  op.timing,
                                },
                                pitch: {
                                    value: op.pitch,
                                    control: [[ op.pitch, op.pitch], [op.pitch, op.pitch]],
                                    timing: [0, 0, 1, 1],
                                    duration:  op.timing ,
                                },
                            }],
    
                            function () {
                                console.log('下一步--游轮母港')
                          
            
                                that.animateS32()
                    
                            }
                            );
                      
                
                  

                } 
            );

        },
        animateS32(){
            let that  =this
            //栈桥-游轮
            this.addS3ylOBJS()
            geo = this.createLineGeo([S3MainPoint[0],
                S3MainPoint[2]])
            this.drawDynamicLineDC(geo,{
                altitude: 0,
                lineWidth: 7,
                // 脉冲头颜色
                headColor:'rgba(255,234,124,0.0)',
                // 脉冲尾颜色
                trailColor: '#ffea7c',
                // 脉冲长度，0.25 表示一段脉冲占整条路的 1/4
                interval: 0.15,
                // 脉冲线的速度，几秒钟跑完整段路
                duration: 2000
            })
            setTimeout(()=>{
                let width = '体验邮轮母港海岸观光'.length * 50
                that.createTextMarker(S3MainPoint[2],'体验邮轮母港海岸观光',{width:width,height:'58px'})
                mapLists0302.push(that.createDCBoardMarker(S3MainPoint[2], yldescrip))
            },500)
       

            let op={
                center:[120.319817,36.083867],
                zoom:17.07,
                rotation:-3.9541490848853096,
                pitch:9.151925118906318,
                timing:3000
            }
            loca.viewControl.addAnimates([{
                    center: {
                        value: op.center, //重点
                        control: [ op.center,
                            op.center],
                        timing: [0.5,0.5,0.5, 1],
                        duration: op.timing,
                    },
                    zoom: {
                        value: op.zoom,
                        control: [[ op.zoom,  op.zoom], [ op.zoom,  op.zoom]],
                        timing: [0, 0, 0.8, 1],
                        duration: op.timing,
                    },
                    rotation: {
                        value: op.rotation,
                        control: [op.rotation, op.rotation],
                        timing: [0, 0, 0.7, 1],
                        duration:  op.timing,
                    },
                    pitch: {
                        value: op.pitch,
                        control: [[op.pitch, op.pitch], [op.pitch, op.pitch]],
                        timing: [0.3, 0, 1, 1],
                        duration:  op.timing ,
                    },
                }],
                function () {
                    // 添加游轮到李沧
                    // lcMainPoint
                    that.addS3lcOBJS()
                    geo = that.createLineGeo([S3MainPoint[2],
                        lcMainPoint[0]])
                    that.drawDynamicLineDC(geo,{
                        altitude: 0,
                        lineWidth: 7,
                        // 脉冲头颜色
                        headColor:'rgba(255,234,124,0.0)',
                        // 脉冲尾颜色
                        trailColor: '#ffea7c',
                        // 脉冲长度，0.25 表示一段脉冲占整条路的 1/4
                        interval: 0.15,
                        // 脉冲线的速度，几秒钟跑完整段路
                        duration: 2000
                    })
            
                    let op={
                        center:[120.334065,36.093742],
                        zoom:15.75,
                        rotation:8.345400328410014,
                        pitch:18.333333333333346,
                        timing:1600
                    }
                    loca.viewControl.addAnimates([{
                            center: {
                                value: op.center, //重点
                                control: [ op.center,
                                    op.center],
                                timing: [0.5,0.5,0.5, 1],
                                duration: op.timing,
                            },
                            zoom: {
                                value: op.zoom,
                                control: [[ op.zoom,  op.zoom], [ op.zoom,  op.zoom]],
                                timing: [0, 0, 0.8, 1],
                                duration: op.timing,
                            },
                            rotation: {
                                value: op.rotation,
                                control: [op.rotation, op.rotation],
                                timing: [0, 0, 0.7, 1],
                                duration:  op.timing,
                            },
                            pitch: {
                                value: op.pitch,
                                control: [[op.pitch, op.pitch], [op.pitch, op.pitch]],
                                timing: [0.3, 0, 1, 1],
                                duration:  op.timing ,
                            },
                        }],
                        function(){

    //第三步云平台
    map.remove(mapLists0302)
    mapLists0302=[]
    mapLists0302.push(that.createDCBoardMarker(S3MainPoint[2], yldescrip,new AMap.Pixel(-300,-200)))
    mapLists0302.push(that.createDCBoardMarker(S3MainPoint[1], tddescrip,new AMap.Pixel(100,300)))
    mapLists0302.push(that.createDCBoardMarker(S3MainPoint[0], zqdescrip,new AMap.Pixel(-10,300)))
    
                    setTimeout(()=>{
                        that.addStage03Yun()                    
                    },500)
                    let op={
                        center:[120.332876,36.099614],
                        zoom:14.54,
                        rotation:11.945400338456949,
                        pitch:80,
                        timing:2000
                    }
                    loca.viewControl.addAnimates([{
                            center: {
                                value: op.center, //重点
                                control: [ op.center,
                                    op.center],
                                timing: [0.5,0.5,0.5, 1],
                                duration: op.timing,
                            },
                            zoom: {
                                value: op.zoom,
                                control: [[op.zoom,  op.zoom], [ op.zoom,  op.zoom]],
                                timing: [0, 0, 0.8, 1],
                                duration: op.timing,
                            },
                            rotation: {
                                value: op.rotation,
                                control: [op.rotation, op.rotation],
                                timing: [0, 0, 0.7, 1],
                                duration:  op.timing,
                            },
                            pitch: {
                                value: op.pitch,
                                control: [[op.pitch, op.pitch], [op.pitch, op.pitch]],
                                timing: [0, 0, 1, 1],
                                duration:  op.timing ,
                            },
                        }]
                        ,function(){
                            setTimeout(()=>{
                                that.dcStage3Flag = false
                                that.dcStage1Flag = false
                                that.dcStage2Flag = false
                                that.dcStage4Flag = true
                             
                            })

                        }
                    )

                        }
    )
                
                }
            );
        },
        
        addStage03Yun() {
            let that = this
            let yunpt = [[120.331728, 36.074966]] //中心
         
            let m1 = this.createImageMarker(yunpt[0], './images/map/dc/yun-pingtai.png', {
                width: '169.0px',
                height: '105px',
                offset: new AMap.Pixel(0, -400)
            })
            let m2 = this.createImageMarker(yunpt[0], './images/map/dc/yun-dizuo.png', {
                class: 'shadow-item',
                width: '270px',
                height: '100px',
                offset: new AMap.Pixel(0, -380)
            })
            setTimeout(() => {
                that.yun1flag = false
                that.yun2flag = false
                that.yun3flag = true
                let center = that.yun2center
            let others = that.yun2others
            
            that.addCurveLines(center,others, {
                height:1700,
                mainheadColor:'#00ffff',
                maintailColor: '#326af6',
                otherheadColor:'#246996',
                othertailColor:'#52AFF7',
                linewidth:[8,8,8,
                    5,5,5,5,5,5,5,5,5,5,5,5,
                   2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2]
            })
             
            }, 800)
            yunMarkers.push(m1, m2)
            // setTimeout(()=>{
            //      that.animateS4()
            // },2000)
           
        },
//    云移动
        startAnimation(marker, lineArr, moveTime) {
            marker.moveAlong(lineArr, {
                // 每一段的时长
                duration: moveTime,//可根据实际采集时间间隔设置
                autoRotation: false,
            });
        },
        removeMarker() {
            map.remove(yunMarkers)
            locaLists.forEach(layer => {
                loca.remove(layer)
            })
            map.remove(mapLists02)
            mapLists02=[]
            // mapLists02.forEach(item=>{
            //     map.remove(item)
            // })
        },
        addStage03otherPath() {
            geo = this.createLinesGeo([
                [
                    [120.345326,36.081253],
                    [120.319718,36.065983],
                    [120.32109,36.082952],
                ], //游轮-栈桥-台东
                [ [120.320058,36.081951],//游轮 -台东 - 栈桥
                 [120.352718,36.080157],
                 [120.321882,36.064262],],
                //  外接线
                 [
                    [120.354437,36.079214],
                    [120.347171,36.061376],
                 ],
                [
                    [120.320701,36.064739],
                    [120.377154,36.067869],
                    [120.393407,36.083762],
                ]
                ]
            )

            this.drawDynamicLineDC(geo, {
                altitude: 0,
                lineWidth: 3,
                // 脉冲头颜色
                headColor: '#5c79ff',
                // 脉冲尾颜色
                trailColor: 'rgba(92,121,255,0.02)',
                // 脉冲长度，0.25 表示一段脉冲占整条路的 1/4
                interval: 0.15,
                // 脉冲线的速度，几秒钟跑完整段路
                duration: 3500
            })
            this.createCircleMarker( [120.319718,36.065983])
            this.createCircleMarker(  [120.352718,36.080157])

            // 
            this.createCircleMarker(  [120.347171,36.061376])

            this.createCircleMarker(  [120.377154,36.067869])
            this.createCircleMarker(  [120.393407,36.083762])

        

        },
        animateS4(){
            let that = this
            // 清除board
            map.remove(mapLists0302)
            mapLists0302=[]
            that.addStage03otherPath()
            let center = that.yun2center
            let others = that.yun2others02
            
            that.addCurveLines(center,others, {
                height:1700,
                mainheadColor:'#00ffff',
                maintailColor: '#326af6',
                otherheadColor:'#246996',
                othertailColor:'#52AFF7',
                linewidth:[8,8,8,
                    5,5,5,5,5,5,5,5,5,5,5,5,
                   2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,
                   2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2]
            })
        },
        createPointGeo(points) {
            let data = {
                "type": "FeatureCollection",
                "features": []
            }
            data.features = []
            points.forEach(item => {

                data.features.push({
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": item
                    },
                    "properties": {
                        "name": "",
                        "h": 0

                    }
                })
            })

            // return data
            return new Loca.GeoJSONSource({
                data: data
            })

        },
        createTopBottomZmarker(geo, url, options) {
            var triangleZMarker = new Loca.ZMarkerLayer({
                loca,
                zIndex: 119,
                depth: true,
            });
            triangleZMarker.setSource(geo);
            triangleZMarker.setStyle({
                content: () => {
                    return (
                        // `<!--<div style="width: 120px; height: 120px; background: url(${url})"></div>-->`
                        `<div style="width: ${options.width}; height: ${options.height}; background: url(${url}) no-repeat"></div>`
                    );
                },
                // unit: 'meter',
                unit: 'px',
                rotation: 0,
                alwaysFront: options.alwaysFront ? options.alwaysFront : true,
                size: options.size,
                altitude: options.altitude,
            });
            triangleZMarker.addAnimate({
                key: 'altitude',
                value: [0.5, 1],
                yoyo: true,
                repeat: 9999,
                // animate:options.animate ? options.animate:true,
                easing: 'Linear',
                transform: 800,// options.transform,
                random: options.random ? options.random : true,
                delay: 0,
                startAt: 1
            });
            locaLists.push(triangleZMarker)
            return triangleZMarker
        },

        createLinkGeo(point, array, isClock = true,options={}) {
            // [[lng,lat]]
            let data = {
                "type": "FeatureCollection",
                "features": []
            }
            data.features = []
            if (isClock) {
                array.forEach(item => {
                    data.features.push({
                        "type": "Feature",
                        "properties": options,
                        "geometry": {
                            "type": "LineString",
                            "coordinates": [
                                point[0],
                                item
                            ]
                        }
                    })
                })
            } else {
                array.forEach(item => {
                    data.features.push({
                        "type": "Feature",
                        "properties": options,
                        "geometry": {
                            "type": "LineString",
                            "coordinates": [
                                item,
                                point[0]
                            ]
                        }
                    })
                })
            }

            // console.log(data, 'link data')
            return new Loca.GeoJSONSource({
                data: data
            });
        },
        createLineGeo(points) {
            let data = {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        properties: {
                            type: 2
                        },
                        geometry: {
                            type: 'LineString',
                            coordinates: points
                        }
                    }
                ]
            }
            return new Loca.GeoJSONSource({
                data: data
            });
        },
        createLinesGeo(points) {
            let data = {
                type: 'FeatureCollection',
                features: []
            }
            points.forEach(item => {
                data.features.push({
                    type: 'Feature',
                    properties: {
                        type: 2
                    },
                    geometry: {
                        type: 'LineString',
                        coordinates: item
                    }
                })
            })
            // console.log(points, 'itemkkkkk')
            return new Loca.GeoJSONSource({
                data: data
            });
        },

        createScatter(geo, options) {

            var scatter = new Loca.ScatterLayer({
                loca: loca,
                zIndex: 110,
                opacity: 1,
                visible: true,
                zooms: [2, 26],
                depth: false
            });
            scatter.setSource(geo);
            scatter.setStyle(
                options
            );
            locaLists.push(scatter)
            return scatter
        },
        createLasterLine(geo, options) {
            var layer = new Loca.LaserLayer({
                zIndex: 130,
                opacity: 1,
                loca: loca,
                visible: true,
                depth: true,
                zooms: [2, 26],
            });
            layer.setSource(geo, options);
            loca.add(layer)
            locaLists.push(layer)
            return layer
        },
        createDCMarker(pos, text, offset) {

            const marker = new AMap.Marker({
                content: `<div id="at-item">
              <p style="width: 110px; height: 30px; line-height: 30px; font-size:19px;background-image:linear-gradient(to right,rgba(30,215,196,0.4),rgba(30, 215, 196, 0.3),rgba(0,0,0,0.4)); border:2px solid rgba(30, 215, 196, 0.9); color:#fff; border-radius: 10px; text-align:center; margin:0;padding:0;">
                ${text || ''}
              </p>
            </div>
            `,
                position: pos,
                offset: offset ? offset : new AMap.Pixel(0, 0),
                anchor: 'bottom-center'
            })
            map.add(marker)
            mapLists.push(marker)
            return marker
        },
        createTextMarker1(pos, text, options) {

            let textEL = ``
            for (let i = 0; i < text.length; i++) {
                // console.log(i, text[i])
                textEL = textEL.concat(
                    `
            <span>${text[i]}</span>
            `
                )
            }

            const marker = new AMap.Marker({
                content:
                // `<!--<div class="${options.class?options.class:''}" style="width: ${options.width}; height:  ${options.height};">-->
                    `
<div style="display: flex;align-items: center">
<div class="circleItem" style="margin-right: 20px;margin-top: -10px;"></div>
<div class="textdc" style="display:flex;width: ${options.width}; height:  ${options.height};">
        ${textEL}</div>
</div>
`,
                position: pos,
                offset:new AMap.Pixel(0, 0),// options.offset ? options.offset : new AMap.Pixel(0, 70),
                anchor: 'bottom-left'
                // anchor: 'center'
            })
            map.add(marker)
            mapLists.push(marker)
        },
        createTextMarker(pos, text, options) {

            let textEL = ``
            for (let i = 0; i < text.length; i++) {
                // console.log(i, text[i])
                textEL = textEL.concat(
                    `
            <span>${text[i]}</span>
            `
                )
            }

            const marker = new AMap.Marker({
                content:
                // `<!--<div class="${options.class?options.class:''}" style="width: ${options.width}; height:  ${options.height};">-->
`<div class="textdc" style="display:flex;width: ${options.width}; height:  ${options.height};">
        ${textEL}</div>
`,
                position: pos,
                offset:new AMap.Pixel(0, 0),// options.offset ? options.offset : new AMap.Pixel(0, 70),
                anchor: 'bottom-left'
                // anchor: 'center'
            })
            map.add(marker)
            mapLists.push(marker)
            return marker
        },
        createCircleMarker(pos) {
            const marker = new AMap.Marker({
                content:
                // `<!--<div class="${options.class?options.class:''}" style="width: ${options.width}; height:  ${options.height};">-->
                    `<div class="circleItemBlue" ></div>`,
                position: pos,
                // offset:options.offset ? options.offset : new AMap.Pixel(0, 0),
                offset: new AMap.Pixel(0, 0),
                anchor: 'bottom-center'
            })
            map.add(marker)
            mapLists.push(marker)
        },

        createTextZMarker(geo, text, options) {

            let textEL = ``
            for (let i = 0; i < text.length; i++) {

                textEL = textEL.concat(
                    `
            <span>${text[i]}</span>
            `
                )
            }

            var triangleZMarker = new Loca.ZMarkerLayer({
                loca: loca,
                zIndex: 119,
                depth: true,
            });
            triangleZMarker.setSource(geo);
            triangleZMarker.setStyle({
                content: () => {
                    return (
                        `<div class="text" style="display:flex;width: ${options.width}; height:  ${options.height};">
        ${textEL}</div>`
                    );
                },
                unit: 'meter',
                // unit: 'px',
                rotation: 0,
                alwaysFront: options.alwaysFront ? options.alwaysFront : true,
                size: options.size,
                altitude: options.altitude,
            });
            locaLists.push(triangleZMarker)
        },

        createImageMarker(pos, url, options) {

            const marker = new AMap.Marker({
                content:
                // `<!--<div style="width: 500px; height:  500px; background: url(${url}) no-repeat;"></div>-->`,
                // `<div style="width: ${options.width}; height:  ${options.height}; background: url(${url}) no-repeat;"></div>`,
                    `<div class="${options.class ? options.class : ''}" style="width: ${options.width}; height:  ${options.height};">
<img src="${url}" width="${options.width}" height="${options.height}" alt="">
</div>`,
                position: pos,
                offset: options.offset ? options.offset : new AMap.Pixel(0, 70),
                anchor: 'bottom-center'
            })
            map.add(marker)
            mapLists.push(marker)
            return marker
        },
        createImageZMarker(geo, url, options) {
            var triangleZMarker = new Loca.ZMarkerLayer({
                loca: loca,
                zIndex: 119,
                depth: true,
            });
            triangleZMarker.setSource(geo);
            triangleZMarker.setStyle({
                content: () => {
                    return (
                        `<div style="width: ${options.width}; height:  ${options.height}; background: url(${url}) no-repeat;"></div>`
                    );
                },
                unit: 'meter',
                rotation: 0,
                alwaysFront: true,
                size: options.size,
                altitude: options.altitude,
            });
            locaLists.push(triangleZMarker)
        },
        createPulseLinkLine(geo, options) {
// 弧线
            let opt = {
                unit: 'meter',
                dash: [1, 0, 0, 0],
                lineWidth: [3,15],
                height: 910,
                altitude: 100,
                smoothSteps: 20,
                speed: 20,
                flowLength: 0,
                lineColors: ['rgba(100,211,255,0)', 'rgba(80,179,255,0)', 'rgba(62,144,255,0.1)', 'rgba(62,144,255,1)', 'rgba(106,245,255,1)'],
                maxHeightScale: 0.02, // 弧顶位置比例
                headColor: 'rgba(62,144,255,0.0)',
                trailColor: 'rgba(62,144,255,0.0)',
            }
            var pulseLink = new Loca.PulseLinkLayer({
                // loca,
                zIndex: 10,
                opacity: 1,
                visible: true,
                zooms: [2, 22],
                depth: true,
            });


            pulseLink.setSource(geo);
            pulseLink.setStyle(options || opt);
            loca.add(pulseLink);
            locaLists.push(pulseLink)
            return pulseLink
        },
        drawDynamicLineDC(geo, options) {
            let opt = {
                altitude: 0,
                lineWidth: 8,
                // 脉冲头颜色
                headColor: '#FBF9E3',
                // 脉冲尾颜色
                trailColor: '#CA9D71',
                // 脉冲长度，0.25 表示一段脉冲占整条路的 1/4
                interval: 0.25,
                // 脉冲线的速度，几秒钟跑完整段路
                duration: 1500
            }
            const pathLayer = new window.Loca.PulseLineLayer({
                // loca,
                zIndex: 10,
                opacity: 1,
                visible: true,
                zooms: [2, 22]
            })
            pathLayer.setSource(geo)
            pathLayer.setStyle(options || opt)
            loca.add(pathLayer)
            locaLists.push(pathLayer)
        },
        addDCBG(url) {
            var maps = new AMap.ImageLayer({
                // url: `./images/map/dc/s021.png`,
                url: url,

                bounds: new AMap.Bounds(
                    [120.293989, 36.056386],
                    [120.373833, 36.090052]
                ),
                zooms: [10, 20]
            })
            map.add(maps)
            mapLists.push(maps)
        },
        addS3zqOBJS(){
            otherGeo = this.createPointGeo(zqohterPoints)
            otherArray = zqohterPoints
            let zqgeo =  new Loca.GeoJSONSource({
                data: {
                    "type": "FeatureCollection",
                    "features": [{
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": S3MainPoint[0]
                        },
                        "properties": {}
                    }]
                }
            })
            locaLists03.push(this.createScatter(zqgeo, {
                unit: 'meter',
                size:[220,220],
                texture: './images/map/dc/breath_yellow.png',
                duration: 2000,
                animate: true,
            }))
            setTimeout(() => {
                otherArray.forEach((item,index) => {
                    mapLists03.push(this.createImageMarker(item, './images/map/dc/triangle-yellow.png', {
                            width: '16px',
                            height: '25px',
                            offset: new AMap.Pixel(0, 0),
                            class: 'bounce-item'
                        })
                    )
                    mapLists03.push( this.createDCTextMarker(item, zqtexts[index]))
                })
                // './images/map/dc/green.png'
                // './images/map/dc/scan_yellow.png'
                locaLists03.push(this.createScatter(otherGeo, {
                    unit: 'meter',
                    size:[100,100],
                    texture: './images/map/dc/green.png',
                    duration: 2000,
                    animate: true,
                }))
            }, 1200)

            linkGeo = this.createLinkGeo([S3MainPoint[0]], otherArray)
            //
            locaLists03.push( this.createPulseLinkLine(linkGeo, {
                unit: 'meter',
                dash: [1, 0, 0, 0],
                lineWidth: function () {
                    return [3.5, 1.5];
                },
                height: 150,
                altitude: 100,
                smoothSteps: 20,
                speed: 20,
                flowLength: 10,
                lineColors: function () {
                    return ['rgb(100,255,242)', 'rgb(80,249,255)', 'rgb(75,253,247)', 'rgba(57,214,232,1)', 'rgb(191,242,255)'];
                },
                maxHeightScale: 0.5, // 弧顶位置比例
                headColor: 'rgb(236,255,167)',
                trailColor: 'rgb(238,248,99)',
            }))
        },
        addS3tdOBJS(){
            otherGeo = this.createPointGeo(tdohterPoints)
            otherArray = tdohterPoints
            let zqgeo =  new Loca.GeoJSONSource({
                data: {
                    "type": "FeatureCollection",
                    "features": [{
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": S3MainPoint[1]
                        },
                        "properties": {}
                    }]
                }
            })
            locaLists03.push(this.createScatter(zqgeo, {
                unit: 'meter',
                size:[220,220],
                texture: './images/map/dc/breath_yellow.png',
                duration: 2000,
                animate: true,
            }))
            setTimeout(() => {
                otherArray.forEach((item,index) => {
                    mapLists03.push(this.createImageMarker(item, './images/map/dc/triangle-yellow.png', {
                            width: '16px',
                            height: '25px',
                            offset: new AMap.Pixel(0, 0),
                            class: 'bounce-item'
                        })
                    )
                    mapLists03.push( this.createDCTextMarker(item, tdtexts[index]))
                })
                // './images/map/dc/green.png'
                // './images/map/dc/scan_yellow.png'
                locaLists03.push(this.createScatter(otherGeo, {
                    unit: 'meter',
                    size:[100,100],
                    texture: './images/map/dc/green.png',
                    duration: 2000,
                    animate: true,
                }))
            }, 2500)

            linkGeo = this.createLinkGeo([S3MainPoint[1]], otherArray)
            //
            locaLists03.push( this.createPulseLinkLine(linkGeo, {
                unit: 'meter',
                dash: [1, 0, 0, 0],
                lineWidth: function () {
                    return [3.5, 1.5];
                },
                height: 150,
                altitude: 100,
                smoothSteps: 20,
                speed: 20,
                flowLength: 10,
                lineColors: function () {
                    return ['rgb(100,255,242)', 'rgb(80,249,255)', 'rgb(75,253,247)', 'rgba(57,214,232,1)', 'rgb(191,242,255)'];
                },
                maxHeightScale: 0.5, // 弧顶位置比例
                headColor: 'rgb(236,255,167)',
                trailColor: 'rgb(238,248,99)',
            }))
        },
        addS3ylOBJS(){
            // otherGeo = this.createPointGeo(tdohterPoints)
            // otherArray = tdohterPoints
            let zqgeo =  new Loca.GeoJSONSource({
                data: {
                    "type": "FeatureCollection",
                    "features": [{
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": S3MainPoint[2]
                        },
                        "properties": {}
                    }]
                }
            })
            locaLists03.push(this.createScatter(zqgeo, {
                unit: 'meter',
                size:[220,220],
                texture: './images/map/dc/breath_yellow.png',
                duration: 2000,
                animate: true,
            }))
            
        },
        addS3lcOBJS(){
            // otherGeo = this.createPointGeo(tdohterPoints)
            // otherArray = tdohterPoints
            let lcgeo =  new Loca.GeoJSONSource({
                data: {
                    "type": "FeatureCollection",
                    "features": [{
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": lcMainPoint[0]
                        },
                        "properties": {}
                    }]
                }
            })
            locaLists03.push(this.createScatter(lcgeo, {
                unit: 'meter',
                size:[220,220],
                texture: './images/map/dc/breath_yellow.png',
                duration: 2000,
                animate: true,
            }))
        },
        createDCTextMarkerS1(pos, text){

                const width = text.length * 70 + 'px'
                const marker = new AMap.Marker({
                    content:

                        `<div class="dctextshadows1" style="width: ${width}; height: 50px;">${text}
</div>`,
                    position: pos,
                    offset: new AMap.Pixel(10, 30),
                    anchor: 'bottom-left',
                    zooms: [16,25],
                })
                map.add(marker)
                mapLists.push(marker)
                return marker

        },
        createDCTextMarker(pos, text){

            const width = text.length * 70 + 'px'
            const marker = new AMap.Marker({
                content:

                    `<div class="dctextshadow" style="width: ${width}; height: 50px;">${text}
</div>`,
                position: pos,
                offset: new AMap.Pixel(0, 0),
                anchor: 'bottom-left',
                zooms: [16,25],
            })
            map.add(marker)
            mapLists.push(marker)
            return marker

    },
        createDCBoardMarker(pos, texts,offset){
            
            let temp=``
            texts.forEach(item=>{
                temp +=` <div class="item">
                       ${item}
                </div>`
            })
            const marker = new AMap.Marker({
                content:

                    `<div id="dcboardBox" >
                   ${temp}
                </div>`,
                position: pos,
                offset:offset ? offset: new AMap.Pixel(0, -100),
                anchor: 'bottom-center',
                zooms: [10,25],
            })
            map.add(marker)
            mapLists.push(marker)
            return marker

    },
        removeAlldcOBJS(){
            if(loca) {
                if(loca.viewControl){
                    loca.viewControl.pauseAnimate();
                    loca.viewControl.clearAnimates();
                }
                if(locaLists.length){
                    locaLists.forEach(item =>{
                        loca.remove(item)
                    })
                    loca.clear()
                }

            }
            if(map){
                if(mapLists.length){
                    mapLists.forEach(item=>{
                        map.remove(item)
                    })
                }
                map && map.remove(mapLists)
            }
         
            mapLists =[]
            locaLists=[]
          
          
            this.yun1flag = false
            this.yun2flag = false
            this.yun3flag = false

        },
        clearstage01objs(){
            mapLists03.forEach(item=>{
                map.remove(item)
            })
            locaLists03.forEach(item=>{
                loca.remove(item)
            })
        },
        initData(){
            this.customCoords = map.customCoords
            customCoords = map.customCoords;
            // 第一云
             let yun1others = [wdPt[0], qpPt[0], ddPt[0],
                [120.35071,36.080856],
                [120.354107,36.083874],
                [120.356501,36.081147],
// 更细
                [120.352295,36.077947],
                [120.353385,36.079553],
                [120.356326,36.079483],
                [120.358935,36.082041],
                [120.35452,36.086374],
                [120.34744,36.081428],
                [120.346965,36.078741],
            ]
            this.yun1center=customCoords.lngLatsToCoords(yunPt) //中心
            this.yun1others=customCoords.lngLatsToCoords(yun1others)
            
            // 云二
            let yun2center = [[120.331728, 36.074966]] //中心
            let yun2others = [

                [120.3187,36.066365], //栈桥
                [120.320538,36.084378], //游轮
                [120.355449,36.083117], //台东


                [120.313267,36.066816], //栈桥
                [120.314658,36.063886],
                [120.324433,36.064211],
                [120.322389,36.068114],

                [120.315128,36.084848], //邮轮母港
                [120.316679,36.081461],
                [120.326425,36.081606],
                [120.324733,36.085755],

                [  120.361384,36.08115], //台东
                [ 120.359383,36.085307],
                [ 120.351626,36.080823],
                [ 120.350181,36.084079],

            ]
            let yun2others02 = [
                [120.3187,36.066365], //栈桥
                [120.320538,36.084378], //游轮
                [120.355449,36.083117], //台东


                [120.313267,36.066816], //栈桥
                [120.314658,36.063886],
                [120.324433,36.064211],
                [120.322389,36.068114],

                [120.315128,36.084848], //邮轮母港
                [120.316679,36.081461],
                [120.326425,36.081606],
                [120.324733,36.085755],

                [  120.361384,36.08115], //台东
                [ 120.359383,36.085307],
                [ 120.351626,36.080823],
                [ 120.350181,36.084079],
                // suiji
 [120.323704,36.063381],
 [120.322194,36.063321],
 [120.317918,36.062886],
 [120.316911,36.065887],
 [120.316104,36.068939],
 [120.316619,36.063247],
 [120.319469,36.082824],
 [120.319443,36.087243],
 [120.342895,36.079803],
 [120.346873,36.081386],
 [120.353435,36.084047],
 [120.353716,36.087565],
 [120.359863,36.081349],

//  其他区域
[120.334508,36.061892],
[120.347133,36.061812],
[120.348602,36.072928],
[120.355861,36.064932],
[120.381023,36.073086],

[120.328811,36.105395],
[120.349779,36.108189],
[120.337205,36.114959],
            ]
            this.yun2center=customCoords.lngLatsToCoords(yun2center) //中心
            this.yun2others=customCoords.lngLatsToCoords(yun2others)
            this.yun2others02=customCoords.lngLatsToCoords(yun2others02)
        },
      initScene(){
         // 创建 GL 图层
         let that = this
      
         var gllayer = new AMap.GLCustomLayer({
           // 图层的层级
           zIndex: 10,
           // 初始化的操作，创建图层过程中执行一次。
           init: (gl) => {
             console.log(gl,that.$refs.amap_map.offsetWidth,'gl')
             
             camera = new THREE.PerspectiveCamera(60, that.$refs.amap_map.offsetWidth / that.$refs.amap_map.offsetHeight, 100, 1 << 30);
             renderer = new THREE.WebGLRenderer({
               context: gl,  // 地图的 gl 上下文
               alpha: true,
               antialias: true,
               canvas: gl.canvas,
             });

             renderer.autoClear = false;
             scene = new THREE.Scene();
             // 环境光照和平行光
             var aLight = new THREE.AmbientLight(0xffffff, 0.3);
             var dLight = new THREE.DirectionalLight(0xffffff, 1);
             dLight.position.set(1000, -100, 900);
             scene.add(dLight);
             scene.add(aLight);
           },
           render: () => {

             // 这里必须执行！！重新设置 three 的 gl 上下文状态。
             renderer.resetState();
    
            //  customCoords.setCenter([ 120.352486, 36.082912]);
             var { near, far, fov, up, lookAt, position } = customCoords.getCameraParams();

             camera.near = near;
             camera.far = far;
             camera.fov = fov;
             camera.position.set(...position);
             camera.up.set(...up);
             camera.lookAt(...lookAt);
             camera.updateProjectionMatrix();
             renderer.render(scene, camera);

             // 这里必须执行！！重新设置 three 的 gl 上下文状态。
             renderer.resetState();

           },
         });
      
         setTimeout(()=>{
         map.add(gllayer);
         that.animate()
         window.addEventListener('resize', that.onWindowResize);
         })
        
       },
       addCurveLines(center,others,options){
        if(!options){
            options={
                height:500,
                headColor:'#00ffff',
                tailColor: '#326af6',
                linewidth:5
            }
        }
        let that = this
        if(that.yunmeshes){
            scene.remove(that.yunmeshes)
            that.yunmeshes = undefined
        }
       
        that.animateCurves =[]
        that.animateMeshes =[]
        that.yunmeshes = new THREE.Group()
        scene.add(that.yunmeshes)
        const point1 = new THREE.Vector3(center[0][0], center[0][1],options.height)
        others.forEach((other,index)=>{
          
            const point2 = new THREE.Vector3(other[0],other[1], 0)

            const controlPoint = new THREE.Vector3(
              (point1.x + point2.x) / 2,
              (point1.y + point2.y) / 2,
              (point1.z + point2.z) / 2 + options.height/2
            )
    
       // 创建三维二次贝塞尔曲线
            const curve = new THREE.QuadraticBezierCurve3(
              new THREE.Vector3(point2.x, point2.y, point2.z),
              new THREE.Vector3(controlPoint.x, controlPoint.y, controlPoint.z),
              new THREE.Vector3(point1.x, point1.y, point1.z),
            )
            const divisions = 30 // 曲线的分段数量
            const points = curve.getPoints(divisions) // 返回分段的点 31个
         
    
            const geometry = new LineGeometry();
            let headColor,tailColor
            if(index <3){
                headColor = options.mainheadColor
                tailColor = options.maintailColor
            }else if(index <6){
                headColor = options.otherheadColor
                tailColor = options.otherheadColor
            }else{
                headColor = options.othertailColor
                tailColor = options.othertailColor
            }
            let colors =[]
            let positions =[]
            const c1 = new THREE.Color(headColor); //曲线起点颜色 青色
            const c2 = new THREE.Color(tailColor); //曲线结束点颜色 黄色
        
            for ( let i = 0, l = points.length; i < l; i ++ ) {
              let point = points[i]
              const t = i / l;
              positions.push(point.x, point.y, point.z)
              const c = c1.clone().lerp(c2, t);//颜色插值计算
              colors.push( c.r, c.g, c.b);
            }
           
            geometry.setColors( colors );
            geometry.setPositions(positions)
            matline = new LineMaterial({
              linewidth:options.linewidth[index],//options.linewidth,
              vertexColors: true,
              dashed: false,
              alphaToCoverage: true,
            })
    
            matline.resolution.set( that.$refs.amap_map.offsetWidth ,that.$refs.amap_map.offsetHeight ); 
     
            const linemesh = new Line2(geometry, matline)
    // // 线的动画
            that.yunmeshes.add(linemesh)
            linemesh.computeLineDistances();
              
            var textureLoader = new THREE.TextureLoader();
            var textureFlare0 = textureLoader.load('/images/map/dc/lpoint.png')
    
            const geometryp = new THREE.BufferGeometry();
            let pos =points[0]
            geometryp.setAttribute('position', new THREE.Float32BufferAttribute([pos.x,pos.y,pos.z], 3));
            const material = new THREE.PointsMaterial({
              size: 60,
              map: textureFlare0,
              alphaMap:textureFlare0,
              blending: THREE.AdditiveBlending,
              depthTest: false,
              transparent: true,
              depthWrite: false,
              color: 'transparent',
              sizeAttenuation:false,
            });
           let lightpoint = new THREE.Points(geometryp, material);
  
           that.yunmeshes.add(lightpoint)
           that.animateCurves.push(curve)
           that.animateMeshes.push(lightpoint)
           that.counter0 =0
           that.counter1 =0
           that.counter2 =0
           that.counter4 =1
           that.counter3 =1
           
        })
       },
       
      animate() {
          map.render();
          
          if(this.yun1flag || this.yun2flag || this.yun3flag ){
            if(this.counter0 <1){
                    this.counter0 += 0.001 + Math.random() * 0.005;
            }
            else{
              this.counter0 = 0
            }
            if(this.counter1 <1){
                    this.counter1 += 0.003 + Math.random() * 0.005;
            }else{
              this.counter1 = 0
            }
            if(this.counter2 <1){
                    this.counter2 += 0.005 + Math.random() * 0.005;
            }else{
              this.counter2 = 0
            }
            if(this.counter3 >0){
                    this.counter3 -= 0.003 + Math.random() * 0.005;
            }else{
              this.counter3 = 1
            }
            if(this.counter4 >0){
                this.counter4 -= 0.005 + Math.random() * 0.005;
        }else{
          this.counter4 = 1
        }
        // console.log(this.counter0,this.counter1,this.counter2,this.counter3,this.counter4)
            this.flyLineFlow()
            }else{
                if(this.yunmeshes &&(!this.yun1flag || !this.yun2flag || !this.yun3flag) ){
                    scene.remove(this.yunmeshes)
                    this.yunmeshes = undefined
                }
            }
          requestAnimationFrame(this.animate);
      },
      flyLineFlow(){
               let that = this
              if(that.animateCurves.length){
                that.animateCurves.forEach((curve,index)=>{
                    let counter 
                    if(index % 7 == 0 ){
                        counter = that.counter3
                    }else if(index % 3 == 0){
                        counter = that.counter1
                    }else if(index % 4 == 0){
                        counter = that.counter2
                    }else if(index % 5 == 0){
                        counter =  that.counter4
                    }else{
                        counter =  that.counter0
                    }
                   
                    let pos =curve.getPointAt(counter)
                    that.animateMeshes[index].geometry.setAttribute('position', new THREE.Float32BufferAttribute([pos.x,pos.y,pos.z], 3));
                  })           
              }      
      },


      onWindowResize() {
           camera.aspect = window.innerWidth / window.innerHeight;
           camera.updateProjectionMatrix();
           renderer.setSize(window.innerWidth, window.innerHeight);
     }

    }
}