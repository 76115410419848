export const mapConfig = {
  base: {
    key: 'c26dd0369ec2a08777862c2c86d242b8',
    // key: '49bbe2f846405c0478d474fab74aa4e9',
    // version: '2.0',
    version: '1.4.15',
    plugins: ['AMap.GeoJSON', 'AMap.DistrictSearch', 'AMap.MoveAnimation'],
    AMapUI: {
      version: '1.1',
      plugins: ['overlay/SimpleMarker']
    },
    // Loca: {
    //   version: '2.0.0'
    // }
  },
  style: {
    mapStyle: 'amap://styles/ce7bfac7a2f145e4e675133682f624a6',
    center: [120.3502, 36.083508],
    resizeEnable: true, //是否监控地图容器尺寸变化
    showLabel: false,
    zooms: [10, 18],
    // zooms: [10, 27],
    scrollWheel: true,
    keyboardEnable: false,
    doubleClickZoom: false,
    // rotateEnable: false,
    showIndoorMap: false,
    // pitchEnable: false,
    showBuildingBlock: false,
    zoom: 16.77,
    viewMode: '3D',
    pitch: 47.24716321414439,
    rotation: 42.44320118885079
  }
}
