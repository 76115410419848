<template>
  <div class='iw-popInfo' style='width:610px;background: rgba(11,20,19,0.8); ;border: 1px solid  #00FFF7 ;' v-if='visible'>
    <div class='box' style='width: 100%;'>
      <div class='tools' style='height: 50px; background: rgba(7,64,68,0.5);
      border-bottom: 1px solid #00FFF7;line-height: 50px;width: 100%;'>
            <span class='text' style=' padding-left: 20px;font-size: 26px;
        font-family: SourceHanSansCN;font-weight: bold;color: #00FFFF;opacity:1;'>路灯控制</span>
          <img style='width:30px;height:30px; position: absolute;right: 10px;top: 10px;cursor: pointer;'
               src='/images/components/std/del-n.png' alt='' @click='close' />
      </div>
      <div class='content' style='display: flex;width: 100%;flex-direction: column;font-size: 18px;flex:2;height: 500px;'>
        <div class='gcitem'  style='height: 50px;line-height: 50px;margin-top: 15px;margin-bottom: 25px;'>
          <ek-select :list="dropList"
                     @click="handleArea"
                     :width="width"
                     :selectVal="selectValue"          
          />
        </div >
        <div class='gcitem' style="max-height: 700px;margin-bottom: 40px;">
          <div class="gcitem-title" style="display: flex;align-items: center;">
            <div class="item" style="width:103px;">回路信息</div>
            <div class="item" style="width:385px"><span style="margin: 0 auto;">管控设备</span></div>
            <div class="item" style="width:103px"><span style="margin: 0 auto;">状态</span></div>
          </div>
          <div class="gcitem-content" >
           <div class="row"  v-for="(item,index) in loopList" :key="index">
             <div class="item" style="width:103px;"><span style="margin: 0 auto;">{{item.deviceName}}</span></div>
             <div class="item" style="width:385px"><span style="margin: 0 auto;">{{item.lightType}}</span></div>
             <div class="item" style="width:103px" >
                 <el-switch
                  @change="checkPassword(index,$event)"
                  style='margin: 0 auto;cursor:pointer;pointer-events: auto'
                  v-model ="item.switchVal"
                  active-color="#35C759"
                  inactive-color="#CFCFCF"
                  :active-value="1"
                  :inactive-value="0"
                  >
                </el-switch>
               <!-- <img :src="data.bzImg" width='50px' height='27px' style='margin: 0 auto;cursor:pointer;pointer-events: auto'
                    alt='' @click='changeSwith' /> -->
             </div>
           </div>

          </div>
        </div>
        </div>

      </div>



    </div>



</template>

<script>

import { controlDeviceSwitch,queryLightLoop,lightLoopList } from '@/api/other'

export default {
  data() {
    return {
      title: '',
      selectValue:1,
      data: {},
      visible:false,
      switchFlag:true,
      width:'570px',
      dropDwons: {
        arae: []
      },
      dropList:[
        ],
        loopList:[],
      formData:{
        label:'',
        value:0
      },
      list: [
        {
          type: '设备',
          level: '1',
          dev: '报警柱',
          content: '紧急报警',
          postion: '台东三路88号',
          time: '04/03'
        },
        {
          type: '舆情',
          level: '2',
          dev: '新浪微博',
          content: '环境差',
          postion: '满口香饭店',
          time: '04/03'
        },
        {
          type: '设备',
          level: '3',
          dev: '智能垃圾桶',
          content: '垃圾桶满溢',
          postion: '台东三路88号',
          time: '04/03'
        }
      ],
      dataTotal: 0,
      curPage: 1,
      pageSize: 3
    }
  },
 

  mounted() {
   this.selectValue=1
   this.fetchDropData()

  },
  created(){

  },
  methods: {

    handleArea(value) {
      this.selectValue = value
      console.log(value,'select value')
      this.fetchDropList(this.dropList[value].label)
    },
    async fetchDropData(){

      const { resultCode, resultData } =  await queryLightLoop()
      this.dropList=[]
      if (resultCode === '200' && resultData){
        resultData.forEach((item,index)=>{
          // this.dropList[index].label = item
          // this.dropList[index].value = index
          this.dropList.push({
            label:item,
            value:index
          })
        })
        console.log(this.dropList,'suc fetch drop')
        this.selectValue = 1
        this.handleArea(this.selectValue)
        // this.fetchDropList(this.dropList[this.selectValue].label)
      }
    },
    async fetchDropList(label){
        const { resultCode, resultData } =  await lightLoopList({
           "deviceLocation":label
        })
      this.loopList =[]
      if (resultCode === '200' && resultData){
        resultData.forEach((item)=>{
          
          this.loopList.push({
            deviceName:item.deviceName.split(' ')[1],
            lightType:item.lightType,
            deviceSwitch:item.deviceSwitch,
            deviceId:item.deviceId,
            // switchVal:item.deviceSwitch == '1' ? true :false
            switchVal:item.deviceSwitch == '1' ? 1 : 0
          })
          
        })
        console.log(resultData,this.loopList,'suc fetch drop')
      }
    },
    checkPassword(index,event){
      let that = this
  if (event == 1) {
    // text = '开启'
     that.loopList[index].switchVal = 0
  } else {
  
     that.loopList[index].switchVal =1 
  }

      this.$prompt('', '提示', {
        customClass: 'popup-conirm',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入密码',
        inputType: 'password'
      })
        .then(({ value }) => {

            that.changeSwith(that.loopList[index],index,value)

        })
        .catch(() => {
          
        })
    },
    async changeSwith(data,index,password){
      console.log(data.deviceId,data.switchVal)
      let that = this

            
      const { resultCode, resultData } =  await controlDeviceSwitch({
        "deviceId": data.deviceId,
        "deviceSwitch":data.switchVal ? '0' : '1',
        "password":password
        // "deviceType": "lighting"
      })
      console.log(resultCode,'路灯code ')
      if (resultCode === '200' && resultData){
            if( that.loopList[index].switchVal  == 1 ){
              that.loopList[index].switchVal = 0
            }else{
              that.loopList[index].switchVal = 1
            }
           this.$eventBus.$emit('iw-loopSwitch',data.switchVal)
      
      }
      console.log(data.switchVal,data.deviceId,'改变状态')
    },


    closeInfoWindow() {
      window.closeInfoWindow()
      this.visible = false
    },
    open() {

      // this.url = url
      // this.width = width
      this.visible = true
      // this.fetchDropData()

    },
    close() {
      this.visible = false
    }
  }
}
</script>

<style scoped lang='scss'>

.box {

  @include flex(column);

  .tools {
    @include flex(column);

    .title {
      height: 50px;
      //width: 100%;
      background: rgba(7, 64, 68, 0.5);
      border-bottom: 1px solid #00FFF7;
      line-height: 50px;
      align-items: center;
      width: 100%;

      .text {
        padding-left: 20px;
        font-size: 26px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #00FFFF;
      }

      img {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
    }
  }

  .content {
    flex: 1;

    .item {
      display: flex;
      align-items: center;
    }
  }

  .chart-box1 {

    //flex: 1;
    //position: relative;
    //@include flex();
    //justify-content: center;

  }
}

.item {
  margin-left: 30px;

}

.table-box {
  height: 250px;
  padding: 5px 15px;

  .table-list {
    height: 100%;

    .table-item {

      display: flex;
      //justify-content: space-between;
      //padding: 0 10px;
      &:nth-child(odd) {
        background: rgba(8, 74, 80, 0.5);
      }

      &:nth-child(even) {
        background: rgba(8, 74, 80, 0.0);
      }

      p {
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }

      .f1 {
        flex: 1
      }

      .f2 {
        flex: 2
      }

      .f3 {
        flex: 3
      }

      .f4 {
        flex: 4
      }

      .f5 {
        flex: 5
      }

      .f6 {
        flex: 6
      }
    }
  }

  .table-body {
    height: 230px;

    .swiper-container {
      height: 100%;
      width: 100%;
    }
  }
}
.gcitem{
  .gcitem-title{
    display: flex;
    .item{
      height: 40px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #00FFFF;
      line-height: 40px;
      margin-left: 0;



    }
  }
  .gcitem-content{
    .row{
      .item{
        margin-left: 0;
        span{
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 45px;
          height: 45px;
        }
      }
    }
  }
}
</style>


<style lang="scss">
.gcitem{
  .el-select,
  .el-input__inner,
  .el-input__inner:focus,
  .el-input__inner:hover,
  .el-select:hover .el-input__inner,
  .el-input.is-focus .el-input__inner {
    border-color: rgba(0, 105, 111, 1) !important;
    font-size: 22px;
    height: 40px;
  }
}
</style>