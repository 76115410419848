<template>
  <div class="emo-detail" v-if="visible">
    <div class="mask" @click="close"></div>
    <div class="box" :style="{ width }">
      <div class="i-box">
        <div class="t-close" @click="close">
          <img src="/images/map/ov/br/desc-close.png" alt="" />
        </div>
        <iframe
          :src="url"
          class="iframe-box"
          align="middle"
          frameborder="0"
          referrerPolicy="no-referrer"
          sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      url: '',
      width: '1800px'
    }
  },
  mounted() {},
  methods: {
    open(url, width) {
      console.log(url)
      this.url = url
      this.width = width
      this.visible = true
    },
    close() {
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
.emo-detail {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;

  @include flex();
  align-items: center;
  justify-content: center;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    // background: rgba($color: #000000, $alpha: 0.8);
  }
  .box {
    z-index: 11;
    height: 90%;
    @include flex();
    background: rgba(6, 23, 25, 0.7);
    border: 1px solid #ef9c00;
    border-radius: 10px;
    position: relative;
    .i-box {
      @include flex(column);
      width: 100%;
      font-size: 24px;
      padding: 30px;
      .t-close {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 25px;
        height: 25px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .iframe-box {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
