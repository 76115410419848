<template>
  <div class="activity-detail"  v-if="visible" >
    <div class="mask" @click="close" ></div>
    <div class="box">
      <div class="t-close" @click="close">
        <img src="/images/map/ov/br/desc-close.png" alt="" />
      </div>
      <div class="html-content" style="width:1000px;" v-html="html" v-viewer></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      html: null
    }
  },
  props: {

    width: {
      type: String,
      default: ''
    }
  },
  mounted() {},
  methods: {
    open(val) {
      this.visible = true
      this.html = val
    },
    close() {
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
.activity-detail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;

  @include flex();
  align-items: center;
  justify-content: center;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba($color: #000000, $alpha: 0.8);
  }
  .box {
    z-index: 11;
    max-height: 90%;
    @include flex();
    background: rgba(6, 23, 25, 0.7);
    border: 1px solid #ef9c00;
    border-radius: 10px;
    position: relative;
    padding: 20px;
    .html-content {
      @include scrollBar();
      overflow-y: scroll;
      overflow-x: hidden;
      p {
        display: flex !important;
        flex-direction: column !important;
        padding: 0 !important;
        margin-block-start: 0 !important;
        margin-block-end: 0 !important;
        margin-inline-start: 0;
        margin-inline-end: 0;
        align-items: center;
        justify-content: center;
        img {
          cursor: pointer;
        }
      }
    }
    .t-close {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 25px;
      height: 25px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

</style>
<style  lang="scss">
.html-content{
p{
img{
  width: 1000px !important;
}
}
}
</style>
