<template>
  <div class="emo-list" v-if="visible">
    <div class="mask" @click="close"></div>
    <div class="box">
      <div class="i-box" v-if="list.length">
        <div class="t-close" @click="close">
          <img src="/images/map/ov/br/desc-close.png" alt="" />
        </div>
        <div class="t-header">
          <span>关键词</span>
          <span>来源</span>
          <span>信息摘要</span>
          <span>发布时间</span>
        </div>
        <div class="t-list">
          <div
            class="item"
            v-for="(item, index) in list"
            :key="index"
            @click="handleItem(item)"
          >
            <span>{{ item.scenicName }}</span>
            <span>{{ item.mediaName }}</span>
            <span>{{ item.title }}</span>
            <span>{{ dayjs(item.date).format('YYYY/MM/DD') }}</span>
          </div>
          <el-pagination
            layout="total,prev, pager, next"
            :background="true"
            @current-change="handlePageChange"
            :total="dataTotal"
            :current-page="curPage"
            :page-size="pageSize"
          />
        </div>
      </div>
      <ek-empty v-if="!list.length"></ek-empty>
    </div>
  </div>
</template>

<script>
import { cs_emo_list } from '@/api/cs'
// const { BrowserWindow } = require('@electron/remote')
let webWindow = null

export default {
  data() {
    return {
      visible: false,
      list: [],
      type: '',
      dataTotal: 0,
      curPage: 1,
      pageSize: 5
    }
  },
  mounted() {},
  methods: {
    open(type) {
      this.type = type
      this.fetchData()
    },
    close() {
      this.visible = false
      this.list = []
      this.dataTotal = 0
      this.curPage = 1
    },
    async fetchData() {
      let { type } = this
      let dataType =
        type == '负面' ? '0' : type == '中性' ? '1' : type == '正面' ? '2' : ''
      let params = {
        needPage: true,
        page: this.curPage - 1,
        size: this.pageSize,
        dataType
      }
      const { resultCode, resultData } = await cs_emo_list(params)
      if (resultCode === '200' && resultData) {
        this.list = resultData.content
        this.dataTotal = resultData.totalElements
        this.visible = true
      }
    },

    handlePageChange(page) {
      this.curPage = page
      this.fetchData()
    },
    handleItem(item) {
      // this.$eventBus.$emit('cs_emo_detail', item.url)
      this.createWindow(item)
    },

    createWindow(item) {
      console.log(item)
      if (webWindow == null) {
        // webWindow = new BrowserWindow({
        //   resizable: true,
        //   width: 1800,
        //   height: 1030,
        //   autoHideMenuBar: true,
        //   webPreferences: {
        //     nodeIntegration: true,
        //     webSecurity: false // 禁用浏览器跨域安全特性
        //   }
        // })
        webWindow.setAlwaysOnTop(true, 'screen-saver', 1)
      }
      webWindow.on('enter-full-screen', () => {
        //console.log('进入全屏')
        webWindow.setAutoHideMenuBar(true)
        webWindow.setMenuBarVisibility(false)
      })
      webWindow.on('leave-full-screen', () => {
        //console.log('退出全屏')
        webWindow.setAutoHideMenuBar(false)
        webWindow.setMenuBarVisibility(true)
      })
      webWindow.on('close', function() {
        webWindow = null
        console.log('关闭')
      })
      webWindow.loadURL(item.url)
      webWindow.setTitle(
        item.scenicName + '-' + item.mediaName + '-' + item.title
      )
      webWindow.moveTop()
    }
  }
}
</script>

<style scoped lang="scss">
.emo-list {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;

  @include flex();
  align-items: center;
  justify-content: center;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba($color: #000000, $alpha: 0.8);
  }
  .box {
    z-index: 11;
    width: 1800px;
    @include flex();
    background: rgba(6, 23, 25, 0.7);
    border: 1px solid #ef9c00;
    border-radius: 10px;
    position: relative;
  }
  .i-box {
    @include flex(column);
    width: 100%;
    font-size: 24px;
    .t-close {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 25px;
      height: 25px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .t-header {
      @include flex();
      align-items: center;
      height: 90px;

      span {
        text-align: center;
        color: #ef9c00;
        font-weight: bold;
      }
    }

    .t-list {
      @include flex(column);
      .item {
        width: 100%;
        @include flex();
        align-items: center;
        height: 70px;
        cursor: pointer;
        span {
          text-align: center;
        }
      }
    }

    .t-header > span:nth-child(1),
    .t-list > .item > span:nth-child(1) {
      flex: 1;
    }
    .t-header > span:nth-child(2),
    .t-list > .item > span:nth-child(2) {
      flex: 1;
    }
    .t-header > span:nth-child(3),
    .t-list > .item > span:nth-child(3) {
      flex: 7;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .t-header > span:nth-child(4),
    .t-list > .item > span:nth-child(4) {
      flex: 1.5;
    }

    .t-list > .item:nth-child(odd) {
      background: rgba($color: #084a50, $alpha: 0.6);
    }
  }
}
</style>

<style lang="scss">
.t-list {
  .el-pagination {
    padding: 15px 20px;
    @include flex();
    justify-content: center;
  }

  .el-pagination__total {
    color: #fff;
  }

  .is-background .el-pager li:not(.disabled).active {
    background-color: #084a50 !important;
    color: #fff !important;
  }
  .el-pager li:not(.disabled):hover {
    color: #084a50 !important;
  }
  // #084A50
}
</style>
